/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any }
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: any; output: any }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any }
  HexColorCode: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any }
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: any; output: any }
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: any; output: any }
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: { input: any; output: any }
  /** Almost restrictions-free phone number, like `+0 (178) 95-551-234` */
  SloppyPhoneNumber: { input: any; output: any }
  /** A free-form postal code (no validation) */
  SloppyPostalCode: { input: any; output: any }
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: { input: any; output: any }
  /** UI Code (use it for I18N and L10N) */
  UICode: { input: any; output: any }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any }
  Upload: { input: any; output: any }
  /** Represents NULL values */
  Void: { input: any; output: any }
}

export type Address = {
  __typename?: 'Address'
  addressId: Scalars['ID']['output']
  /** State / Province / Region (ISO code when available) */
  administrativeArea?: Maybe<Scalars['String']['output']>
  /** Country */
  country: Country
  id: Scalars['ID']['output']
  /** City / Town */
  locality?: Maybe<Scalars['String']['output']>
  /** Postal code / ZIP Code */
  postalCode?: Maybe<Scalars['SloppyPostalCode']['output']>
  /** Street address */
  streetAddress?: Maybe<Scalars['String']['output']>
  /** Street address (line 2), or Apartment, Suite, Box number, etc. */
  streetAddress2?: Maybe<Scalars['String']['output']>
  /** County / District (unused) */
  subAdministrativeArea?: Maybe<Scalars['String']['output']>
}

export type AddressArg = {
  /** State / Province / Region (ISO code when available) */
  administrativeArea?: InputMaybe<Scalars['String']['input']>
  countryId?: InputMaybe<Scalars['ID']['input']>
  /** City / Town */
  locality?: InputMaybe<Scalars['String']['input']>
  /** Postal code / ZIP Code */
  postalCode?: InputMaybe<Scalars['SloppyPostalCode']['input']>
  /** Street address */
  streetAddress?: InputMaybe<Scalars['String']['input']>
  /** Street address (line 2), or Apartment, Suite, Box number, etc. */
  streetAddress2?: InputMaybe<Scalars['String']['input']>
  /** County / District (unused) */
  subAdministrativeArea?: InputMaybe<Scalars['String']['input']>
}

export type AdminRegionCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type AdminRegionCreatePayload = {
  __typename?: 'AdminRegionCreatePayload'
  region: Region
}

export type AnnualSalesRevenue = {
  __typename?: 'AnnualSalesRevenue'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  maxValue?: Maybe<Scalars['Float']['output']>
  minValue?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Asset = IDatamapEntity & {
  __typename?: 'Asset'
  assetCategory: AssetCategory
  assetCategoryId: Scalars['ID']['output']
  assetId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type AssetCategory = {
  __typename?: 'AssetCategory'
  assetCategoryId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type AssetConnection = {
  __typename?: 'AssetConnection'
  asset?: Maybe<Asset>
  assetConnectionId: Scalars['ID']['output']
  assetId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  key?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  secret?: Maybe<Scalars['String']['output']>
  /** ALWAYS NULL for this entity, but it is required for the interface */
  uiCode?: Maybe<Scalars['UICode']['output']>
  url?: Maybe<Scalars['String']['output']>
  username?: Maybe<Scalars['String']['output']>
}

export type Audience = {
  __typename?: 'Audience'
  audienceId: Scalars['ID']['output']
  company: Company
  computingMethod: CompanyAudienceComputingMethodEnum
  createdAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** @deprecated 2025-01 we do not use isSingleUser audiences */
  isSingleUser: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  users: Array<User>
}

export type AudienceUsersArgs = {
  input?: InputMaybe<UserGetInput>
}

export type AuthApiKey = {
  __typename?: 'AuthApiKey'
  apiKeyId: Scalars['ID']['output']
  apiKeyMasked: Scalars['String']['output']
  botUser: User
  botUserId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  createdByUser: User
  createdByUserId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type AuthApiKeyCreateInput = {
  companyId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type AuthApiKeyCreatePayload = {
  __typename?: 'AuthApiKeyCreatePayload'
  apiKey: AuthApiKey
  apiKeyString: Scalars['String']['output']
}

export type AuthApiKeyDeleteFilterArg = {
  apiKeyId: Scalars['ID']['input']
}

export type AuthApiKeyDeleteInput = {
  filter: AuthApiKeyDeleteFilterArg
  /**
   * Transfer entities created using ApiKey to the provided user.
   *
   * **See also**: `user.delete(input.transferOwnershipsToUserId)`
   */
  transferOwnershipsToUserId?: InputMaybe<Scalars['ID']['input']>
}

export type AuthApiKeyDeletePayload = {
  __typename?: 'AuthApiKeyDeletePayload'
  apiKeyId: Scalars['ID']['output']
  query: Query
}

export type AuthApiKeysGetFilterArg = {
  apiKeyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type AuthApiKeysGetInput = {
  filter?: InputMaybe<AuthApiKeysGetFilterArg>
  order?: InputMaybe<Array<AuthApiKeysGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type AuthApiKeysGetOrderArg = {
  by: AuthApiKeysGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum AuthApiKeysGetOrderEnum {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export type AuthApiKeysGetPayload = {
  __typename?: 'AuthApiKeysGetPayload'
  apiKeys: Array<AuthApiKey>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

export enum AuthCodeDeliveryMediumEnum {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Unknown = 'UNKNOWN',
}

export type AuthIdentityApplication = {
  __typename?: 'AuthIdentityApplication'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  identityApplicationId: Scalars['ID']['output']
  identityProtocolCode: AuthIdentityProtocolCodeEnum
  identityProviderCode: AuthIdentityProviderCodeEnum
  /** Name will be available even for anonymous users */
  name: Scalars['String']['output']
  specificConfig?: Maybe<Scalars['JSON']['output']>
  /**
   * The URI used to init the "authentication flow".
   *
   * _At the moment - the only URI available for the application, so the name is pretty vague._
   */
  uriInit?: Maybe<Scalars['URL']['output']>
  uriLogout?: Maybe<Scalars['URL']['output']>
  uriProviderRedirect?: Maybe<Scalars['URL']['output']>
}

export type AuthIdentityApplicationUpsertInput = {
  /** Not null for `create` */
  companyId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** Not null for `create` */
  identityProviderCode?: InputMaybe<AuthIdentityProviderCodeEnum>
  /**
   * Name will be available even for anonymous users
   *
   * Not null for `create`
   */
  name?: InputMaybe<Scalars['String']['input']>
  specificConfig?: InputMaybe<Scalars['JSON']['input']>
}

export type AuthIdentityApplicationUpsertPayload = {
  __typename?: 'AuthIdentityApplicationUpsertPayload'
  identityApplication: AuthIdentityApplication
  query: Query
}

export type AuthIdentityApplicationsGetFilterArg = {
  applicationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId?: InputMaybe<Scalars['ID']['input']>
  companySlug?: InputMaybe<Scalars['String']['input']>
  companyUsersPrimaryEmailDomain?: InputMaybe<Scalars['String']['input']>
  /**
   * Filter IdP using their "isSocial" nature. Example:
   *   - social: Google, Facebook, ..
   *   - private SSO: MS Entra, AWS Cognito
   * Valid values are:
   *   - `null` - no filtering by this param
   *   - `true` - only social IdP
   *   - `false` - only private SSO IdP
   */
  isSocial?: InputMaybe<Scalars['Boolean']['input']>
}

export type AuthIdentityApplicationsGetInput = {
  filter?: InputMaybe<AuthIdentityApplicationsGetFilterArg>
}

export type AuthIdentityApplicationsGetPayload = {
  __typename?: 'AuthIdentityApplicationsGetPayload'
  /**
   * **IMPORTANT!**
   * this field can be empty even if there is a matching company
   *
   * it depends on `identityApplications`
   */
  companies: Array<Company>
  identityApplications: Array<AuthIdentityApplication>
  query: Query
}

export enum AuthIdentityProtocolCodeEnum {
  Oidc = 'OIDC',
}

export enum AuthIdentityProviderCodeEnum {
  AwsCognito = 'AWS_COGNITO',
  MicrosoftEntra = 'MICROSOFT_ENTRA',
}

export type AuthLoginConfirmInput = {
  confirmationCode: Scalars['String']['input']
  mfaChallengeKey: Scalars['String']['input']
  mfaType: AuthMfaTypeEnum
  username: Scalars['EmailAddress']['input']
}

export type AuthLoginConfirmPayload =
  | AuthLoginNexChallengePayload
  | AuthLoginSuccessPayload

export type AuthLoginInput = {
  password: Scalars['String']['input']
  username: Scalars['EmailAddress']['input']
}

export type AuthLoginNexChallengePayload = {
  __typename?: 'AuthLoginNexChallengePayload'
  mfaChallengeKey: Scalars['String']['output']
  mfaType: AuthMfaTypeEnum
}

export type AuthLoginPayload =
  | AuthLoginNexChallengePayload
  | AuthLoginSuccessPayload

export type AuthLoginSuccessPayload = {
  __typename?: 'AuthLoginSuccessPayload'
  accessToken: Scalars['String']['output']
  /**
   * Expiration time for **Access Token**
   * @deprecated use token itself
   */
  expiresAfterSeconds: Scalars['NonNegativeInt']['output']
  identity: AuthUserIdentity
  refreshToken: Scalars['String']['output']
  user: User
}

export type AuthLogoutPayload = {
  __typename?: 'AuthLogoutPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
}

export type AuthMfaConfigureConfirmInput = {
  confirmationCode: Scalars['String']['input']
  mfaType: AuthMfaTypeEnum
}

export type AuthMfaConfigureConfirmPayload = {
  __typename?: 'AuthMfaConfigureConfirmPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
  user: User
}

export type AuthMfaConfigureInput = {
  contact?: InputMaybe<Scalars['String']['input']>
  mfaType: AuthMfaTypeEnum
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}

export type AuthMfaConfigurePayload = {
  __typename?: 'AuthMfaConfigurePayload'
  query: Query
  secretKey?: Maybe<Scalars['String']['output']>
  user: User
}

export type AuthMfaDisableInput = {
  /**
   * Identifier of the user for MFA disable
   * **FOR BACKWARD COMPATIBILITY**: by default it is executed for the current
   * user.
   */
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type AuthMfaDisablePayload = {
  __typename?: 'AuthMfaDisablePayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
  user: User
}

export enum AuthMfaTypeEnum {
  Sms = 'SMS',
  Totp = 'TOTP',
}

export type AuthPasswordChangeInput = {
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type AuthPasswordChangePayload = {
  __typename?: 'AuthPasswordChangePayload'
  query: Query
  user: User
}

export type AuthPasswordRestoreConfirmInput = {
  confirmationCode: Scalars['String']['input']
  confirmationToken: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export type AuthPasswordRestoreConfirmPayload = {
  __typename?: 'AuthPasswordRestoreConfirmPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
}

export type AuthPasswordRestoreInitInput = {
  username: Scalars['EmailAddress']['input']
}

export type AuthPasswordRestoreInitPayload = {
  __typename?: 'AuthPasswordRestoreInitPayload'
  codeDeliveryInfo: CodeDeliveryInfo
  query: Query
}

/** Security role */
export type AuthRole = {
  __typename?: 'AuthRole'
  code: AuthRoleCodeEnum
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export enum AuthRoleCodeEnum {
  /**
   * Company's administrator.
   *
   * Usually it is the first user in company. By default this role is assigned
   * to the user on sign-up
   */
  Administrator = 'ADMINISTRATOR',
  /**
   * Data Protection Officer
   *
   * Usually, it is the main user of Palqee Services
   */
  Dpo = 'DPO',
  Employee = 'EMPLOYEE',
  PalqeeManager = 'PALQEE_MANAGER',
  /** Owner of the business-process in company */
  ProcessOwner = 'PROCESS_OWNER',
  Vendor = 'VENDOR',
}

export type AuthSignUpConfirmInput = {
  confirmationCode: Scalars['String']['input']
  confirmationToken: Scalars['String']['input']
}

export type AuthSignUpConfirmPayload = {
  __typename?: 'AuthSignUpConfirmPayload'
  identity: AuthUserIdentity
  query: Query
  user: User
}

export type AuthSignUpInput = {
  /**
   * Name of a deparment where the current signing-up user works in the company.
   *
   * **NOTE**: You have to provide both `positionName` and `deparmentName` to
   * make it possible to add the user to the deparment automatically after
   * creating a company.
   */
  departmentName?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /**
   * Name of a position in the company for the current signing-up user.
   *
   * **NOTE**: You have to provide both `positionName` and `deparmentName` to
   * make it possible to add the user to the deparment automatically after
   * creating a company.
   */
  positionName?: InputMaybe<Scalars['String']['input']>
  /** this username will be used as the main email */
  username: Scalars['EmailAddress']['input']
}

export type AuthSignUpPayload = {
  __typename?: 'AuthSignUpPayload'
  identity: AuthUserIdentity
  query: Query
  user: User
}

export type AuthSignUpResendCodeInput = {
  usernames: Array<Scalars['EmailAddress']['input']>
}

export type AuthSignUpResendCodePayload = {
  __typename?: 'AuthSignUpResendCodePayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
  usersMatched: Array<User>
}

export type AuthSignUpResendCodeToPendingUsersInput = {
  companyIds: Array<Scalars['ID']['input']>
}

export type AuthSignUpResendCodeToPendingUsersPayload = {
  __typename?: 'AuthSignUpResendCodeToPendingUsersPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
  usersMatched: Array<User>
}

export type AuthTokenRefreshInput = {
  refreshToken: Scalars['String']['input']
}

export type AuthTokenRefreshPayload = {
  __typename?: 'AuthTokenRefreshPayload'
  accessToken: Scalars['String']['output']
  /**
   * Expiration time for the **Access Token**
   *
   * # DEPRECATED: #tokenExpiresAfterSeconds
   * @deprecated use token itself
   */
  expiresAfterSeconds: Scalars['NonNegativeInt']['output']
  identity: AuthUserIdentity
  refreshToken: Scalars['String']['output']
  user: User
}

export type AuthUserCurrentInput = {
  fakeInput?: InputMaybe<Scalars['Boolean']['input']>
}

export type AuthUserCurrentPayload = {
  __typename?: 'AuthUserCurrentPayload'
  identity?: Maybe<AuthUserIdentity>
  user?: Maybe<User>
}

export type AuthUserIdentity = {
  __typename?: 'AuthUserIdentity'
  id: Scalars['ID']['output']
  identityApplication: AuthIdentityApplication
  identityApplicationId: Scalars['ID']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type BinaryFileInfo = IBinaryFileInfo & {
  __typename?: 'BinaryFileInfo'
  binaryFileInfoId: Scalars['ID']['output']
  /** Length is unknown for OLD files */
  contentLength?: Maybe<Scalars['NonNegativeInt']['output']>
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  mimetype: Scalars['String']['output']
  originalFilename?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type BinaryFileInfoUrlArgs = {
  input?: InputMaybe<BinaryFileInfoUrlInput>
}

export type BinaryFileInfoPublic = IBinaryFileInfo & {
  __typename?: 'BinaryFileInfoPublic'
  binaryFileInfoPublicId: Scalars['ID']['output']
  /** Length is unknown for OLD files */
  contentLength?: Maybe<Scalars['NonNegativeInt']['output']>
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  mimetype: Scalars['String']['output']
  originalFilename?: Maybe<Scalars['String']['output']>
  publicUrl?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type BinaryFileInfoPublicUrlArgs = {
  input?: InputMaybe<BinaryFileInfoUrlInput>
}

export type BinaryFileInfoUrlInput = {
  redirectTtlSeconds?: InputMaybe<Scalars['NonNegativeInt']['input']>
  urlTargetType?: BinaryFileInfoUrlTargetTypeEnum
}

export enum BinaryFileInfoUrlTargetTypeEnum {
  Base64Payload = 'BASE64_PAYLOAD',
  BinaryPayload = 'BINARY_PAYLOAD',
  Null = 'NULL',
  PublicUrl = 'PUBLIC_URL',
  SignedJsonUrl = 'SIGNED_JSON_URL',
  SignedRedirect = 'SIGNED_REDIRECT',
  /**
   * This one generates URL for our `/files/redirect` endpoint (we use it for
   * more or less static redirects for file-access
   */
  SignedUrlHttpRedirect = 'SIGNED_URL_HTTP_REDIRECT',
}

export type CodeDeliveryInfo = {
  __typename?: 'CodeDeliveryInfo'
  codeDeliveryDestination: Scalars['String']['output']
  codeDeliveryMedium: AuthCodeDeliveryMediumEnum
  isUserConfirmed?: Maybe<Scalars['Boolean']['output']>
}

export type Company = {
  __typename?: 'Company'
  address?: Maybe<Address>
  annualSalesRevenue: AnnualSalesRevenue
  audiences: Array<Audience>
  companyFeatures: Array<ISubscriptionFeature>
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  currency?: Maybe<Currency>
  currentSubscriptionTier?: Maybe<SubscriptionTier>
  departments: Array<Department>
  id: Scalars['ID']['output']
  industries: Array<Industry>
  logo?: Maybe<BinaryFileInfoPublic>
  name?: Maybe<Scalars['String']['output']>
  numberOfEmployees: Scalars['NonNegativeInt']['output']
  positions: Array<Position>
  regions: Array<Region>
  representatives: Array<Representative>
  /** A list of regulations, marked as **selected** for the company */
  selectedRegulations: Array<Regulation>
  slug: Scalars['String']['output']
  structure: CompanyStructure
  tenant: Tenant
  tenantId: Scalars['ID']['output']
  type: CompanyType
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * This field is used in the SSO authentication flow. When ANONYMOUS user
   * tries to login, they can ask for available Identity Providers, and since
   * most providers are linked to companies - we do company lookup using this
   * field.
   *
   * Set it to `null` if you don't want to make this search possible (when it is
   * null no IdP configured for this company will be shown).
   *
   * The field must be unique.
   *
   * Recomended value:
   *   - company name (simplified: "acme" instead of "Public antarctic company ACME LTD")
   *
   * The field is case-insensitive (`AaB === aab`)
   */
  usersPrimaryEmailDomain?: Maybe<Scalars['String']['output']>
}

export type CompanyAudiencesArgs = {
  input?: InputMaybe<CompanyAudiencesInput>
}

export type CompanyRepresentativesArgs = {
  input?: InputMaybe<CompanyRepresentativesInput>
}

export type CompanyAddressSetInput = {
  addressInfo?: InputMaybe<AddressArg>
  companyId: Scalars['ID']['input']
}

export type CompanyAddressSetPayload = {
  __typename?: 'CompanyAddressSetPayload'
  address: Address
  company: Company
  query: Query
}

export type CompanyAssetConnectionCreateInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>
  companyId: Scalars['ID']['input']
  key?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  secret?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type CompanyAssetConnectionCreatePayload = {
  __typename?: 'CompanyAssetConnectionCreatePayload'
  assetConnection: AssetConnection
  query: Query
}

export enum CompanyAudienceComputingMethodEnum {
  /** For regular audiences with users added manually */
  None = 'NONE',
  RoleAdministrator = 'ROLE_ADMINISTRATOR',
  RoleDpo = 'ROLE_DPO',
  RoleEmployee = 'ROLE_EMPLOYEE',
  RoleProcessOwner = 'ROLE_PROCESS_OWNER',
  RoleVendor = 'ROLE_VENDOR',
}

export type CompanyAudienceCreateInput = {
  companyId: Scalars['ID']['input']
  computingMethod?: CompanyAudienceComputingMethodEnum
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type CompanyAudienceCreatePayload = {
  __typename?: 'CompanyAudienceCreatePayload'
  audience: Audience
  query: Query
}

export type CompanyAudienceDeleteFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  computingMethods?: InputMaybe<Array<CompanyAudienceComputingMethodEnum>>
}

export type CompanyAudienceDeleteInput = {
  filter?: InputMaybe<CompanyAudienceDeleteFilterArg>
}

export type CompanyAudienceDeletePayload = {
  __typename?: 'CompanyAudienceDeletePayload'
  audiences: Array<Audience>
  query: Query
}

export type CompanyAudienceUpdateInput = {
  audienceId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type CompanyAudienceUpdatePayload = {
  __typename?: 'CompanyAudienceUpdatePayload'
  audience: Audience
  query: Query
}

export type CompanyAudienceUsersLinkInput = {
  audienceId: Scalars['ID']['input']
  onUserAlreadyLinkedBehavior?: InputMaybe<OnErrorBehaviorEnum>
  sendRegistrationEmailsToPendingUsers?: Scalars['Boolean']['input']
  users: CompanyAudienceUsersLinkUsersArg
}

export type CompanyAudienceUsersLinkPayload = {
  __typename?: 'CompanyAudienceUsersLinkPayload'
  audience: Audience
  /** @deprecated long time ago we stopped to support this feature */
  invitedUsers?: Maybe<Array<Maybe<User>>>
  query: Query
}

export type CompanyAudienceUsersLinkUsersArg = {
  exclude?: InputMaybe<CompanyAudienceUsersLinkUsersExcludeArg>
  filter?: InputMaybe<CompanyAudienceUsersLinkUsersFilterArg>
}

export type CompanyAudienceUsersLinkUsersExcludeArg = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyAudienceUsersLinkUsersFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyAudienceUsersUnlinkInput = {
  audienceId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type CompanyAudienceUsersUnlinkPayload = {
  __typename?: 'CompanyAudienceUsersUnlinkPayload'
  audience: Audience
  query: Query
}

export type CompanyAudiencesFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  computingMethods?: InputMaybe<Array<CompanyAudienceComputingMethodEnum>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  /**
   * Filter by _"assigned tasks"_:
   *
   * - `true` - returns audiences that have tasks assigned to them.
   * - `false` - returns audiences that do not have any task assigned to them.
   *
   * **Note 1**: when `filter.companyIds` is set - the filter considers tasks exclusively from these companies.
   * **Note 2**: deleted tasks are ignored
   */
  tasksAssignedHave?: InputMaybe<Scalars['Boolean']['input']>
}

export type CompanyAudiencesGetFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  computingMethods?: InputMaybe<Array<CompanyAudienceComputingMethodEnum>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  /**
   * Filter by _"assigned tasks"_:
   *
   * - `true` - returns audiences that have tasks assigned to them.
   * - `false` - returns audiences that do not have any task assigned to them.
   *
   * **Note 1**: when `filter.companyIds` is set - the filter considers tasks exclusively from these companies.
   * **Note 2**: deleted tasks are ignored
   */
  tasksAssignedHave?: InputMaybe<Scalars['Boolean']['input']>
}

export type CompanyAudiencesGetInput = {
  filter?: InputMaybe<CompanyAudiencesGetFilterArg>
  order?: InputMaybe<Array<CompanyAudiencesGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<CompanyAudiencesGetSearchArg>
}

export type CompanyAudiencesGetOrderArg = {
  by: CompanyAudiencesGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum CompanyAudiencesGetOrderEnum {
  ComputingMethod = 'computingMethod',
  CreatedAt = 'createdAt',
  Description = 'description',
  Name = 'name',
}

export type CompanyAudiencesGetPayload = {
  __typename?: 'CompanyAudiencesGetPayload'
  audiences: Array<Audience>
  paginationInfo: PaginationInfo
  query: Query
}

export type CompanyAudiencesGetSearchArg = {
  pattern: Scalars['String']['input']
}

export type CompanyAudiencesInput = {
  filter?: InputMaybe<CompanyAudiencesFilterArg>
  order?: InputMaybe<Array<CompanyAudiencesGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type CompanyCreateInput = {
  addressInfo?: InputMaybe<AddressArg>
  annualSalesRevenueId: Scalars['ID']['input']
  currencyId: Scalars['ID']['input']
  isSubscriptionTrialEnabled?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  numberOfEmployees: Scalars['NonNegativeInt']['input']
  /** Temporary is used to generate "path" part of the URLs for the company in the UI */
  slug?: InputMaybe<Scalars['String']['input']>
  structureId: Scalars['ID']['input']
  subscriptionTierId?: InputMaybe<Scalars['ID']['input']>
  typeId: Scalars['ID']['input']
  usersPrimaryEmailDomain?: InputMaybe<Scalars['String']['input']>
}

export type CompanyCreatePayload = {
  __typename?: 'CompanyCreatePayload'
  company: Company
  query: Query
}

export type CompanyDeleteFilterArgument = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyDeleteInput = {
  filter: CompanyDeleteFilterArgument
}

export type CompanyDeletePayload = {
  __typename?: 'CompanyDeletePayload'
  companies: Array<Company>
  query: Query
}

export type CompanyDepartmentCreateInput = {
  companyId: Scalars['ID']['input']
  departmentTemplateId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * if `true` the method just returns the existing department, instead of throwing an NON-UNIQUE exception
   * allows to bypass only name-unique exception
   */
  doSuppressException?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
}

export type CompanyDepartmentCreatePayload = {
  __typename?: 'CompanyDepartmentCreatePayload'
  department: Department
  query: Query
}

export type CompanyDepartmentsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Filtering departments that HAVE/HAVE NOT **any** users working there.
   *
   * _Since we use a lot of Yoda-style names - hence the name of this filter._
   */
  usersEmployedHaveAny?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Filtering by departments, where these users are employed (have
   * "position" records).
   */
  usersEmployedIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyDepartmentsGetInput = {
  filter?: InputMaybe<CompanyDepartmentsGetFilterArg>
  order?: InputMaybe<Array<CompanyDepartmentsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type CompanyDepartmentsGetOrderArg = {
  by: CompanyDepartmentsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum CompanyDepartmentsGetOrderEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Name = 'name',
}

export type CompanyDepartmentsGetPayload = {
  __typename?: 'CompanyDepartmentsGetPayload'
  departments: Array<Department>
  paginationInfo: PaginationInfo
  query: Query
}

export type CompanyFeatureUsagesGetInput = {
  companyId: Scalars['ID']['input']
  featureCodes?: InputMaybe<Array<Scalars['String']['input']>>
}

export type CompanyFeatureUsagesGetPayload = {
  __typename?: 'CompanyFeatureUsagesGetPayload'
  company: Company
  featureUsages: Array<SubscriptionFeatureUsage>
  query: Query
}

export type CompanyFeaturesGetInput = {
  companyId: Scalars['ID']['input']
}

export type CompanyFeaturesGetPayload = {
  __typename?: 'CompanyFeaturesGetPayload'
  company: Company
  features: Array<ISubscriptionFeature>
  query: Query
}

export type CompanyGetInput = {
  companyId: Scalars['ID']['input']
}

export type CompanyGetPayload = {
  __typename?: 'CompanyGetPayload'
  company: Company
  query: Query
}

export type CompanyIndustriesSetInput = {
  companyId: Scalars['ID']['input']
  industryIds: Array<Scalars['ID']['input']>
}

export type CompanyIndustriesSetPayload = {
  __typename?: 'CompanyIndustriesSetPayload'
  company: Company
  query: Query
}

export type CompanyLogoSetInput = {
  companyId: Scalars['ID']['input']
  logo?: InputMaybe<Scalars['Upload']['input']>
}

export type CompanyLogoSetPayload = {
  __typename?: 'CompanyLogoSetPayload'
  company: Company
  logo: IBinaryFileInfo
  query: Query
}

export type CompanyOnboardingStepsGetInput = {
  companyId: Scalars['ID']['input']
}

export type CompanyOnboardingStepsGetPayload = {
  __typename?: 'CompanyOnboardingStepsGetPayload'
  stepCodes: Array<OnboardingProgressStepCodeEnum>
}

export type CompanyOnboardingStepsSaveInput = {
  companyId: Scalars['ID']['input']
  isCompleted: Scalars['Boolean']['input']
  stepCode: OnboardingProgressStepCodeEnum
}

export type CompanyOnboardingStepsSavePayload = {
  __typename?: 'CompanyOnboardingStepsSavePayload'
  stepCodes: Array<OnboardingProgressStepCodeEnum>
}

export type CompanyPositionCreateInput = {
  companyId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * if `true` the method just returns the existing position, instead of throwing an NON-UNIQUE exception
   * allows to bypass only name-unique exception
   */
  doSuppressException?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  positionTemplateId?: InputMaybe<Scalars['ID']['input']>
}

export type CompanyPositionCreatePayload = {
  __typename?: 'CompanyPositionCreatePayload'
  position: Position
  query: Query
}

export type CompanyPositionsGetInput = {
  filter?: InputMaybe<PositionFilterArg>
}

export type CompanyPositionsGetPayload = {
  __typename?: 'CompanyPositionsGetPayload'
  positions: Array<Position>
  query: Query
}

export type CompanyProcessCreateInput = {
  companyId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  processTemplateId?: InputMaybe<Scalars['ID']['input']>
}

export type CompanyProcessCreatePayload = {
  __typename?: 'CompanyProcessCreatePayload'
  process: Process
  query: Query
}

export type CompanyProcessesGetInput = {
  filter?: InputMaybe<ProcessesGetFilterArg>
}

export type CompanyProcessesGetPayload = {
  __typename?: 'CompanyProcessesGetPayload'
  processes: Array<Process>
  query: Query
}

export type CompanyRegulationLinkInput = {
  companyId: Scalars['ID']['input']
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyRegulationLinkPayload = {
  __typename?: 'CompanyRegulationLinkPayload'
  company: Company
  query: Query
  regulations: Array<Regulation>
}

export type CompanyRegulationUnLinkInput = {
  companyId: Scalars['ID']['input']
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyRegulationUnLinkPayload = {
  __typename?: 'CompanyRegulationUnLinkPayload'
  company: Company
  query: Query
  regulations?: Maybe<Array<Regulation>>
}

export type CompanyRepresentativeAddressSetInput = {
  address?: InputMaybe<AddressArg>
  representativeId: Scalars['ID']['input']
}

export type CompanyRepresentativeAddressSetPayload = {
  __typename?: 'CompanyRepresentativeAddressSetPayload'
  query: Query
  representative: Representative
}

export type CompanyRepresentativeCreateInput = {
  companyId: Scalars['ID']['input']
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  representativeGroup: RepresentativeGroupEnum
}

export type CompanyRepresentativeCreatePayload = {
  __typename?: 'CompanyRepresentativeCreatePayload'
  query: Query
  representative: Representative
}

export type CompanyRepresentativeDeleteInput = {
  filter: RepresentativeFilterArg
}

export type CompanyRepresentativeDeletePayload = {
  __typename?: 'CompanyRepresentativeDeletePayload'
  query: Query
  representatives: Array<Representative>
}

export type CompanyRepresentativeFilterArg = {
  representativeGroups?: InputMaybe<Array<RepresentativeGroupEnum>>
  representativeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyRepresentativeUpdateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  representativeGroup?: InputMaybe<RepresentativeGroupEnum>
  representativeId: Scalars['ID']['input']
}

export type CompanyRepresentativeUpdatePayload = {
  __typename?: 'CompanyRepresentativeUpdatePayload'
  query: Query
  representative: Representative
}

export type CompanyRepresentativesGetInput = {
  filter?: InputMaybe<RepresentativeFilterArg>
}

export type CompanyRepresentativesGetPayload = {
  __typename?: 'CompanyRepresentativesGetPayload'
  query: Query
  representatives: Array<Representative>
}

export type CompanyRepresentativesInput = {
  filter?: InputMaybe<CompanyRepresentativeFilterArg>
}

export type CompanyStructure = {
  __typename?: 'CompanyStructure'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type CompanySubscriptionTierSetInput = {
  companyId: Scalars['ID']['input']
  subscriptionTierId: Scalars['ID']['input']
}

export type CompanySubscriptionTierSetPayload = {
  __typename?: 'CompanySubscriptionTierSetPayload'
  company: Company
  query: Query
  subscriptionTier: SubscriptionTier
}

export type CompanySubscriptionTrialStartPayload = {
  __typename?: 'CompanySubscriptionTrialStartPayload'
  query: Query
  tenant: Tenant
}

export type CompanyType = {
  __typename?: 'CompanyType'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type CompanyUpdateInput = {
  annualSalesRevenueId?: InputMaybe<Scalars['ID']['input']>
  companyId: Scalars['ID']['input']
  currencyId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  numberOfEmployees?: InputMaybe<Scalars['NonNegativeInt']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  structureId?: InputMaybe<Scalars['ID']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
  usersPrimaryEmailDomain?: InputMaybe<Scalars['String']['input']>
}

export type CompanyUpdatePayload = {
  __typename?: 'CompanyUpdatePayload'
  company: Company
  query: Query
}

export enum CompareOperationEnum {
  EqualsTo = 'EqualsTo',
  GreaterOrEqualsTo = 'GreaterOrEqualsTo',
  GreaterThan = 'GreaterThan',
  /**
   * this operation will check if the operand is in the right open
   * interval `[a; b)` -- including `a` but not `b`
   *
   * It is a very common operation for `Datetime` ranges
   */
  InIntervalRightOpen = 'InIntervalRightOpen',
  LessOrEqualsTo = 'LessOrEqualsTo',
  LessThan = 'LessThan',
}

export type ConsentCondition = {
  __typename?: 'ConsentCondition'
  conditionId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  form: ConsentForm
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ConsentConditionArchivedSetFilterArg = {
  conditionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ConsentConditionArchivedSetInput = {
  filter?: InputMaybe<ConsentConditionArchivedSetFilterArg>
  isArchived: Scalars['Boolean']['input']
}

export type ConsentConditionArchivedSetPayload = {
  __typename?: 'ConsentConditionArchivedSetPayload'
  conditions: Array<ConsentCondition>
  query: Query
}

export type ConsentConditionCreateInfoItemArg = {
  description: Scalars['String']['input']
}

export type ConsentConditionCreateInput = {
  conditionInfos: Array<ConsentConditionCreateInfoItemArg>
  formId: Scalars['ID']['input']
}

export type ConsentConditionCreatePayload = {
  __typename?: 'ConsentConditionCreatePayload'
  conditions?: Maybe<Array<ConsentCondition>>
  form: ConsentForm
  query: Query
}

export type ConsentConditionDeleteInput = {
  conditionId: Scalars['ID']['input']
}

export type ConsentConditionDeletePayload = {
  __typename?: 'ConsentConditionDeletePayload'
  condition: ConsentCondition
  query: Query
}

export type ConsentConditionUpdateInput = {
  conditionId: Scalars['ID']['input']
  description: Scalars['String']['input']
}

export type ConsentConditionUpdatePayload = {
  __typename?: 'ConsentConditionUpdatePayload'
  condition: ConsentCondition
  query: Query
}

export type ConsentConsentIsHiddenSetConsentInfosItemArg = {
  conditionId: Scalars['ID']['input']
  isHidden: Scalars['Boolean']['input']
  personId: Scalars['ID']['input']
}

export type ConsentConsentIsHiddenSetInput = {
  consentInfos: Array<ConsentConsentIsHiddenSetConsentInfosItemArg>
}

export type ConsentConsentIsHiddenSetPayload = {
  __typename?: 'ConsentConsentIsHiddenSetPayload'
  query: Query
}

export type ConsentConsentResponseSaveConsentsItemArg = {
  conditionId: Scalars['ID']['input']
  doAgree: Scalars['Boolean']['input']
}

export type ConsentConsentResponseSaveCustomFieldValueInfo = {
  formCustomFieldId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type ConsentConsentResponseSaveInput = {
  actorEmail?: InputMaybe<Scalars['EmailAddress']['input']>
  actorFirstName?: InputMaybe<Scalars['String']['input']>
  actorLastName?: InputMaybe<Scalars['String']['input']>
  consents: Array<ConsentConsentResponseSaveConsentsItemArg>
  customFieldValueInfos?: InputMaybe<
    Array<ConsentConsentResponseSaveCustomFieldValueInfo>
  >
  email: Scalars['EmailAddress']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
}

export type ConsentConsentResponseSavePayload = {
  __typename?: 'ConsentConsentResponseSavePayload'
  deedConsentResponse: DeedConsentResponse
  query: Query
}

export type ConsentConsentResponseVerifyInput = {
  token: Scalars['String']['input']
}

export type ConsentConsentResponseVerifyPayload = {
  __typename?: 'ConsentConsentResponseVerifyPayload'
  query: Query
}

export type ConsentCurrent = {
  __typename?: 'ConsentCurrent'
  condition: ConsentCondition
  conditionId: Scalars['ID']['output']
  endedAt?: Maybe<Scalars['DateTime']['output']>
  firstGrantedAt: Scalars['DateTime']['output']
  grantedAt: Scalars['DateTime']['output']
  grantedByPerson: Person
  grantedByPersonId: Scalars['ID']['output']
  isHidden: Scalars['Boolean']['output']
  /**
   * Shows if the last consent-form-submission was confirmed using a secret token
   *
   * can be null for expired/revoked consents
   */
  isLastVerified?: Maybe<Scalars['Boolean']['output']>
  person: Person
  personId: Scalars['ID']['output']
  revokedByPerson?: Maybe<Person>
  revokedByPersonId?: Maybe<Scalars['ID']['output']>
  statusCode: ConsentStatusCodeEnum
}

export type ConsentForm = {
  __typename?: 'ConsentForm'
  allowConsentOnBehalf: Scalars['Boolean']['output']
  company: Company
  companyId: Scalars['ID']['output']
  conditions: Array<ConsentCondition>
  /**
   * The expiration/renew period of the consent. If it is 1Y, it means that consent
   * granted/accepted to this form will expire in 1 year and must be renewed.
   */
  consentExpiresAfterPeriod?: Maybe<Scalars['Duration']['output']>
  createdAt: Scalars['DateTime']['output']
  createdByUser?: Maybe<User>
  createdByUserId: Scalars['ID']['output']
  customFields: Array<ConsentFormCustomField>
  defaultLanguage: LanguageCodeEnum
  description?: Maybe<Scalars['String']['output']>
  draftAccessToken: Scalars['String']['output']
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  isDraft: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  lastUpdatedAt: Scalars['DateTime']['output']
  logo?: Maybe<IBinaryFileInfo>
  privacyPolicyLink?: Maybe<Scalars['String']['output']>
  reference: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ConsentFormArchivedSetInput = {
  formId: Scalars['ID']['input']
  isArchived: Scalars['Boolean']['input']
}

export type ConsentFormArchivedSetPayload = {
  __typename?: 'ConsentFormArchivedSetPayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormCreateInput = {
  allowConsentOnBehalf: Scalars['Boolean']['input']
  companyId: Scalars['ID']['input']
  conditionInfos?: InputMaybe<Array<ConsentConditionCreateInfoItemArg>>
  consentExpiresAfterPeriod?: InputMaybe<Scalars['Duration']['input']>
  defaultLanguage: LanguageCodeEnum
  description?: InputMaybe<Scalars['String']['input']>
  isDraft: Scalars['Boolean']['input']
  logo?: InputMaybe<Scalars['Upload']['input']>
  privacyPolicyLink?: InputMaybe<Scalars['String']['input']>
  reference: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type ConsentFormCreatePayload = {
  __typename?: 'ConsentFormCreatePayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormCustomField = {
  __typename?: 'ConsentFormCustomField'
  description?: Maybe<Scalars['String']['output']>
  form: ConsentForm
  formCustomFieldId: Scalars['ID']['output']
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isLocked: Scalars['Boolean']['output']
  isRequired: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  placeholder?: Maybe<Scalars['String']['output']>
  sortOrder: Scalars['NonNegativeInt']['output']
  validationArg?: Maybe<Scalars['String']['output']>
  validationMethodCode?: Maybe<QuestionAnswerValidationMethodCodeEnum>
}

export type ConsentFormCustomFieldUpsertInput = {
  description?: InputMaybe<Scalars['String']['input']>
  formId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  /**
   * An additional information about the field. It is a JSON, validated using
   * the schema in `@palqee-private/survey-question`, at the moment schema v0
   *
   * This field can contain:
   * - `label`
   * - `description`
   * - `placeholder`
   * - `answerDefinition` (with `type` and `format` fields)
   * - `translations'
   */
  meta?: InputMaybe<Scalars['String']['input']>
  placeholder?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type ConsentFormCustomFieldUpsertPayload = {
  __typename?: 'ConsentFormCustomFieldUpsertPayload'
  formCustomField: ConsentFormCustomField
  query: Query
}

export type ConsentFormCustomFieldsDeleteFilterArg = {
  formCustomFieldIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ConsentFormCustomFieldsDeleteInput = {
  filter: ConsentFormCustomFieldsDeleteFilterArg
}

export type ConsentFormCustomFieldsDeletePayload = {
  __typename?: 'ConsentFormCustomFieldsDeletePayload'
  deletedItemsCount: Scalars['Int']['output']
  query: Query
}

export type ConsentFormLogoSetInput = {
  formId: Scalars['ID']['input']
  logo?: InputMaybe<Scalars['Upload']['input']>
}

export type ConsentFormLogoSetPayload = {
  __typename?: 'ConsentFormLogoSetPayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormUpdateInput = {
  allowConsentOnBehalf?: InputMaybe<Scalars['Boolean']['input']>
  consentExpiresAfterPeriod?: InputMaybe<Scalars['Duration']['input']>
  defaultLanguage?: InputMaybe<LanguageCodeEnum>
  description?: InputMaybe<Scalars['String']['input']>
  formId: Scalars['ID']['input']
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  privacyPolicyLink?: InputMaybe<Scalars['String']['input']>
  reference?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ConsentFormUpdatePayload = {
  __typename?: 'ConsentFormUpdatePayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  lastUpdatedAt?: InputMaybe<DateTimeFilterArg>
  updatedAt?: InputMaybe<DateTimeFilterArg>
}

export type ConsentFormsGetInput = {
  draftAccessToken?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<ConsentFormsGetFilterArg>
  order?: InputMaybe<Array<ConsentFormsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<ConsentFormsGetSearchArg>
}

export type ConsentFormsGetOrderArg = {
  by: ConsentFormsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum ConsentFormsGetOrderEnum {
  CreatedAt = 'createdAt',
  IsArchived = 'isArchived',
  IsDraft = 'isDraft',
  LastUpdatedAt = 'lastUpdatedAt',
  Reference = 'reference',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type ConsentFormsGetPayload = {
  __typename?: 'ConsentFormsGetPayload'
  forms: Array<ConsentForm>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

export type ConsentFormsGetSearchArg = {
  pattern: Scalars['String']['input']
}

export type ConsentGetFilterArg = {
  actorIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  conditionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  endedAt?: InputMaybe<DateTimeFilterArg>
  firstGrantedAt?: InputMaybe<DateTimeFilterArg>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  grantedAt?: InputMaybe<DateTimeFilterArg>
  /**
   * filtering by `isHidden` flag
   *   - `null` means _"return ALL consents"_
   *   - `true` means _"return only isHidden consents"
   *   - `false` means _"return only NOT isHidden consents"
   * default behavior : `false` (omit hidden)
   */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>
  isLastVerified?: InputMaybe<Scalars['Boolean']['input']>
  personEmails?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  personIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statusCodes?: InputMaybe<Array<ConsentStatusCodeEnum>>
}

export type ConsentGetInput = {
  filter?: InputMaybe<ConsentGetFilterArg>
  filterOperator?: InputMaybe<FilterBoolOperatorEnum>
  order?: InputMaybe<Array<ConsentGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<ConsentGetSearchArg>
}

export type ConsentGetOrderArg = {
  by: ConsentGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum ConsentGetOrderEnum {
  ConditionDescription = 'conditionDescription',
  EndedAt = 'endedAt',
  FirstGrantedAt = 'firstGrantedAt',
  FormDescription = 'formDescription',
  FormReference = 'formReference',
  FormTitle = 'formTitle',
  /**
   * Order by expiration date. It has no relation with `consent status`,
   * the expiration date exists for both: expired and acttve consents
   */
  GrantedAt = 'grantedAt',
  IsHidden = 'isHidden',
  PersonEmail = 'personEmail',
  PersonFirstName = 'personFirstName',
  PersonLastName = 'personLastName',
  Status = 'status',
}

export type ConsentGetPayload = {
  __typename?: 'ConsentGetPayload'
  consents: Array<ConsentCurrent>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

/**
 * Searches for matches on
 * - Form title
 * - Data subject name and email
 */
export type ConsentGetSearchArg = {
  pattern: Scalars['String']['input']
}

export type ConsentManualRevokeInput = {
  conditionIds: Array<Scalars['ID']['input']>
  personId: Scalars['ID']['input']
}

export type ConsentManualRevokePayload = {
  __typename?: 'ConsentManualRevokePayload'
  deedConsentManualRevoke: DeedConsentManualRevoke
  query: Query
}

export enum ConsentStatusCodeEnum {
  Expired = 'EXPIRED',
  Granted = 'GRANTED',
  Revoked = 'REVOKED',
}

export type Country = {
  __typename?: 'Country'
  countryId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isoCode: Scalars['String']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export type Currency = {
  __typename?: 'Currency'
  id: Scalars['ID']['output']
  isoCode: Scalars['String']['output']
  name: Scalars['String']['output']
  symbol: Scalars['String']['output']
}

export type Dashboard = {
  __typename?: 'Dashboard'
  dashboardId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isFavoriteForCurrentUser: Scalars['Boolean']['output']
  metadata: Scalars['String']['output']
  name: Scalars['String']['output']
  owner: User
  ownerId: Scalars['ID']['output']
  /**
   * This field contains calculated permissions for the current user for this
   * Dashboard (taking into account user roles, sharing through audiences and
   * sharing individually with users).
   */
  permissionForCurrentUser: DashboardUserPermission
  sharedWithAudiences: Array<DashboardShareWithAudienceInfo>
  /**
   * This field contains the list of personal shares with users and it
   * DOES NOT take into account other ways to share a Dashboard with users (like
   * with ADMINs, or by Audience)
   */
  sharedWithUsers: Array<DashboardUserPermission>
}

export type DashboardDeleteInput = {
  id: Scalars['ID']['input']
}

export type DashboardDeletePayload = {
  __typename?: 'DashboardDeletePayload'
  dashboard: Dashboard
  query: Query
}

export type DashboardFavoriteSetInput = {
  dashboardId?: InputMaybe<Scalars['ID']['input']>
}

export type DashboardFavoriteSetPayload = {
  __typename?: 'DashboardFavoriteSetPayload'
  dashboard?: Maybe<Dashboard>
  query: Query
}

export type DashboardGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  dashboardIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isFavoriteForCurrentUser?: InputMaybe<Scalars['Boolean']['input']>
}

export type DashboardGetInput = {
  filter?: InputMaybe<DashboardGetFilterArg>
  order?: InputMaybe<Array<DashboardGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DashboardGetOrderArg = {
  by: DashboardGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DashboardGetOrderEnum {
  CreatedAt = 'createdAt',
  IsFavoriteForCurrentUser = 'isFavoriteForCurrentUser',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type DashboardGetPayload = {
  __typename?: 'DashboardGetPayload'
  dashboards: Array<Dashboard>
  paginationInfo: PaginationInfo
  query: Query
}

export type DashboardShareDashboardInfoArg = {
  companyId: Scalars['ID']['input']
  metadata: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type DashboardShareInput = {
  audienceId?: InputMaybe<Scalars['ID']['input']>
  canEdit?: Scalars['Boolean']['input']
  dashboardId?: InputMaybe<Scalars['ID']['input']>
  dashboardInfo?: InputMaybe<DashboardShareDashboardInfoArg>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type DashboardSharePayload = {
  __typename?: 'DashboardSharePayload'
  dashboard: Dashboard
  query: Query
}

export type DashboardShareWithAudienceInfo = {
  __typename?: 'DashboardShareWithAudienceInfo'
  audience: Audience
  audienceId: Scalars['ID']['output']
  canEdit: Scalars['Boolean']['output']
}

export type DashboardUnshareInput = {
  audienceId?: InputMaybe<Scalars['ID']['input']>
  dashboardId: Scalars['ID']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type DashboardUnsharePayload = {
  __typename?: 'DashboardUnsharePayload'
  dashboard: Dashboard
  query: Query
}

export type DashboardUpsertInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  metadata?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  setAsFavorite?: InputMaybe<Scalars['Boolean']['input']>
}

export type DashboardUpsertPayload = {
  __typename?: 'DashboardUpsertPayload'
  dashboard: Dashboard
  query: Query
}

export type DashboardUserPermission = {
  __typename?: 'DashboardUserPermission'
  canEdit: Scalars['Boolean']['output']
  /**
   * This field here now is only to maintain semantics of the method. At the
   * moment if the user can't view a dashboard - user will never get it as an
   * output from GQL-queries
   *
   * At the moment, this field is always `true`
   */
  canView: Scalars['Boolean']['output']
  dashboard: Dashboard
  dashboardId: Scalars['ID']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type DataElement = IDatamapEntity & {
  __typename?: 'DataElement'
  dataElementId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isSensitive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataLifecycle = IDatamapEntity & {
  __typename?: 'DataLifecycle'
  dataLifecycleId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataOwnership = IDatamapEntity & {
  __typename?: 'DataOwnership'
  dataOwnershipId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataSharing = IDatamapEntity & {
  __typename?: 'DataSharing'
  dataSharingId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  needsValue: Scalars['Boolean']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataSource = IDatamapEntity & {
  __typename?: 'DataSource'
  dataSourceId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  needsValue: Scalars['Boolean']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataSubject = IDatamapEntity & {
  __typename?: 'DataSubject'
  dataSubjectId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Datamap = {
  __typename?: 'Datamap'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  datamapId: Scalars['ID']['output']
  datamapResponses: Array<IDatamapResponse>
  id: Scalars['ID']['output']
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  /** @deprecated use `session.survey` */
  survey?: Maybe<Survey>
  /** @deprecated use `session.surveyId` */
  surveyId?: Maybe<Scalars['ID']['output']>
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type DatamapAnswerOption = ISurveyAnswerOption & {
  __typename?: 'DatamapAnswerOption'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isSensitive?: Maybe<Scalars['Boolean']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type DatamapCustomAnswerOption = ISurveyAnswerOption & {
  __typename?: 'DatamapCustomAnswerOption'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  entity?: Maybe<IDatamapEntity>
  /** always `NULL` */
  id?: Maybe<Scalars['ID']['output']>
  isCustom: Scalars['Boolean']['output']
  isSensitive?: Maybe<Scalars['Boolean']['output']>
  /** always `NULL` */
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  /** always `NULL` */
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type DatamapDataElementResponse = IDatamapResponse & {
  __typename?: 'DatamapDataElementResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isSensitive?: Maybe<Scalars['Boolean']['output']>
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

export type DatamapDataSharingResponse = IDatamapResponse & {
  __typename?: 'DatamapDataSharingResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
  sharedWith3rdParty?: Maybe<Scalars['String']['output']>
  sharedWithDepartment?: Maybe<Department>
  sharedWithDepartmentId?: Maybe<Scalars['ID']['output']>
}

export type DatamapDataSourceResponse = IDatamapResponse & {
  __typename?: 'DatamapDataSourceResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  parentResponseId: Scalars['ID']['output']
  providedBy3rdParty?: Maybe<Scalars['String']['output']>
  providedByDepartment?: Maybe<Department>
  providedByDepartmentId?: Maybe<Scalars['ID']['output']>
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

export type DatamapResponse = IDatamapResponse & {
  __typename?: 'DatamapResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

/**
 * we implemented two kind of filters:
 * - with "operation" and "values"
 * - with every operation as a separate value
 *
 * both approaches results the same queries, the difference - readability
 */
export type DateTimeFilterArg = {
  eq?: InputMaybe<Scalars['DateTime']['input']>
  ge?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  le?: InputMaybe<Scalars['DateTime']['input']>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  operation?: InputMaybe<CompareOperationEnum>
  values?: InputMaybe<Array<Scalars['DateTime']['input']>>
}

export type DeedConsentManualRevoke = {
  __typename?: 'DeedConsentManualRevoke'
  actor: Person
  actorId: Scalars['ID']['output']
  deedConsentManualRevokeId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  person: Person
  personId: Scalars['ID']['output']
}

export type DeedConsentResponse = {
  __typename?: 'DeedConsentResponse'
  actor: Person
  actorId: Scalars['ID']['output']
  consentResponseId: Scalars['ID']['output']
  form: ConsentForm
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  person: Person
  personId: Scalars['ID']['output']
}

export type Department = IDatamapEntity & {
  __typename?: 'Department'
  company: Company
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  departmentId: Scalars['ID']['output']
  departmentTemplate?: Maybe<DepartmentTemplate>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type DepartmentTemplate = {
  __typename?: 'DepartmentTemplate'
  departmentTemplateId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export type DocboxDocument = {
  __typename?: 'DocboxDocument'
  createdAt: Scalars['DateTime']['output']
  createdByUser: User
  createdByUserId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  documentId: Scalars['ID']['output']
  /** Nullable only FOR DELETED files (output from `documentDelete`) */
  fileInfo?: Maybe<BinaryFileInfo>
  folder: DocboxFolder
  folderId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isPrivate: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  owner: User
  ownerId: Scalars['ID']['output']
  validUntil?: Maybe<Scalars['DateTime']['output']>
}

export type DocboxDocumentCreateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  file: Scalars['Upload']['input']
  folderId?: InputMaybe<Scalars['ID']['input']>
  isPrivate?: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  /** By default - current user */
  ownerId?: InputMaybe<Scalars['ID']['input']>
  validUntil?: InputMaybe<Scalars['DateTime']['input']>
}

export type DocboxDocumentCreatePayload = {
  __typename?: 'DocboxDocumentCreatePayload'
  document: DocboxDocument
  query: Query
}

export type DocboxDocumentDeleteFilterArg = {
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DocboxDocumentDeleteInput = {
  filter?: InputMaybe<DocboxDocumentDeleteFilterArg>
}

export type DocboxDocumentDeletePayload = {
  __typename?: 'DocboxDocumentDeletePayload'
  documents: Array<DocboxDocument>
  query: Query
}

export type DocboxDocumentUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  documentId: Scalars['ID']['input']
  folderId?: InputMaybe<Scalars['ID']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  validUntil?: InputMaybe<Scalars['DateTime']['input']>
}

export type DocboxDocumentUpdatePayload = {
  __typename?: 'DocboxDocumentUpdatePayload'
  document: DocboxDocument
  query: Query
}

export type DocboxFolder = {
  __typename?: 'DocboxFolder'
  company: Company
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  createdByUser: User
  createdByUserId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  folderId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isRoot: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  owner: User
  ownerId: Scalars['ID']['output']
  parentFolder?: Maybe<DocboxFolder>
  parentFolderId?: Maybe<Scalars['ID']['output']>
}

export type DocboxFolderCreateInput = {
  companyId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  /** By default - current user */
  ownerId?: InputMaybe<Scalars['ID']['input']>
  parentFolderId?: InputMaybe<Scalars['ID']['input']>
}

export type DocboxFolderCreatePayload = {
  __typename?: 'DocboxFolderCreatePayload'
  folder: DocboxFolder
  query: Query
}

export type DocboxFolderDeleteFilterArg = {
  folderIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DocboxFolderDeleteInput = {
  /** NOT IMPLEMENTED */
  deleteRecursive?: Scalars['Boolean']['input']
  filter?: InputMaybe<DocboxFolderDeleteFilterArg>
}

export type DocboxFolderDeletePayload = {
  __typename?: 'DocboxFolderDeletePayload'
  folders: Array<DocboxFolder>
  query: Query
}

export type DocboxFolderUpdateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  folderId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  parentFolderId?: InputMaybe<Scalars['ID']['input']>
}

export type DocboxFolderUpdatePayload = {
  __typename?: 'DocboxFolderUpdatePayload'
  folder: DocboxFolder
  query: Query
}

export type DocboxFoldersGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  folderIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DocboxFoldersGetInput = {
  filter?: InputMaybe<DocboxFoldersGetFilterArg>
}

export type DocboxFoldersGetPayload = {
  __typename?: 'DocboxFoldersGetPayload'
  folders?: Maybe<Array<DocboxFolder>>
}

export type DocboxGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  folderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  /** Ignores case */
  mimetypes?: InputMaybe<Array<Scalars['String']['input']>>
  /** Ignores case */
  names?: InputMaybe<Array<Scalars['String']['input']>>
}

export type DocboxGetInput = {
  filter?: InputMaybe<DocboxGetFilterArg>
  order?: InputMaybe<Array<DocboxGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<DocboxGetSearchArg>
}

export type DocboxGetOrderArg = {
  by: DocboxGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DocboxGetOrderEnum {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  FolderCreatedAt = 'folderCreatedAt',
  FolderName = 'folderName',
  Mimetype = 'mimetype',
  Name = 'name',
  OwnerId = 'ownerId',
  ValidUntil = 'validUntil',
}

export type DocboxGetPayload = {
  __typename?: 'DocboxGetPayload'
  documents?: Maybe<Array<DocboxDocument>>
  paginationInfo: PaginationInfo
}

export type DocboxGetSearchArg = {
  /**
   * Searches in:
   *   - document.name
   */
  pattern: Scalars['String']['input']
}

export type DsarForm = {
  __typename?: 'DsarForm'
  availableLanguages: Array<LanguageCodeEnum>
  company: Company
  companyCountryIsoCode: Scalars['String']['output']
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  customFields: Array<DsarFormCustomField>
  defaultLanguage: LanguageCodeEnum
  draftAccessToken: Scalars['String']['output']
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  lastUpdatedAt: Scalars['DateTime']['output']
  logo?: Maybe<BinaryFileInfoPublic>
  name?: Maybe<Scalars['String']['output']>
  regulation: Regulation
  regulationId: Scalars['ID']['output']
  requests: Array<DsarRequest>
  statusCode: DsarFormStatusCodeEnum
  translations: Array<DsarFormTranslation>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type DsarFormCreateInput = {
  companyId: Scalars['ID']['input']
  defaultLanguage: LanguageCodeEnum
  formTranslationInfos?: InputMaybe<Array<DsarFormTranslationArg>>
  logo?: InputMaybe<Scalars['Upload']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  regulationId: Scalars['ID']['input']
  requestDataSubjectTypeInfos?: InputMaybe<
    Array<DsarFormCreate_DataSubjectTypeInfosItemArg>
  >
}

export type DsarFormCreatePayload = {
  __typename?: 'DsarFormCreatePayload'
  form: DsarForm
  query: Query
}

export type DsarFormCreate_DataSubjectTypeInfosItemArg = {
  /**
   * This field is used to find similar `DsarRequestDataSubjectType` items
   * among all forms and companies.
   *
   * #DsarRequestDataSubjectType_groupingKey
   *
   * _This field is always transformed to lower case_.
   */
  groupingKey: Scalars['String']['input']
  translationInfos?: InputMaybe<
    Array<DsarFormCreate_DataSubjectTypeTranslationInfosItemArg>
  >
}

export type DsarFormCreate_DataSubjectTypeTranslationInfosItemArg = {
  language: LanguageCodeEnum
  name: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type DsarFormCustomField = {
  __typename?: 'DsarFormCustomField'
  description?: Maybe<Scalars['String']['output']>
  form: DsarForm
  formCustomFieldId: Scalars['ID']['output']
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isLocked: Scalars['Boolean']['output']
  isRequired: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  placeholder?: Maybe<Scalars['String']['output']>
  sortOrder: Scalars['NonNegativeInt']['output']
  translations?: Maybe<Array<DsarFormCustomFieldTranslation>>
  validationArg?: Maybe<Scalars['String']['output']>
  /** @deprecated use validationMethodCode */
  validationMethod?: Maybe<Scalars['String']['output']>
  validationMethodCode?: Maybe<QuestionAnswerValidationMethodCodeEnum>
}

export type DsarFormCustomFieldTranslation = {
  __typename?: 'DsarFormCustomFieldTranslation'
  description?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  language: LanguageCodeEnum
  placeholder?: Maybe<Scalars['String']['output']>
}

export type DsarFormCustomFieldUpsertInput = {
  description?: InputMaybe<Scalars['String']['input']>
  formId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  /**
   * An additional information about the field. It is a JSON, validated using
   * the schema in `@palqee-private/survey-question`, at the moment schema v0
   *
   * This field can contain:
   * - `label`
   * - `description`
   * - `placeholder`
   * - `answerDefinition` (with `type` and `format` fields)
   * - `translations'
   */
  meta?: InputMaybe<Scalars['String']['input']>
  placeholder?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type DsarFormCustomFieldUpsertPayload = {
  __typename?: 'DsarFormCustomFieldUpsertPayload'
  formCustomField: DsarFormCustomField
  query: Query
}

export type DsarFormCustomFieldsDeleteFilterArg = {
  formCustomFieldIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarFormCustomFieldsDeleteInput = {
  filter: DsarFormCustomFieldsDeleteFilterArg
}

export type DsarFormCustomFieldsDeletePayload = {
  __typename?: 'DsarFormCustomFieldsDeletePayload'
  deletedItemsCount: Scalars['Int']['output']
  query: Query
}

export type DsarFormDeleteInput = {
  formId: Scalars['ID']['input']
  withRequestsRecursively?: InputMaybe<Scalars['Boolean']['input']>
}

export type DsarFormDeletePayload = {
  __typename?: 'DsarFormDeletePayload'
  form: DsarForm
  query: Query
}

export type DsarFormGetOrderArg = {
  by: DsarFormGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarFormGetOrderEnum {
  CreatedAt = 'createdAt',
  LastUpdatedAt = 'lastUpdatedAt',
  Name = 'name',
  StatusCode = 'statusCode',
  UpdatedAt = 'updatedAt',
}

export type DsarFormLogoSetInput = {
  formId: Scalars['ID']['input']
  logo?: InputMaybe<Scalars['Upload']['input']>
}

export type DsarFormLogoSetPayload = {
  __typename?: 'DsarFormLogoSetPayload'
  form: DsarForm
  query: Query
}

export enum DsarFormStatusCodeEnum {
  Draft = 'DRAFT',
  Final = 'FINAL',
}

export type DsarFormTranslation = {
  __typename?: 'DsarFormTranslation'
  description?: Maybe<Scalars['String']['output']>
  font?: Maybe<Scalars['String']['output']>
  form: DsarForm
  formId: Scalars['ID']['output']
  formTranslationId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  language: LanguageCodeEnum
  privacyCookiesLink?: Maybe<Scalars['String']['output']>
  privacyPolicyLink?: Maybe<Scalars['String']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type DsarFormTranslationArg = {
  description?: InputMaybe<Scalars['String']['input']>
  font?: InputMaybe<Scalars['String']['input']>
  language: LanguageCodeEnum
  privacyCookiesLink?: InputMaybe<Scalars['String']['input']>
  privacyPolicyLink?: InputMaybe<Scalars['String']['input']>
  /** the order of the translation in the list of translations for the DSAR-form */
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type DsarFormTranslationCreateInput = {
  formId: Scalars['ID']['input']
  formTranslationInfo: DsarFormTranslationArg
}

export type DsarFormTranslationCreatePayload = {
  __typename?: 'DsarFormTranslationCreatePayload'
  formTranslation: DsarFormTranslation
  query: Query
}

export type DsarFormTranslationDeleteFilterArg = {
  formTranslationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarFormTranslationDeleteInput = {
  filter?: InputMaybe<DsarFormTranslationDeleteFilterArg>
}

export type DsarFormTranslationDeletePayload = {
  __typename?: 'DsarFormTranslationDeletePayload'
  formTranslations: Array<DsarFormTranslation>
  query: Query
}

export type DsarFormTranslationUpdateInput = {
  formTranslationId: Scalars['ID']['input']
  formTranslationInfo: DsarFormTranslationArg
}

export type DsarFormTranslationUpdatePayload = {
  __typename?: 'DsarFormTranslationUpdatePayload'
  formTranslation: DsarFormTranslation
  query: Query
}

export type DsarFormUpdateInput = {
  defaultLanguage?: InputMaybe<LanguageCodeEnum>
  formId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  statusCode?: InputMaybe<DsarFormStatusCodeEnum>
}

export type DsarFormUpdatePayload = {
  __typename?: 'DsarFormUpdatePayload'
  form: DsarForm
  query: Query
}

export type DsarFormsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statusCodes?: InputMaybe<Array<DsarFormStatusCodeEnum>>
}

export type DsarFormsGetInput = {
  draftAccessToken?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<DsarFormsGetFilterArg>
  order?: InputMaybe<Array<DsarFormGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarFormsGetPayload = {
  __typename?: 'DsarFormsGetPayload'
  forms: Array<DsarForm>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

export type DsarRequest = {
  __typename?: 'DsarRequest'
  administrativeArea?: Maybe<Scalars['String']['output']>
  channel?: Maybe<DsarRequestChannel>
  channelCode?: Maybe<DsarRequestChannelCodeEnum>
  comments: Array<DsarRequestComment>
  company: Company
  companyId: Scalars['ID']['output']
  completedAt?: Maybe<Scalars['DateTime']['output']>
  completedAtEffective?: Maybe<Scalars['DateTime']['output']>
  country: Country
  countryId: Scalars['ID']['output']
  customFieldValues: Array<DsarRequestCustomFieldValue>
  description?: Maybe<Scalars['String']['output']>
  email: Scalars['EmailAddress']['output']
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  expiredAtEffective?: Maybe<Scalars['DateTime']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  form?: Maybe<DsarForm>
  formId?: Maybe<Scalars['ID']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  historyItems: Array<DsarRequestHistoryItem>
  id: Scalars['ID']['output']
  isIdVerified: Scalars['Boolean']['output']
  language: LanguageCodeEnum
  lastName?: Maybe<Scalars['String']['output']>
  messages: Array<DsarRequestMessage>
  phoneNumber?: Maybe<Scalars['SloppyPhoneNumber']['output']>
  /** This key works like ID, but is more human friendly */
  publicKey: Scalars['String']['output']
  requestDataSubjectTypeId: Scalars['ID']['output']
  /**
   * Return the translation for `dataSubjectType` for this request. One can
   * select the desired language explicitly. The final tranlation will be chosen:
   * 0. using `input.language`
   * 1. using `request.language` (the language of the request, as the
   *   requestor filled it
   * 2. using `currentUser.language`
   * 3. first non-empty translation
   *
   * First **NON-empty** (by `name` field) translation will be returned.
   */
  requestDataSubjectTypeTranslation: DsarRequestDataSubjectTypeTranslation
  requestId: Scalars['ID']['output']
  requestedAt: Scalars['DateTime']['output']
  statusCode: DsarRequestStatusCodeEnum
  type: DsarRequestType
  typeId: Scalars['ID']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type DsarRequestRequestDataSubjectTypeTranslationArgs = {
  input?: InputMaybe<DsarRequestDataSubjectTypeTranslationArg>
}

export type DsarRequestChannel = {
  __typename?: 'DsarRequestChannel'
  code: DsarRequestChannelCodeEnum
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export enum DsarRequestChannelCodeEnum {
  Chatbox = 'CHATBOX',
  Email = 'EMAIL',
  Form = 'FORM',
  InPerson = 'IN_PERSON',
  Letter = 'LETTER',
  MessagingApp = 'MESSAGING_APP',
  Phone = 'PHONE',
  Sms = 'SMS',
}

export type DsarRequestChannelsGetPayload = {
  __typename?: 'DsarRequestChannelsGetPayload'
  query: Query
  requestChannels: Array<DsarRequestChannel>
}

export type DsarRequestComment = {
  __typename?: 'DsarRequestComment'
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  id?: Maybe<Scalars['ID']['output']>
  isFirstComment: Scalars['Boolean']['output']
  request: DsarRequest
  requestCommentId?: Maybe<Scalars['ID']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user: IUserWithEmail
}

export type DsarRequestCommentCreateInput = {
  content: Scalars['String']['input']
  requestId: Scalars['ID']['input']
}

export type DsarRequestCommentCreatePayload = {
  __typename?: 'DsarRequestCommentCreatePayload'
  query: Query
  requestComment: DsarRequestComment
}

export type DsarRequestCommentUpdateInput = {
  content: Scalars['String']['input']
  requestCommentId: Scalars['ID']['input']
}

export type DsarRequestCommentUpdatePayload = {
  __typename?: 'DsarRequestCommentUpdatePayload'
  query: Query
  requestComment: DsarRequestComment
}

export type DsarRequestCommentsFilterArg = {
  /**
   * Filtering by IDs of comments
   *
   * If you want to filter by this field and also want to include the comment
   * from the original message (it doesn't have ID, because it is a part of
   * the request), then use `NULL` value as an array item
   */
  commentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Filtering by author of the comment
   *
   * If you want to filter by this field and also want to include the comment
   * from the original applicant/requester (and they doesn't have ID,
   * because they are not Palqee users), then use `NULL` value as an array item.
   */
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type DsarRequestCommentsGetInput = {
  filter?: InputMaybe<DsarRequestCommentsFilterArg>
  order?: InputMaybe<Array<DsarRequestCommentsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarRequestCommentsGetOrderArg = {
  by: DsarRequestCommentsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestCommentsGetOrderEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  RequestId = 'requestId',
  UserId = 'userId',
}

export type DsarRequestCommentsGetPayload = {
  __typename?: 'DsarRequestCommentsGetPayload'
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
  requestComments: Array<DsarRequestComment>
}

export type DsarRequestConversationUnion =
  | DsarRequestComment
  | DsarRequestMessage

export type DsarRequestConversationsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Filtering by IDs of comments */
  requestCommentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestMessageIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Filtering by author of the comment
   *
   * If you want to filter by this field and also want to include the comment
   * from the original applicant/requester (and they doesn't have ID,
   * because they are not Palqee users), then use `NULL` value as an array item.
   */
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type DsarRequestConversationsGetInput = {
  filter?: InputMaybe<DsarRequestConversationsGetFilterArg>
  order?: InputMaybe<Array<DsarRequestConversationsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarRequestConversationsGetOrderArg = {
  by: DsarRequestConversationsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestConversationsGetOrderEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  RequestId = 'requestId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type DsarRequestConversationsGetPayload = {
  __typename?: 'DsarRequestConversationsGetPayload'
  conversations: Array<DsarRequestConversationUnion>
  paginationInfo: PaginationInfo
  query: Query
}

export type DsarRequestCreateCustomFieldValueInfo = {
  formCustomFieldId: Scalars['ID']['input']
  value: Scalars['String']['input']
}

export type DsarRequestCreateInput = {
  administrativeArea?: InputMaybe<Scalars['String']['input']>
  channelCode?: InputMaybe<DsarRequestChannelCodeEnum>
  companyId?: InputMaybe<Scalars['ID']['input']>
  countryOfResidenceId?: InputMaybe<Scalars['ID']['input']>
  customFieldValueInfos?: InputMaybe<
    Array<DsarRequestCreateCustomFieldValueInfo>
  >
  description: Scalars['String']['input']
  email: Scalars['EmailAddress']['input']
  firstName: Scalars['String']['input']
  formId?: InputMaybe<Scalars['ID']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  requestDataSubjectTypeId: Scalars['ID']['input']
  requestTypeId: Scalars['ID']['input']
  /** by default - current date */
  requestedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type DsarRequestCreatePayload = {
  __typename?: 'DsarRequestCreatePayload'
  query: Query
  request: DsarRequest
}

export type DsarRequestCustomFieldValue = {
  __typename?: 'DsarRequestCustomFieldValue'
  formCustomField: DsarFormCustomField
  formCustomFieldId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  request: DsarRequest
  requestCustomFieldValueId: Scalars['ID']['output']
  requestId: Scalars['ID']['output']
  value: Scalars['String']['output']
}

export type DsarRequestDataSubjectType = {
  __typename?: 'DsarRequestDataSubjectType'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  form?: Maybe<DsarForm>
  formId?: Maybe<Scalars['ID']['output']>
  /**
   * This field is used to find similar `DsarRequestDataSubjectType` items
   * among all forms and companies.
   *
   * #DsarRequestDataSubjectType_groupingKey
   *
   * _This field is always transformed to lower case_.
   */
  groupingKey: Scalars['String']['output']
  id: Scalars['ID']['output']
  requestDataSubjectTypeId: Scalars['ID']['output']
  /**
   * Indicates if `DsarRequestDataSubjectType` item is related
   * to a company (company-wide) or to a form ("per-form" item). In other words,
   * it describes _the scope or boundaries of applicability_ of the item.
   *
   * The only goal of this field is to make the separation between different
   * item-domains more transparent (company-wide items **never** overlap with
   * per-form items)
   */
  scopeCode: DsarRequestDataSubjectTypeScopeCodeEnum
  translations: Array<DsarRequestDataSubjectTypeTranslation>
}

export enum DsarRequestDataSubjectTypeScopeCodeEnum {
  CompanyWide = 'COMPANY_WIDE',
  FormSpecific = 'FORM_SPECIFIC',
}

/**
 * A `DsarRequestDataSubjectTypeTranslation` is a translation to one
 * of the available languages of the request's `DsarRequestDataSubjectType`.
 *
 * ----
 *
 * Please, note that there are two different and important identifiers here:
 *
 * 1. `requestDataSubjectTypeId` - identifier of the TYPE
 * 2. `requestDataSubjectTypeTranslationId` (or its copy `id`) - identifier of the TRANSLATION
 *
 * If you want to create a DSAR-request and set its `requestDataSubjectTypeId` you need
 * **the first one** — it's the expected identifier there.
 *
 * If you want to manage translations or DsarRequestDataSubjectTypes - then the second one
 * will help you to edit the appropriate translation.
 */
export type DsarRequestDataSubjectTypeTranslation = {
  __typename?: 'DsarRequestDataSubjectTypeTranslation'
  id: Scalars['ID']['output']
  language: LanguageCodeEnum
  name: Scalars['String']['output']
  requestDataSubjectType: DsarRequestDataSubjectType
  /**
   * This field is used to find similar `DsarRequestDataSubjectType` items
   * among all forms and companies.
   *
   * #DsarRequestDataSubjectType_groupingKey
   *
   * _This field is always transformed to lower case_.
   */
  requestDataSubjectTypeGroupingKey: Scalars['String']['output']
  requestDataSubjectTypeId: Scalars['ID']['output']
  /**
   * Indicates if the corresponding `DsarRequestDataSubjectType` item is related
   * to a company (company-wide) or to a form ("per-form" item). In other words,
   * it describes _the scope or boundaries of applicability_ of the item.
   *
   * The only goal of this field is to make the separation between different
   * item-domains more transparent (company-wide items **never** overlap with
   * per-form items)
   */
  requestDataSubjectTypeScopeCode: DsarRequestDataSubjectTypeScopeCodeEnum
  requestDataSubjectTypeTranslationId: Scalars['ID']['output']
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
}

export type DsarRequestDataSubjectTypeTranslationArg = {
  language?: InputMaybe<LanguageCodeEnum>
}

export type DsarRequestDataSubjectTypeTranslationsDeleteFilterArg = {
  requestDataSubjectTypeTranslationIds: Array<Scalars['ID']['input']>
}

export type DsarRequestDataSubjectTypeTranslationsDeleteInput = {
  filter?: InputMaybe<DsarRequestDataSubjectTypeTranslationsDeleteFilterArg>
}

export type DsarRequestDataSubjectTypeTranslationsDeletePayload = {
  __typename?: 'DsarRequestDataSubjectTypeTranslationsDeletePayload'
  query: Query
}

export type DsarRequestDataSubjectTypeTranslationsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  requestDataSubjectTypeGroupingKeys?: InputMaybe<
    Array<Scalars['String']['input']>
  >
  /**
   * NON STANDARD: by default it equals `['FORM_SPECIFIC']`, so unlike most
   * other queries you need to provide explicit values if you want to get more
   * items
   */
  requestDataSubjectTypeScopeCodes?: InputMaybe<
    Array<DsarRequestDataSubjectTypeScopeCodeEnum>
  >
}

export type DsarRequestDataSubjectTypeTranslationsGetInput = {
  filter?: InputMaybe<DsarRequestDataSubjectTypeTranslationsGetFilterArg>
  order?: InputMaybe<Array<DsarRequestDataSubjectTypeTranslationsGetOrderArg>>
}

export type DsarRequestDataSubjectTypeTranslationsGetOrderArg = {
  by: DsarRequestDataSubjectTypeTranslationsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestDataSubjectTypeTranslationsGetOrderEnum {
  FormName = 'formName',
  Language = 'language',
  RequestDataSubjectTypeId = 'requestDataSubjectTypeId',
  SortOrder = 'sortOrder',
}

export type DsarRequestDataSubjectTypeTranslationsGetPayload = {
  __typename?: 'DsarRequestDataSubjectTypeTranslationsGetPayload'
  query: Query
  requestDataSubjectTypeTranslations: Array<DsarRequestDataSubjectTypeTranslation>
}

export type DsarRequestDataSubjectTypesUpsertInput = {
  requestDataSubjectTypeInfos: Array<DsarRequestDataSubjectTypesUpsert_DataSubjectTypeInfosItemArg>
}

export type DsarRequestDataSubjectTypesUpsertPayload = {
  __typename?: 'DsarRequestDataSubjectTypesUpsertPayload'
  query: Query
  requestDataSubjectTypes: Array<DsarRequestDataSubjectType>
}

export type DsarRequestDataSubjectTypesUpsert_DataSubjectTypeInfosItemArg = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  formId?: InputMaybe<Scalars['ID']['input']>
  /**
   * This field is used to find similar `DsarRequestDataSubjectType` items
   * among all forms and companies.
   *
   * #DsarRequestDataSubjectType_groupingKey
   *
   * _This field is always transformed to lower case_.
   */
  groupingKey?: InputMaybe<Scalars['String']['input']>
  requestDataSubjectTypeId?: InputMaybe<Scalars['ID']['input']>
  translationInfos?: InputMaybe<
    Array<DsarRequestDataSubjectTypesUpsert_DataSubjectTypeTranslationInfoItemArg>
  >
}

export type DsarRequestDataSubjectTypesUpsert_DataSubjectTypeTranslationInfoItemArg =
  {
    language: LanguageCodeEnum
    name?: InputMaybe<Scalars['String']['input']>
    sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  }

export type DsarRequestHistoryItem = {
  __typename?: 'DsarRequestHistoryItem'
  comment?: Maybe<DsarRequestComment>
  createdAt: Scalars['DateTime']['output']
  id?: Maybe<Scalars['ID']['output']>
  /** @deprecated use Templates on the client side */
  message?: Maybe<Scalars['String']['output']>
  request: DsarRequest
  requestHistoryItemId?: Maybe<Scalars['ID']['output']>
  requestMessage?: Maybe<DsarRequestMessage>
  uiCode: Scalars['UICode']['output']
  user: IUserWithEmail
}

export type DsarRequestHistoryItemsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarRequestHistoryItemsGetInput = {
  filter?: InputMaybe<DsarRequestHistoryItemsGetFilterArg>
  order?: InputMaybe<Array<DsarRequestHistoryItemsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarRequestHistoryItemsGetOrderArg = {
  by: DsarRequestHistoryItemsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestHistoryItemsGetOrderEnum {
  CreatedAt = 'createdAt',
  FormId = 'formId',
  RequestId = 'requestId',
}

export type DsarRequestHistoryItemsGetPayload = {
  __typename?: 'DsarRequestHistoryItemsGetPayload'
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
  requestHistoryItems: Array<DsarRequestHistoryItem>
}

export type DsarRequestMessage = {
  __typename?: 'DsarRequestMessage'
  body: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  fromUser: IUserWithEmail
  /**
   * A request message can be created by two types of users:
   *
   * 1. Company Administrator (Authenticated)
   * 	In this case, field `fromUserID` contains Palqee User Id and `fromUser` resolves to GQL `User`
   *
   *
   * 2. Requestor (Anonymous)
   * 	Field `fromUserId` is null and `fromUser` is filled with data from Request. (email, firstName and lastName)
   */
  fromUserId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  inReplyToRequestMessage?: Maybe<DsarRequestMessage>
  inReplyToRequestMessageId?: Maybe<Scalars['ID']['output']>
  request: DsarRequest
  requestId: Scalars['ID']['output']
  requestMessageId: Scalars['ID']['output']
  senderKind: DsarRequestMessageSenderKindEnum
  subject: Scalars['String']['output']
}

export type DsarRequestMessageSendInput = {
  body: Scalars['String']['input']
  inReplyToRequestMessageId?: InputMaybe<Scalars['ID']['input']>
  requestId: Scalars['ID']['input']
  subject: Scalars['String']['input']
}

export type DsarRequestMessageSendPayload = {
  __typename?: 'DsarRequestMessageSendPayload'
  query: Query
  requestMessage: DsarRequestMessage
}

export enum DsarRequestMessageSenderKindEnum {
  CompanyAdministrator = 'COMPANY_ADMINISTRATOR',
  Requestor = 'REQUESTOR',
}

export enum DsarRequestStatusCodeEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Fulfilled = 'FULFILLED',
  IncorrectChannel = 'INCORRECT_CHANNEL',
  InProgress = 'IN_PROGRESS',
  MissingDocuments = 'MISSING_DOCUMENTS',
  NotFulfilled = 'NOT_FULFILLED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
}

export type DsarRequestType = {
  __typename?: 'DsarRequestType'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  regulation: Regulation
  regulationId: Scalars['ID']['output']
  requestTypeId: Scalars['ID']['output']
  sortOrder: Scalars['Int']['output']
  uiCode: Scalars['UICode']['output']
}

export type DsarRequestTypesFilterArg = {
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarRequestTypesGetInput = {
  filter?: InputMaybe<DsarRequestTypesFilterArg>
}

export type DsarRequestTypesGetPayload = {
  __typename?: 'DsarRequestTypesGetPayload'
  query: Query
  requestTypes: Array<DsarRequestType>
}

export type DsarRequestUpdateInput = {
  isIdVerified?: InputMaybe<Scalars['Boolean']['input']>
  requestId: Scalars['ID']['input']
  statusCode?: InputMaybe<DsarRequestStatusCodeEnum>
}

export type DsarRequestUpdatePayload = {
  __typename?: 'DsarRequestUpdatePayload'
  query: Query
  request: DsarRequest
}

export type DsarRequestor = IUserWithEmail & {
  __typename?: 'DsarRequestor'
  email: Scalars['EmailAddress']['output']
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export type DsarRequestsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  completedAt?: InputMaybe<DateTimeFilterArg>
  countryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  expiredAt?: InputMaybe<DateTimeFilterArg>
  publicKeys?: InputMaybe<Array<Scalars['String']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestedAt?: InputMaybe<DateTimeFilterArg>
}

export type DsarRequestsGetInput = {
  filter?: InputMaybe<DsarRequestsGetFilterArg>
  order?: InputMaybe<Array<DsarRequestsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<DsarRequestsGetSearchArg>
}

export type DsarRequestsGetOrderArg = {
  by: DsarRequestsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestsGetOrderEnum {
  ChannelCode = 'channelCode',
  CompanyId = 'companyId',
  CompanyName = 'companyName',
  CompletedAt = 'completedAt',
  CompletedAtEffective = 'completedAtEffective',
  CountryId = 'countryId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Email = 'email',
  ExpiredAt = 'expiredAt',
  ExpiredAtEffective = 'expiredAtEffective',
  FirstName = 'firstName',
  FormId = 'formId',
  FormName = 'formName',
  IsIdVerified = 'isIdVerified',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  RequestedAt = 'requestedAt',
  StatusCode = 'statusCode',
}

export type DsarRequestsGetPayload = {
  __typename?: 'DsarRequestsGetPayload'
  paginationInfo?: Maybe<PaginationInfo>
  requests: Array<DsarRequest>
}

export type DsarRequestsGetSearchArg = {
  /**
   * Searches in:
   *   - request.firstName
   *   - request.lastName
   *   - request.description
   */
  pattern: Scalars['String']['input']
}

export enum FilterBoolOperatorEnum {
  And = 'AND',
  Or = 'OR',
}

export type Fraction = {
  __typename?: 'Fraction'
  A?: Maybe<Scalars['Int']['output']>
  B?: Maybe<Scalars['Int']['output']>
  decimal?: Maybe<Scalars['Float']['output']>
}

export type FractionFloat = {
  __typename?: 'FractionFloat'
  A?: Maybe<Scalars['Float']['output']>
  B?: Maybe<Scalars['Float']['output']>
  decimal?: Maybe<Scalars['Float']['output']>
}

export type GeneralAnnualSalesRevenueListPayload = {
  __typename?: 'GeneralAnnualSalesRevenueListPayload'
  annualSalesRevenues?: Maybe<Array<AnnualSalesRevenue>>
}

export type GeneralAssetListPayload = {
  __typename?: 'GeneralAssetListPayload'
  assets: Array<Asset>
}

export type GeneralCompanyOnboardingProgressListInput = {
  companyId: Scalars['ID']['input']
}

export type GeneralCompanyOnboardingProgressStepPayload = {
  __typename?: 'GeneralCompanyOnboardingProgressStepPayload'
  stepCodes: Array<OnboardingProgressStepCodeEnum>
}

export type GeneralCompanyRegionLinkInput = {
  companyId: Scalars['ID']['input']
  regionIds: Array<Scalars['ID']['input']>
}

export type GeneralCompanyRegionLinkPayload = {
  __typename?: 'GeneralCompanyRegionLinkPayload'
  company: Company
}

export type GeneralCompanyRegionUnlinkInput = {
  companyId: Scalars['ID']['input']
  regionIds: Array<Scalars['ID']['input']>
}

export type GeneralCompanyRegionUnlinkPayload = {
  __typename?: 'GeneralCompanyRegionUnlinkPayload'
  company: Company
}

export type GeneralCompanyStructureListPayload = {
  __typename?: 'GeneralCompanyStructureListPayload'
  companyStructures?: Maybe<Array<CompanyStructure>>
}

export type GeneralCompanyTypeListPayload = {
  __typename?: 'GeneralCompanyTypeListPayload'
  companyTypes?: Maybe<Array<CompanyType>>
}

export type GeneralCountryListFilterArg = {
  countryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type GeneralCountryListInput = {
  filter?: InputMaybe<GeneralCountryListFilterArg>
  order?: InputMaybe<Array<GeneralCountryListOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type GeneralCountryListOrderArg = {
  by: GeneralCountryListOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum GeneralCountryListOrderEnum {
  IsoCode = 'isoCode',
  Name = 'name',
}

export type GeneralCountryListPayload = {
  __typename?: 'GeneralCountryListPayload'
  countries: Array<Country>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

export type GeneralCurrencyListPayload = {
  __typename?: 'GeneralCurrencyListPayload'
  currencies?: Maybe<Array<Currency>>
}

export type GeneralDepartmentTemplatesGetPayload = {
  __typename?: 'GeneralDepartmentTemplatesGetPayload'
  departmentTemplates: Array<DepartmentTemplate>
}

export type GeneralEmailSendInput = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  body: Scalars['String']['input']
  subject: Scalars['String']['input']
  to?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GeneralEmailSendPayload = {
  __typename?: 'GeneralEmailSendPayload'
  messagesSentCount: Scalars['NonNegativeInt']['output']
  query: Query
}

export type GeneralIndustryListPayload = {
  __typename?: 'GeneralIndustryListPayload'
  industries: Array<Industry>
}

export type GeneralModulesGetPayload = {
  __typename?: 'GeneralModulesGetPayload'
  modules: Array<PalqeeModule>
  query: Query
}

export type GeneralPositionTemplatesGetPayload = {
  __typename?: 'GeneralPositionTemplatesGetPayload'
  positionTemplates: Array<PositionTemplate>
}

export type GeneralProcessTemplateListPayload = {
  __typename?: 'GeneralProcessTemplateListPayload'
  processTemplates: Array<ProcessTemplate>
}

export type GeneralRegionGroupListPayload = {
  __typename?: 'GeneralRegionGroupListPayload'
  regionGroups: Array<RegionGroup>
}

export type GeneralRegionListFilterArg = {
  companyId: Scalars['ID']['input']
}

export type GeneralRegionListInput = {
  filter?: InputMaybe<GeneralRegionListFilterArg>
}

export type GeneralRegionListPayload = {
  __typename?: 'GeneralRegionListPayload'
  regions: Array<Region>
}

export type GeneralRegulationsPayload = {
  __typename?: 'GeneralRegulationsPayload'
  regulations: Array<Regulation>
}

export type GeneralSearchFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  palqeeModuleCodes?: InputMaybe<Array<PalqeeModuleCodeEnum>>
}

export type GeneralSearchInput = {
  filter?: InputMaybe<GeneralSearchFilterArg>
  order?: InputMaybe<Array<GeneralSearchOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<GeneralSearchSearchArg>
}

export type GeneralSearchMainEntitiesInput = {
  filter?: InputMaybe<GeneralSearchFilterArg>
  order?: InputMaybe<Array<GeneralSearchOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<GeneralSearchSearchArg>
}

export type GeneralSearchMainEntitiesPayload = {
  __typename?: 'GeneralSearchMainEntitiesPayload'
  paginationInfo: PaginationInfo
  query: Query
  results: Array<GeneralSearchMainEntitiesResultsItem>
}

export type GeneralSearchMainEntitiesResultEntityUnion =
  | DocboxDocument
  | DsarForm
  | DsarRequest
  | Survey
  | SurveySession
  | Task

export type GeneralSearchMainEntitiesResultsItem = {
  __typename?: 'GeneralSearchMainEntitiesResultsItem'
  entity: GeneralSearchMainEntitiesResultEntityUnion
}

export type GeneralSearchOrderArg = {
  by: GeneralSearchOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum GeneralSearchOrderEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
}

export type GeneralSearchPayload = {
  __typename?: 'GeneralSearchPayload'
  paginationInfo: PaginationInfo
  query: Query
  results: Array<GeneralSearchResultsItem>
}

export type GeneralSearchResultEntityUnion =
  | DocboxDocument
  | DsarForm
  | DsarRequest
  | DsarRequestType
  | Survey
  | SurveySubcategory
  | Task
  | TaskCategory
  | TaskPriority
  | TaskStatus

export type GeneralSearchResultsItem = {
  __typename?: 'GeneralSearchResultsItem'
  entity: GeneralSearchResultEntityUnion
}

export type GeneralSearchSearchArg = {
  pattern: Scalars['String']['input']
}

export type GeneralSubscriptionTiersFilterArg = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GeneralSubscriptionTiersGetInput = {
  filter?: InputMaybe<GeneralSubscriptionTiersFilterArg>
}

export type GeneralSubscriptionTiersGetPayload = {
  __typename?: 'GeneralSubscriptionTiersGetPayload'
  subscriptionTiers?: Maybe<Array<SubscriptionTier>>
}

/**
 * Palqee specification for CSV-file import:
 *
 * What is CSV: http://super-csv.github.io/super-csv/csv_specification.html
 *
 * Palqee's rules:
 *
 *   1. csv file
 *   2. encoding : utf8
 *   3. field separator/delimiter - `,` (comma). Line separator `\r\n` or `\n` (line break, windows or unix style)
 *   4. first line in the file - HEADERS, list of available headers below (and they depends on the language now)
 *   5. empty lines are ignored
 *   6. if a line starts with `#` - it is considered a comment, and it is ignored
 *   7. if one of the fields needs a `,` (comma) as a part of the value (for example, to pass the list of `roleCodes`) - wrap the field with `"` (double quotes), like this: `"  ADMINISTRATOR   ,  EMPLOYEE "`. If you want to use both `,` (comma) and `"` (double quote) as a value - wrap the value with `"` (double quote) and use two `"` , like this (CSV): `"Bernard ""Junior"", The second"` (the value is `Bernard "Junior", The second`)
 *   8. most leading and trailing white spaces (between commas) are ignored (see field specs below, for more details)
 */
export type GqlDocs_CsvFileUpload = {
  none?: InputMaybe<Scalars['Void']['input']>
}

export type HttpHeader = {
  __typename?: 'HttpHeader'
  header: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type IBinaryFileInfo = {
  /** Length is unknown for OLD files */
  contentLength?: Maybe<Scalars['NonNegativeInt']['output']>
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  mimetype: Scalars['String']['output']
  originalFilename?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type IBinaryFileInfoUrlArgs = {
  input?: InputMaybe<BinaryFileInfoUrlInput>
}

export type IDatamapEntity = {
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

/**
 * This is a part of the response, the generic part is stored in the
 * `SurveyReponse`, and this is information of the response, related to the
 * Datamap
 */
export type IDatamapResponse = {
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

export type ISubscriptionFeature = {
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  subscriptionFeatureId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type ISurveyAnswerOption = {
  id?: Maybe<Scalars['ID']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type IUserWithEmail = {
  email: Scalars['EmailAddress']['output']
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export type Industry = {
  __typename?: 'Industry'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Invitation = {
  __typename?: 'Invitation'
  createdAt: Scalars['DateTime']['output']
  invitationId: Scalars['ID']['output']
  invitedByUser?: Maybe<User>
  invitedByUserId?: Maybe<Scalars['ID']['output']>
  isCompleted: Scalars['Boolean']['output']
  lastRespondedAt?: Maybe<Scalars['DateTime']['output']>
  session: SurveySession
  sessionId: Scalars['ID']['output']
  /** @deprecated will be removed */
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user: User
  userId: Scalars['ID']['output']
}

export type KnowledgeBaseArticle = {
  __typename?: 'KnowledgeBaseArticle'
  description?: Maybe<Scalars['String']['output']>
  externalArticleInfo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  knowledgeBaseArticleId: Scalars['ID']['output']
  label: Scalars['String']['output']
}

export enum LanguageCodeEnum {
  De = 'de',
  En = 'en',
  Es = 'es',
  Pt = 'pt',
}

export type LegalBasis = IDatamapEntity & {
  __typename?: 'LegalBasis'
  id: Scalars['ID']['output']
  legalBasisId: Scalars['ID']['output']
  name: Scalars['String']['output']
  regulation?: Maybe<Regulation>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type MaturityScore = {
  __typename?: 'MaturityScore'
  maturityScore: Scalars['NonNegativeInt']['output']
  question: SurveyQuestion
  questionId: Scalars['ID']['output']
  session: SurveySession
  sessionId: Scalars['ID']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  admin: NamespaceAdminMutation
  auth: NamespaceAuthMutation
  company: NamespaceCompanyMutation
  consent: NamespaceConsentMutation
  dashboard: NamespaceDashboardMutation
  docbox: NamespaceDocboxMutation
  /**
   * obsolete
   * @deprecated use docbox
   */
  document: NamespaceDocboxMutation
  dsar: NamespaceDsarMutation
  general: NamespaceGeneralMutation
  survey: NamespaceSurveyMutation
  task: NamespaceTaskMutation
  user: NamespaceUserMutation
}

/**
 * Namespace: **admin**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAdminMutation = {
  __typename?: 'NamespaceAdminMutation'
  regionCreate: AdminRegionCreatePayload
}

/**
 * Namespace: **admin**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAdminMutationRegionCreateArgs = {
  input: AdminRegionCreateInput
}

/**
 * Namespace: **admin**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAdminQuery = {
  __typename?: 'NamespaceAdminQuery'
  messageTemplateRender: Scalars['Void']['output']
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutation = {
  __typename?: 'NamespaceAuthMutation'
  apiKeyCreate: AuthApiKeyCreatePayload
  apiKeyDelete: AuthApiKeyDeletePayload
  identityApplicationUpsert: AuthIdentityApplicationUpsertPayload
  login: AuthLoginPayload
  loginConfirm: AuthLoginConfirmPayload
  logout: AuthLogoutPayload
  /**
   * Set up MFA for an authenticated user.
   *
   * ### TOTP
   * Use a TOTP-application as a second factor.
   * For debug-purposes you could use WEB app.
   * See also: https://qoomon.github.io/otp-authenticator-webapp/
   *
   * ### SMS
   * Use phone as a second factor for an authentication.
   */
  mfaConfigure: AuthMfaConfigurePayload
  mfaConfigureConfirm: AuthMfaConfigureConfirmPayload
  mfaDisable: AuthMfaDisablePayload
  passwordChange: AuthPasswordChangePayload
  passwordRestoreConfirm: AuthPasswordRestoreConfirmPayload
  passwordRestoreInit: AuthPasswordRestoreInitPayload
  /**
   * For signing up on Palqee
   * If you want to add a new user to your company use `createUser()`
   */
  signUp: AuthSignUpPayload
  signUpConfirm: AuthSignUpConfirmPayload
  signUpResendCode: AuthSignUpResendCodePayload
  signUpResendCodeToPendingUsers: AuthSignUpResendCodeToPendingUsersPayload
  tokenRefresh: AuthTokenRefreshPayload
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationApiKeyCreateArgs = {
  input: AuthApiKeyCreateInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationApiKeyDeleteArgs = {
  input: AuthApiKeyDeleteInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationIdentityApplicationUpsertArgs = {
  input: AuthIdentityApplicationUpsertInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationLoginArgs = {
  input: AuthLoginInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationLoginConfirmArgs = {
  input: AuthLoginConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationMfaConfigureArgs = {
  input: AuthMfaConfigureInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationMfaConfigureConfirmArgs = {
  input: AuthMfaConfigureConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationMfaDisableArgs = {
  input?: InputMaybe<AuthMfaDisableInput>
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationPasswordChangeArgs = {
  input: AuthPasswordChangeInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationPasswordRestoreConfirmArgs = {
  input: AuthPasswordRestoreConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationPasswordRestoreInitArgs = {
  input: AuthPasswordRestoreInitInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpArgs = {
  input: AuthSignUpInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpConfirmArgs = {
  input: AuthSignUpConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpResendCodeArgs = {
  input: AuthSignUpResendCodeInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpResendCodeToPendingUsersArgs = {
  input: AuthSignUpResendCodeToPendingUsersInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationTokenRefreshArgs = {
  input: AuthTokenRefreshInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthQuery = {
  __typename?: 'NamespaceAuthQuery'
  apiKeysGet: AuthApiKeysGetPayload
  identityApplicationsGet: AuthIdentityApplicationsGetPayload
  /**
   * `userCurrent` checks the content of the HTTP-header `Authorization`. If
   * there is a valid `accessToken` then the request is considered
   * **authenticated**, and method returns information about authenticated user.
   *
   * If there is no `accessToken` in the HTTP-header — the request is considered
   * **anonymous**.
   *
   * If accessToken is invalid — an exception is raised.
   */
  userCurrent: AuthUserCurrentPayload
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthQueryApiKeysGetArgs = {
  input: AuthApiKeysGetInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthQueryIdentityApplicationsGetArgs = {
  input: AuthIdentityApplicationsGetInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthQueryUserCurrentArgs = {
  input?: InputMaybe<AuthUserCurrentInput>
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutation = {
  __typename?: 'NamespaceCompanyMutation'
  addressSet: CompanyAddressSetPayload
  assetConnectionCreate: CompanyAssetConnectionCreatePayload
  audienceCreate: CompanyAudienceCreatePayload
  audienceDelete: CompanyAudienceDeletePayload
  audienceUpdate: CompanyAudienceUpdatePayload
  audienceUsersLink: CompanyAudienceUsersLinkPayload
  audienceUsersUnlink: CompanyAudienceUsersUnlinkPayload
  create: CompanyCreatePayload
  delete: CompanyDeletePayload
  /**
   * Create a new department-entity for a company.
   *
   * If you provide the departmentTemplateId, then `name`, `description` and `uiCode` are copied to the new entity from
   * the template, and the new entity will be translated (just as the template itself)
   */
  departmentCreate: CompanyDepartmentCreatePayload
  industriesSet: CompanyIndustriesSetPayload
  logoSet: CompanyLogoSetPayload
  /** TODO: Pretty specific `save` behavior: it updates only the steps you pass in, but do not affect other steps */
  onboardingStepsSave: CompanyOnboardingStepsSavePayload
  /**
   * Create a new position-entity for a company.
   *
   * If you provide the positionTemplateId, then `name`, `description` and `uiCode` are copied to the new entity from
   * the template, and the new entity will be translated (just as the template itself)
   */
  positionCreate: CompanyPositionCreatePayload
  processCreate: CompanyProcessCreatePayload
  regulationLink: CompanyRegulationLinkPayload
  regulationUnlink: CompanyRegulationUnLinkPayload
  representativeAddressSet: CompanyRepresentativeAddressSetPayload
  representativeCreate: CompanyRepresentativeCreatePayload
  representativeDelete: CompanyRepresentativeDeletePayload
  representativeUpdate: CompanyRepresentativeUpdatePayload
  subscriptionTierSet: CompanySubscriptionTierSetPayload
  subscriptionTrialStart?: Maybe<CompanySubscriptionTrialStartPayload>
  update: CompanyUpdatePayload
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAddressSetArgs = {
  input: CompanyAddressSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAssetConnectionCreateArgs = {
  input: CompanyAssetConnectionCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceCreateArgs = {
  input: CompanyAudienceCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceDeleteArgs = {
  input: CompanyAudienceDeleteInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceUpdateArgs = {
  input: CompanyAudienceUpdateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceUsersLinkArgs = {
  input: CompanyAudienceUsersLinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceUsersUnlinkArgs = {
  input: CompanyAudienceUsersUnlinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationCreateArgs = {
  input: CompanyCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationDeleteArgs = {
  input: CompanyDeleteInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationDepartmentCreateArgs = {
  input: CompanyDepartmentCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationIndustriesSetArgs = {
  input: CompanyIndustriesSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationLogoSetArgs = {
  input: CompanyLogoSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationOnboardingStepsSaveArgs = {
  input: CompanyOnboardingStepsSaveInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationPositionCreateArgs = {
  input: CompanyPositionCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationProcessCreateArgs = {
  input: CompanyProcessCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRegulationLinkArgs = {
  input: CompanyRegulationLinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRegulationUnlinkArgs = {
  input: CompanyRegulationUnLinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeAddressSetArgs = {
  input: CompanyRepresentativeAddressSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeCreateArgs = {
  input: CompanyRepresentativeCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeDeleteArgs = {
  input: CompanyRepresentativeDeleteInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeUpdateArgs = {
  input: CompanyRepresentativeUpdateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationSubscriptionTierSetArgs = {
  input: CompanySubscriptionTierSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationUpdateArgs = {
  input: CompanyUpdateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQuery = {
  __typename?: 'NamespaceCompanyQuery'
  audiencesGet: CompanyAudiencesGetPayload
  companyFeatureUsagesGet: CompanyFeatureUsagesGetPayload
  companyFeaturesGet: CompanyFeaturesGetPayload
  departmentsGet: CompanyDepartmentsGetPayload
  get: CompanyGetPayload
  onboardingStepsGet: CompanyOnboardingStepsGetPayload
  positionsGet: CompanyPositionsGetPayload
  processesGet: CompanyProcessesGetPayload
  representativesGet: CompanyRepresentativesGetPayload
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryAudiencesGetArgs = {
  input: CompanyAudiencesGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryCompanyFeatureUsagesGetArgs = {
  input: CompanyFeatureUsagesGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryCompanyFeaturesGetArgs = {
  input: CompanyFeaturesGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryDepartmentsGetArgs = {
  input: CompanyDepartmentsGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryGetArgs = {
  input: CompanyGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryOnboardingStepsGetArgs = {
  input: CompanyOnboardingStepsGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryPositionsGetArgs = {
  input: CompanyPositionsGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryProcessesGetArgs = {
  input?: InputMaybe<CompanyProcessesGetInput>
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryRepresentativesGetArgs = {
  input: CompanyRepresentativesGetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutation = {
  __typename?: 'NamespaceConsentMutation'
  conditionArchivedSet: ConsentConditionArchivedSetPayload
  conditionCreate: ConsentConditionCreatePayload
  conditionDelete: ConsentConditionDeletePayload
  conditionUpdate: ConsentConditionUpdatePayload
  consentIsHiddenSet: ConsentConsentIsHiddenSetPayload
  consentManualRevoke: ConsentManualRevokePayload
  consentResponseSave: ConsentConsentResponseSavePayload
  consentResponseVerify: ConsentConsentResponseVerifyPayload
  formArchivedSet: ConsentFormArchivedSetPayload
  formCreate: ConsentFormCreatePayload
  formCustomFieldUpsert: ConsentFormCustomFieldUpsertPayload
  formCustomFieldsDelete: ConsentFormCustomFieldsDeletePayload
  formLogoSet: ConsentFormLogoSetPayload
  formUpdate: ConsentFormUpdatePayload
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionArchivedSetArgs = {
  input: ConsentConditionArchivedSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionCreateArgs = {
  input: ConsentConditionCreateInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionDeleteArgs = {
  input: ConsentConditionDeleteInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionUpdateArgs = {
  input: ConsentConditionUpdateInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentIsHiddenSetArgs = {
  input: ConsentConsentIsHiddenSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentManualRevokeArgs = {
  input: ConsentManualRevokeInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentResponseSaveArgs = {
  input: ConsentConsentResponseSaveInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentResponseVerifyArgs = {
  input: ConsentConsentResponseVerifyInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormArchivedSetArgs = {
  input: ConsentFormArchivedSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormCreateArgs = {
  input: ConsentFormCreateInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormCustomFieldUpsertArgs = {
  input: ConsentFormCustomFieldUpsertInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormCustomFieldsDeleteArgs = {
  input: ConsentFormCustomFieldsDeleteInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormLogoSetArgs = {
  input: ConsentFormLogoSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormUpdateArgs = {
  input: ConsentFormUpdateInput
}

/**
 * Namespace `consent`
 *
 * It contains all **Consent manager** functionality
 */
export type NamespaceConsentQuery = {
  __typename?: 'NamespaceConsentQuery'
  formsGet: ConsentFormsGetPayload
  get: ConsentGetPayload
}

/**
 * Namespace `consent`
 *
 * It contains all **Consent manager** functionality
 */
export type NamespaceConsentQueryFormsGetArgs = {
  input: ConsentFormsGetInput
}

/**
 * Namespace `consent`
 *
 * It contains all **Consent manager** functionality
 */
export type NamespaceConsentQueryGetArgs = {
  input: ConsentGetInput
}

/** Namespace: **dashboard** */
export type NamespaceDashboardMutation = {
  __typename?: 'NamespaceDashboardMutation'
  delete: DashboardDeletePayload
  favoriteSet: DashboardFavoriteSetPayload
  share: DashboardSharePayload
  unshare: DashboardUnsharePayload
  upsert: DashboardUpsertPayload
}

/** Namespace: **dashboard** */
export type NamespaceDashboardMutationDeleteArgs = {
  input: DashboardDeleteInput
}

/** Namespace: **dashboard** */
export type NamespaceDashboardMutationFavoriteSetArgs = {
  input: DashboardFavoriteSetInput
}

/** Namespace: **dashboard** */
export type NamespaceDashboardMutationShareArgs = {
  input: DashboardShareInput
}

/** Namespace: **dashboard** */
export type NamespaceDashboardMutationUnshareArgs = {
  input: DashboardUnshareInput
}

/** Namespace: **dashboard** */
export type NamespaceDashboardMutationUpsertArgs = {
  input: DashboardUpsertInput
}

/** Namespace `dashboard` */
export type NamespaceDashboardQuery = {
  __typename?: 'NamespaceDashboardQuery'
  get: DashboardGetPayload
}

/** Namespace `dashboard` */
export type NamespaceDashboardQueryGetArgs = {
  input: DashboardGetInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxMutation = {
  __typename?: 'NamespaceDocboxMutation'
  create: DocboxDocumentCreatePayload
  delete: DocboxDocumentDeletePayload
  folderCreate: DocboxFolderCreatePayload
  folderDelete: DocboxFolderDeletePayload
  folderUpdate: DocboxFolderUpdatePayload
  update: DocboxDocumentUpdatePayload
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxMutationCreateArgs = {
  input: DocboxDocumentCreateInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxMutationDeleteArgs = {
  input: DocboxDocumentDeleteInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxMutationFolderCreateArgs = {
  input: DocboxFolderCreateInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxMutationFolderDeleteArgs = {
  input: DocboxFolderDeleteInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxMutationFolderUpdateArgs = {
  input: DocboxFolderUpdateInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxMutationUpdateArgs = {
  input: DocboxDocumentUpdateInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxQuery = {
  __typename?: 'NamespaceDocboxQuery'
  foldersGet: DocboxFoldersGetPayload
  /**
   * Gets the list of DocBox-files
   *
   * By default returns files JUST FROM the root folder (not all files).
   */
  get: DocboxGetPayload
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxQueryFoldersGetArgs = {
  input?: InputMaybe<DocboxFoldersGetInput>
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocboxQueryGetArgs = {
  input?: InputMaybe<DocboxGetInput>
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutation = {
  __typename?: 'NamespaceDsarMutation'
  formCreate: DsarFormCreatePayload
  formCustomFieldUpsert: DsarFormCustomFieldUpsertPayload
  formCustomFieldsDelete: DsarFormCustomFieldsDeletePayload
  formDelete: DsarFormDeletePayload
  formLogoSet: DsarFormLogoSetPayload
  formTranslationCreate: DsarFormTranslationCreatePayload
  formTranslationDelete: DsarFormTranslationDeletePayload
  formTranslationUpdate: DsarFormTranslationUpdatePayload
  formUpdate: DsarFormUpdatePayload
  requestCommentCreate: DsarRequestCommentCreatePayload
  requestCommentUpdate: DsarRequestCommentUpdatePayload
  requestCreate: DsarRequestCreatePayload
  requestDataSubjectTypeTranslationsDelete: DsarRequestDataSubjectTypeTranslationsDeletePayload
  requestDataSubjectTypesUpsert: DsarRequestDataSubjectTypesUpsertPayload
  requestMessageSend: DsarRequestMessageSendPayload
  requestUpdate: DsarRequestUpdatePayload
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormCreateArgs = {
  input: DsarFormCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormCustomFieldUpsertArgs = {
  input: DsarFormCustomFieldUpsertInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormCustomFieldsDeleteArgs = {
  input: DsarFormCustomFieldsDeleteInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormDeleteArgs = {
  input: DsarFormDeleteInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormLogoSetArgs = {
  input: DsarFormLogoSetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormTranslationCreateArgs = {
  input: DsarFormTranslationCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormTranslationDeleteArgs = {
  input: DsarFormTranslationDeleteInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormTranslationUpdateArgs = {
  input: DsarFormTranslationUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormUpdateArgs = {
  input: DsarFormUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestCommentCreateArgs = {
  input: DsarRequestCommentCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestCommentUpdateArgs = {
  input: DsarRequestCommentUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestCreateArgs = {
  input: DsarRequestCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestDataSubjectTypeTranslationsDeleteArgs =
  {
    input: DsarRequestDataSubjectTypeTranslationsDeleteInput
  }

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestDataSubjectTypesUpsertArgs = {
  input: DsarRequestDataSubjectTypesUpsertInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestMessageSendArgs = {
  input: DsarRequestMessageSendInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestUpdateArgs = {
  input: DsarRequestUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQuery = {
  __typename?: 'NamespaceDsarQuery'
  formsGet: DsarFormsGetPayload
  requestChannelsGet: DsarRequestChannelsGetPayload
  requestCommentsGet: DsarRequestCommentsGetPayload
  requestConversationsGet: DsarRequestConversationsGetPayload
  /**
   * Returns DsarRequestDataSubjectType translations
   *
   * Be aware of not standard behavior of `input.filter.requestDataSubjectTypeScopeCodes`
   * (see docs for that parameter)
   */
  requestDataSubjectTypeTranslationsGet: DsarRequestDataSubjectTypeTranslationsGetPayload
  requestHistoryItemsGet: DsarRequestHistoryItemsGetPayload
  requestTypesGet: DsarRequestTypesGetPayload
  requestsGet: DsarRequestsGetPayload
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryFormsGetArgs = {
  input: DsarFormsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestCommentsGetArgs = {
  input: DsarRequestCommentsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestConversationsGetArgs = {
  input: DsarRequestConversationsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestDataSubjectTypeTranslationsGetArgs = {
  input: DsarRequestDataSubjectTypeTranslationsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestHistoryItemsGetArgs = {
  input: DsarRequestHistoryItemsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestTypesGetArgs = {
  input: DsarRequestTypesGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestsGetArgs = {
  input: DsarRequestsGetInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutation = {
  __typename?: 'NamespaceGeneralMutation'
  /**  TODO: MOVE TO COMPANY  */
  companyRegionLink: GeneralCompanyRegionLinkPayload
  /**  TODO: MOVE TO COMPANY  */
  companyRegionUnlink: GeneralCompanyRegionUnlinkPayload
  /** Sends free-form notifications to users. */
  emailSend: GeneralEmailSendPayload
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutationCompanyRegionLinkArgs = {
  input: GeneralCompanyRegionLinkInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutationCompanyRegionUnlinkArgs = {
  input: GeneralCompanyRegionUnlinkInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutationEmailSendArgs = {
  input: GeneralEmailSendInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuery = {
  __typename?: 'NamespaceGeneralQuery'
  annualSalesRevenueList: GeneralAnnualSalesRevenueListPayload
  assetList: GeneralAssetListPayload
  companyStructureList: GeneralCompanyStructureListPayload
  companyTypeList: GeneralCompanyTypeListPayload
  countryList: GeneralCountryListPayload
  currencyList: GeneralCurrencyListPayload
  departmentTemplatesGet: GeneralDepartmentTemplatesGetPayload
  industryList: GeneralIndustryListPayload
  modulesGet: GeneralModulesGetPayload
  positionTemplatesGet: GeneralPositionTemplatesGetPayload
  processTemplateList: GeneralProcessTemplateListPayload
  regionGroupList: GeneralRegionGroupListPayload
  regionList: GeneralRegionListPayload
  regulationsGet: GeneralRegulationsPayload
  /**
   * Performs "search" inside the requested Palqee-modules and returns the
   * matching information.
   */
  search: GeneralSearchPayload
  searchMainEntities: GeneralSearchMainEntitiesPayload
  subscriptionTiersGet: GeneralSubscriptionTiersGetPayload
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQueryCountryListArgs = {
  input?: InputMaybe<GeneralCountryListInput>
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQueryRegionListArgs = {
  input?: InputMaybe<GeneralRegionListInput>
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuerySearchArgs = {
  input: GeneralSearchInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuerySearchMainEntitiesArgs = {
  input: GeneralSearchMainEntitiesInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuerySubscriptionTiersGetArgs = {
  input?: InputMaybe<GeneralSubscriptionTiersGetInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQuery = {
  __typename?: 'NamespaceStatisticsQuery'
  completedSurveys: StatisticsCompletedSurveysPayload
  consentsCountByPeriod: StatisticsConsentsCountByPeriodPayload
  datamapResponsesCount: StatisticsDatamapResponsesCountPayload
  dsarRequestsAverage: StatisticsDsarRequestsAveragePayload
  dsarRequestsCount: StatisticsDsarRequestsCountPayload
  maturityScoreAverages: StatisticsMaturityScoreAveragesPayload
  respondedQuestions: StatisticsRespondedQuestionsPayload
  tasksCount: StatisticsTasksCountPayload
  /** number of users of total company size */
  workforcePercentage: StatisticsWorkforcePercentagePayload
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryCompletedSurveysArgs = {
  input?: InputMaybe<StatisticsCompletedSurveysInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryConsentsCountByPeriodArgs = {
  input?: InputMaybe<StatisticsConsentsCountByPeriodInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryDatamapResponsesCountArgs = {
  input?: InputMaybe<StatisticsDatamapResponsesCountInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryDsarRequestsAverageArgs = {
  input?: InputMaybe<StatisticsDsarRequestsAverageInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryDsarRequestsCountArgs = {
  input?: InputMaybe<StatisticsDsarRequestsCountInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryMaturityScoreAveragesArgs = {
  input?: InputMaybe<StatisticsMaturityScoreAveragesInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryRespondedQuestionsArgs = {
  input?: InputMaybe<StatisticsRespondedQuestionsInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryTasksCountArgs = {
  input?: InputMaybe<StatisticsTasksCountInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryWorkforcePercentageArgs = {
  input?: InputMaybe<StatisticsWorkforcePercentageInput>
}

export type NamespaceSurveyMutation = {
  __typename?: 'NamespaceSurveyMutation'
  answerOptionCreate: SurveyAnswerOptionCreatePayload
  answerOptionDelete: SurveyAnswerOptionDeletePayload
  answerOptionUpdate: SurveyAnswerOptionUpdatePayload
  copy: SurveyCopyPayload
  create: SurveyCreatePayload
  datamapInit: SurveyDatamapInitPayload
  delete: SurveyDeletePayload
  guidedStepCreate: SurveyGuidedStepCreatePayload
  guidedStepDelete: SurveyGuidedStepDeletePayload
  guidedStepUpdate: SurveyGuidedStepUpdatePayload
  invitationDelete: SurveyInvitationDeletePayload
  invitationsCreate: SurveyInvitationsCreatePayload
  /**
   * this method invites all users from provided audiences `audienceIds` to a survey session.
   *
   * **NOTE**: the method **DOES NOT** throw exceptions if some users from target audiences were already invited, and invites only new users.
   * @deprecated use `invitationsCreate`
   */
  inviteAudiences: SurveyInviteAudiencesPayload
  /**
   * this method invites particular users `userIds` to a survey session.
   *
   * **NOTE**: the method throws exceptions when on attempt to invite already invited users
   * @deprecated use `invitationsCreate`
   */
  inviteUsers: SurveyInviteUsersPayload
  maturityScoreSet: SurveyMaturityScoreSetPayload
  questionCreate: SurveyQuestionCreatePayload
  questionDelete: SurveyQuestionDeletePayload
  questionMatrixCreate: SurveyQuestionMatrixCreatePayload
  questionMatrixDelete: SurveyQuestionMatrixDeletePayload
  questionMatrixQuestionsLink: SurveyQuestionMatrixQuestionsLinkPayload
  questionMatrixQuestionsUnlink: SurveyQuestionMatrixQuestionsUnlinkPayload
  questionMatrixUpdate: SurveyQuestionMatrixUpdatePayload
  questionUpdate: SurveyQuestionUpdatePayload
  reminderToInvitedNotRespondedSend: SurveyReminderToInvitedNotRespondedSendPayload
  /** @deprecated use `responsesCreate` */
  responseCreate: SurveyResponseCreatePayload
  responseDelete: SurveyResponseDeletePayload
  responseDocumentRemove: SurveyResponseDocumentRemovePayload
  /** Uploads and attaches a document to the existing response */
  responseDocumentUpload: SurveyResponseDocumentUploadPayload
  responseUpdate: SurveyResponseUpdatePayload
  responsesChangeRespondent: SurveyResponsesChangeRespondentPayload
  responsesCreate: SurveyResponsesCreatePayload
  /**
   * This method will prepare a URL for secure uploading a CSV file with responses for further import to the DB.
   *
   * One has to upload the import file to the provided URL. This file must have a CSV format (see GqlDocs_CsvFileUpload type)
   *
   * Available HEADERS (fields) in the CSV:
   *
   * (a) for Datamap surveys:
   *
   * - `departmentName`           (spaces trimmed, empty value => field ignored)
   * - `processName`              (spaces trimmed, empty value => field ignored)
   * - `processingType`           (spaces trimmed, empty value => field ignored)
   * - `dataProcessingRegion`     (spaces trimmed, empty value => field ignored)
   * - `legalBasis`               (spaces trimmed, empty value => field ignored)
   * - `dataOwnership`            (spaces trimmed, empty value => field ignored)
   * - `dataSubject`              (spaces trimmed, empty value => field ignored)
   * - `dataLifecycle`            (spaces trimmed, empty value => field ignored)
   * - `asset`                    (spaces trimmed, empty value => field ignored)
   * - `dataSharing`              (spaces trimmed, empty value => field ignored)
   * - `dataSource`               (spaces trimmed, empty value => field ignored)
   * - `dataElement`              (spaces trimmed, empty value => field ignored)
   * - `customQuestionProcess1`   (spaces trimmed, empty value => field ignored)
   * - ...
   * - `customQuestionProcessN`   (spaces trimmed, empty value => field ignored)
   * - `dataElementIsSensitive`   (boolean, empty/unrecognized value => field ignored, availale values:
   *     `'yes', '1', 'on', 'enable', 'enabled', 'true'` and
   *     `'no', '0', 'off', 'disable', 'disabled', 'false'`)
   * - `palqeeProcessingResult` (will be ignored during processing, but will be added to the report, with the description of errors)
   *
   * (b) for other surveys:
   *
   * - `questionNumber`           number of the question (1-based) (spaces trimmed, empty value => field ignored)
   * - `value`                    (spaces trimmed, empty value => field ignored)
   * - `palqeeProcessingResult` (will be ignored during processing, but will be added to the report, with the description of errors)
   */
  responsesImportPrepareUrl: SurveyResponsesImportPrepareUrlPayload
  scheduleCreate: SurveyScheduleCreatePayload
  scheduleDelete: SurveyScheduleDeletePayload
  scheduleUpdate: SurveyScheduleUpdatePayload
  sessionCreate: SurveySessionCreatePayload
  sessionDelete: SurveySessionDeletePayload
  sessionUpdate: SurveySessionUpdatePayload
  subcategoryCreate: SurveySubcategoryCreatePayload
  subcategoryCreateFromTemplate: SurveySubcategoryCreateFromTemplatePayload
  subcategoryDelete: SurveySubcategoryDeletePayload
  subcategoryTemplateUpdate: SurveySubcategoryTemplateUpdatePayload
  subcategoryUpdate: SurveySubcategoryUpdatePayload
  surveyAudiencesSet: SurveySurveyAudiencesSetPayload
  surveyConvertIntoTemplate: SurveySurveyConvertIntoTemplatePayload
  update: SurveyUpdatePayload
}

export type NamespaceSurveyMutationAnswerOptionCreateArgs = {
  input: SurveyAnswerOptionCreateInput
}

export type NamespaceSurveyMutationAnswerOptionDeleteArgs = {
  input: SurveyAnswerOptionDeleteInput
}

export type NamespaceSurveyMutationAnswerOptionUpdateArgs = {
  input: SurveyAnswerOptionUpdateInput
}

export type NamespaceSurveyMutationCopyArgs = {
  input: SurveyCopyInput
}

export type NamespaceSurveyMutationCreateArgs = {
  input: SurveyCreateInput
}

export type NamespaceSurveyMutationDatamapInitArgs = {
  input: SurveyDatamapInitInput
}

export type NamespaceSurveyMutationDeleteArgs = {
  input: SurveyDeleteInput
}

export type NamespaceSurveyMutationGuidedStepCreateArgs = {
  input: SurveyGuidedStepCreateInput
}

export type NamespaceSurveyMutationGuidedStepDeleteArgs = {
  input: SurveyGuidedStepDeleteInput
}

export type NamespaceSurveyMutationGuidedStepUpdateArgs = {
  input: SurveyGuidedStepUpdateInput
}

export type NamespaceSurveyMutationInvitationDeleteArgs = {
  input: SurveyInvitationDeleteInput
}

export type NamespaceSurveyMutationInvitationsCreateArgs = {
  input: SurveyInvitationsCreateInput
}

export type NamespaceSurveyMutationInviteAudiencesArgs = {
  input: SurveyInviteAudiencesInput
}

export type NamespaceSurveyMutationInviteUsersArgs = {
  input: SurveyInviteUsersInput
}

export type NamespaceSurveyMutationMaturityScoreSetArgs = {
  input: SurveyMaturityScoreSetInput
}

export type NamespaceSurveyMutationQuestionCreateArgs = {
  input: SurveyQuestionCreateInput
}

export type NamespaceSurveyMutationQuestionDeleteArgs = {
  input: SurveyQuestionDeleteInput
}

export type NamespaceSurveyMutationQuestionMatrixCreateArgs = {
  input: SurveyQuestionMatrixCreateInput
}

export type NamespaceSurveyMutationQuestionMatrixDeleteArgs = {
  input: SurveyQuestionMatrixDeleteInput
}

export type NamespaceSurveyMutationQuestionMatrixQuestionsLinkArgs = {
  input: SurveyQuestionMatrixQuestionsLinkInput
}

export type NamespaceSurveyMutationQuestionMatrixQuestionsUnlinkArgs = {
  input: SurveyQuestionMatrixQuestionsUnlinkInput
}

export type NamespaceSurveyMutationQuestionMatrixUpdateArgs = {
  input: SurveyQuestionMatrixUpdateInput
}

export type NamespaceSurveyMutationQuestionUpdateArgs = {
  input: SurveyQuestionUpdateInput
}

export type NamespaceSurveyMutationReminderToInvitedNotRespondedSendArgs = {
  input: SurveyReminderToInvitedNotRespondedSendInput
}

export type NamespaceSurveyMutationResponseCreateArgs = {
  input: SurveyResponseCreateInput
}

export type NamespaceSurveyMutationResponseDeleteArgs = {
  input: SurveyResponseDeleteInput
}

export type NamespaceSurveyMutationResponseDocumentRemoveArgs = {
  input: SurveyResponseDocumentRemoveInput
}

export type NamespaceSurveyMutationResponseDocumentUploadArgs = {
  input: SurveyResponseDocumentUploadInput
}

export type NamespaceSurveyMutationResponseUpdateArgs = {
  input: SurveyResponseUpdateInput
}

export type NamespaceSurveyMutationResponsesChangeRespondentArgs = {
  input: SurveyResponsesChangeRespondentInput
}

export type NamespaceSurveyMutationResponsesCreateArgs = {
  input: SurveyResponsesCreateInput
}

export type NamespaceSurveyMutationResponsesImportPrepareUrlArgs = {
  input: SurveyResponsesImportPrepareUrlInput
}

export type NamespaceSurveyMutationScheduleCreateArgs = {
  input: SurveyScheduleCreateInput
}

export type NamespaceSurveyMutationScheduleDeleteArgs = {
  input: SurveyScheduleDeleteInput
}

export type NamespaceSurveyMutationScheduleUpdateArgs = {
  input: SurveyScheduleUpdateInput
}

export type NamespaceSurveyMutationSessionCreateArgs = {
  input: SurveySessionCreateInput
}

export type NamespaceSurveyMutationSessionDeleteArgs = {
  input: SurveySessionDeleteInput
}

export type NamespaceSurveyMutationSessionUpdateArgs = {
  input: SurveySessionUpdateInput
}

export type NamespaceSurveyMutationSubcategoryCreateArgs = {
  input: SurveySubcategoryCreateInput
}

export type NamespaceSurveyMutationSubcategoryCreateFromTemplateArgs = {
  input: SurveySubcategoryCreateFromTemplateInput
}

export type NamespaceSurveyMutationSubcategoryDeleteArgs = {
  input: SurveySubcategoryDeleteInput
}

export type NamespaceSurveyMutationSubcategoryTemplateUpdateArgs = {
  input: SurveySubcategoryTemplateUpdateInput
}

export type NamespaceSurveyMutationSubcategoryUpdateArgs = {
  input: SurveySubcategoryUpdateInput
}

export type NamespaceSurveyMutationSurveyAudiencesSetArgs = {
  input: SurveySurveyAudiencesSetInput
}

export type NamespaceSurveyMutationSurveyConvertIntoTemplateArgs = {
  input: SurveySurveyConvertIntoTemplateInput
}

export type NamespaceSurveyMutationUpdateArgs = {
  input: SurveyUpdateInput
}

export type NamespaceSurveyQuery = {
  __typename?: 'NamespaceSurveyQuery'
  categoriesGet: SurveyCategoriesGetPayload
  datamapResponsesGet: SurveyDatamapResponsesGetPayload
  datamapsGet: SurveyDatamapsGetPayload
  /** Get the list of surveys for a company */
  get: SurveyGetPayload
  guidedStepsGet: SurveyGuidedStepsPayload
  invitationsGet: SurveyInvitationsGetPayload
  maturityScoresGet: SurveyMaturityScoresGetPayload
  /**
   * The method takes `sessionIds` as input and:
   * - applies a filter to input `userIds` - returning only users who are not invited to the provided sessions
   * - applies a filter to input `audienceIds` - returning audiences that **during runtime** have users without invitations to the provided sessions
   * * `userIds` and `audienceIds` are independent inputs that don't interfere on each other
   */
  notInvitedGet: SurveyNotInvitedGetPayload
  questionTypesGet: SurveyQuestionTypesGetPayload
  questionsGet: SurveyQuestionsGetPayload
  responsesGet: SurveyResponsesGetPayload
  sessionsGet: SurveySessionsGetPayload
  subcategoryTemplatesGet: SurveySubcategoryTemplatesGetPayload
}

export type NamespaceSurveyQueryCategoriesGetArgs = {
  input?: InputMaybe<SurveyCategoriesGetInput>
}

export type NamespaceSurveyQueryDatamapResponsesGetArgs = {
  input?: InputMaybe<SurveyDatamapResponsesGetInput>
}

export type NamespaceSurveyQueryDatamapsGetArgs = {
  input?: InputMaybe<SurveyDatamapsGetInput>
}

export type NamespaceSurveyQueryGetArgs = {
  input?: InputMaybe<SurveyGetInput>
}

export type NamespaceSurveyQueryGuidedStepsGetArgs = {
  input?: InputMaybe<SurveyGuidedStepsGetInput>
}

export type NamespaceSurveyQueryInvitationsGetArgs = {
  input: SurveyInvitationsGetInput
}

export type NamespaceSurveyQueryMaturityScoresGetArgs = {
  input: SurveyMaturityScoresGetInput
}

export type NamespaceSurveyQueryNotInvitedGetArgs = {
  input: SurveyNotInvitedGetInput
}

export type NamespaceSurveyQueryQuestionTypesGetArgs = {
  input?: InputMaybe<SurveyQuestionTypesGetInput>
}

export type NamespaceSurveyQueryQuestionsGetArgs = {
  input: SurveyQuestionsGetInput
}

export type NamespaceSurveyQueryResponsesGetArgs = {
  input?: InputMaybe<SurveyResponsesGetInput>
}

export type NamespaceSurveyQuerySessionsGetArgs = {
  input?: InputMaybe<SurveySessionsGetInput>
}

export type NamespaceSurveyQuerySubcategoryTemplatesGetArgs = {
  input?: InputMaybe<SurveySubcategoryTemplatesGetInput>
}

/** Namespace: **task** */
export type NamespaceTaskMutation = {
  __typename?: 'NamespaceTaskMutation'
  /**
   * **NOTES**: this method affects just `isEmbed === false` attachments, in
   * order to change other attachments - use `task.update`
   */
  attachmentRemove: TaskAttachmentRemovePayload
  attachmentUpload: TaskAttachmentUploadPayload
  categoryCreate: TaskCategoryCreatePayload
  categoryDelete: TaskCategoryDeletePayload
  categoryUpdate: TaskCategoryUpdatePayload
  commentCreate: TaskCommentCreatePayload
  copy: TaskCopyPayload
  create: TaskCreatePayload
  delete: TaskDeletePayload
  statusCreate: TaskStatusCreatePayload
  statusDelete: TaskStatusDeletePayload
  statusUpdate: TaskStatusUpdatePayload
  statusesModify: TaskStatusesModifyPayload
  /**
   * Set tasks "archived"
   *
   * **NOTE 1**
   *
   * The method **silently ignore** the tasks that already are in the desired state,
   * this means:
   *
   * ```
   * taskArchivedSet(true, taskIds)
   * ```
   *
   * will ignore already archived tasks, **without throwing errors**.
   *
   * **NOTE 2**
   * At the moment UN-archive doesn't cause the RE-ordering the tasks, even though
   * the un-archived task goes to company's NEW status
   */
  taskArchivedSet: TaskTaskArchivedSetPayload
  taskAssigneeAudienceLink: TaskTaskAssigneeAudienceLinkPayload
  taskAssigneeAudienceUnlink: TaskTaskAssigneeAudienceUnlinkPayload
  /**  The method ignores already linked `assigneeIds` */
  taskAssigneeUserLink: TaskTaskAssigneeUserLinkPayload
  taskAssigneeUserUnlink: TaskTaskAssigneeUserUnlinkPayload
  /**  The method ignores already linked `categoryIds` */
  taskCategoryLink: TaskCategoryLinkPayload
  taskCategoryUnlink: TaskCategoryUnlinkPayload
  /**  The method ignores already linked `documentIds` */
  taskDocboxDocumentLink: TaskDocboxDocumentLinkPayload
  taskDocboxDocumentUnlink: TaskDocboxDocumentUnlinkPayload
  /**  The method ignores already linked `requestIds` */
  taskDsarRequestLink: TaskDsarRequestLinkPayload
  taskDsarRequestUnlink: TaskDsarRequestUnlinkPayload
  /**  The method ignores already linked `linkUrlIds` */
  taskLinkedUrlCreate: TaskLinkedUrlCreatePayload
  taskLinkedUrlDelete: TaskLinkedUrlDeletePayload
  /**  The method ignores already linked `sessionIds` */
  taskSurveySessionLink: TaskSurveySessionLinkPayload
  taskSurveySessionUnlink: TaskSurveySessionUnlinkPayload
  update: TaskUpdatePayload
}

/** Namespace: **task** */
export type NamespaceTaskMutationAttachmentRemoveArgs = {
  input: TaskAttachmentRemoveInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationAttachmentUploadArgs = {
  input: TaskAttachmentUploadInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCategoryCreateArgs = {
  input: TaskCategoryCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCategoryDeleteArgs = {
  input: TaskCategoryDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCategoryUpdateArgs = {
  input: TaskCategoryUpdateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCommentCreateArgs = {
  input: TaskCommentCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCopyArgs = {
  input: TaskCopyInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCreateArgs = {
  input: TaskCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationDeleteArgs = {
  input: TaskDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusCreateArgs = {
  input: TaskStatusCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusDeleteArgs = {
  input: TaskStatusDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusUpdateArgs = {
  input: TaskStatusUpdateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusesModifyArgs = {
  input: TaskStatusesModifyInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskArchivedSetArgs = {
  input: TaskTaskArchivedSetInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeAudienceLinkArgs = {
  input: TaskTaskAssigneeAudienceLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeAudienceUnlinkArgs = {
  input: TaskTaskAssigneeAudienceUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeUserLinkArgs = {
  input: TaskTaskAssigneeUserLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeUserUnlinkArgs = {
  input: TaskTaskAssigneeUserUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskCategoryLinkArgs = {
  input: TaskCategoryLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskCategoryUnlinkArgs = {
  input: TaskCategoryUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDocboxDocumentLinkArgs = {
  input: TaskDocboxDocumentLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDocboxDocumentUnlinkArgs = {
  input: TaskDocboxDocumentUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDsarRequestLinkArgs = {
  input: TaskDsarRequestLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDsarRequestUnlinkArgs = {
  input: TaskDsarRequestUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskLinkedUrlCreateArgs = {
  input: TaskLinkedUrlCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskLinkedUrlDeleteArgs = {
  input: TaskLinkedUrlDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskSurveySessionLinkArgs = {
  input: TaskSurveySessionLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskSurveySessionUnlinkArgs = {
  input: TaskSurveySessionUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationUpdateArgs = {
  input: TaskUpdateInput
}

/** Namespace: **task** */
export type NamespaceTaskQuery = {
  __typename?: 'NamespaceTaskQuery'
  categoriesGet: TaskCategoriesGetPayload
  /** Get the list of tasks for a company */
  get: TaskGetPayload
  historyItemsGet: TaskHistoryItemsGetPayload
  prioritiesGet: TaskPrioritiesGetPayload
  statusesGet: TaskStatusesGetPaylod
}

/** Namespace: **task** */
export type NamespaceTaskQueryCategoriesGetArgs = {
  input: TaskCategoriesGetInput
}

/** Namespace: **task** */
export type NamespaceTaskQueryGetArgs = {
  input: TaskGetInput
}

/** Namespace: **task** */
export type NamespaceTaskQueryHistoryItemsGetArgs = {
  input: TaskHistoryItemsGetInput
}

/** Namespace: **task** */
export type NamespaceTaskQueryStatusesGetArgs = {
  input: TaskStatusesGetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutation = {
  __typename?: 'NamespaceUserMutation'
  create: UserCreatePayload
  /** **`createOrLink`** - inserts a new user or add new link the existing one. */
  createOrLinkToCompany: UserCreateOrLinkToCompanyPayload
  /** **WARNING**: the method deletes users (and related data) and you can not rollback this changes. */
  delete: UserDeletePayload
  positionSet: UserPositionSetPayload
  rolesLink: UserRolesLinkPayload
  rolesSet: UserRolesSetPayload
  rolesUnlink: UserRolesUnlinkPayload
  /**
   * **`upsert`** - inserts a new user or update the existing one.
   *
   * When `userId` is not empty the server will try to find the user using only
   * `userId` (_ignoring email_ - whenever it was provided or not), and then:
   *
   *   - if the user was found — will update the record (if `email` contains
   *     non-empty value — it will be updated too)
   *   - if the user was not found - will create a new user — common behavior for
   *     any `upsert` methods (and this update could lead to conflict of the
   *     email uniquness - then methods will `throw`)
   *
   * **NON STANDARD BEHAVIOR** for `upsert` methods: if you pass non empty
   * `email` and empty `userId` (which is a primary key) - then the method will
   * try to find the user **by email** and then:
   *
   *  - if the user was found — update other fields of the user-record;
   *  - if the user was not found - will create a new user;
   *
   * When both `userId` and `email` are empty - error will be thrown
   */
  upsert: UserUpsertPayload
  /**
   * This method will prepare an URL for secure uploading the CSV file with users' data for further processing.
   *
   * One has to upload the import file to the provided URL. This file must have a CSV format (see GqlDocs_CsvFileUpload type)
   *
   * Available HEADERS (fields) in the CSV:
   *
   * - `email`          (**mandatory**, spaces trimmed, empty value causes error)
   * - `phoneNumber`    (spaces trimmed, empty value => field ignored)
   * - `positionName`   (spaces trimmed, empty value => field ignored)
   * - `departmentName` (spaces trimmed, empty value => field ignored)
   * - `vendorName`     (spaces trimmed, empty value => field ignored)
   * - `defaultCompanyId` (spaces trimmed, empty value => field ignored, if not empty - must be UUID of the company)
   * - `language`         (spaces trimmed, empty value => field ignored, available values (`en`, `pt`, `de`))
   *
   * - `firstName` (spaces trimmed, empty value => NULL - will erase existing values)
   * - `lastName`  (spaces trimmed, empty value => NULL - will erase existing values)
   * - `title`     (spaces trimmed, empty value => NULL - will erase existing values)
   *
   * - `sendRegistrationEmail` (boolean, empty/unrecognized value => field ignored, availale values:
   *     `'yes', '1', 'on', 'enable', 'enabled', 'true'` and
   *     `'no', '0', 'off', 'disable', 'disabled', 'false'`
   * - `roleCodes` (list of role codes, separated by `,` or by `;`, all whitespaces are ignored for this field, available values:
   *     `ADMINISTRATOR, EMPLOYEE, VENDOR, DPO, PROCESS_OWNER`
   * - `palqeeProcessingResult` (will be ignored during processing, but will be added to the report, with the description of errors)
   */
  upsertBulkPrepareUrl: UserUpsertBulkPrepareUrlPayload
  vendorInfoSet: UserVendorInfoSetPayload
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationCreateArgs = {
  input: UserCreateInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationCreateOrLinkToCompanyArgs = {
  input: UserCreateOrLinkToCompanyInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationDeleteArgs = {
  input: UserDeleteInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationPositionSetArgs = {
  input: UserPositionSetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationRolesLinkArgs = {
  input: UserRolesLinkInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationRolesSetArgs = {
  input: UserRolesSetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationRolesUnlinkArgs = {
  input: UserRolesUnlinkInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationUpsertArgs = {
  input: UserUpsertInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationUpsertBulkPrepareUrlArgs = {
  input: UserUpsertBulkPrepareUrlInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationVendorInfoSetArgs = {
  input: UserVendorInfoSetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceUserQuery = {
  __typename?: 'NamespaceUserQuery'
  get: UserGetPayload
}

/**
 * Namespace: **user**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceUserQueryGetArgs = {
  input: UserGetInput
}

export enum OnErrorBehaviorEnum {
  Ignore = 'IGNORE',
  Throw = 'THROW',
}

export enum OnboardingProgressStepCodeEnum {
  Dpo = 'DPO',
  GeneralInfo = 'GENERAL_INFO',
  ProcessOwners = 'PROCESS_OWNERS',
  Regions = 'REGIONS',
}

export type PaginationArg = {
  /** Number of items in one page */
  limit?: InputMaybe<Scalars['NonNegativeInt']['input']>
  /** Number of ITEMS to skip (not pages) before returning the result */
  offset?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type PaginationInfo = {
  __typename?: 'PaginationInfo'
  limit?: Maybe<Scalars['NonNegativeInt']['output']>
  offset?: Maybe<Scalars['NonNegativeInt']['output']>
  totalItems: Scalars['NonNegativeInt']['output']
  totalPages: Scalars['NonNegativeInt']['output']
}

export type PalqeeModule = {
  __typename?: 'PalqeeModule'
  code: PalqeeModuleCodeEnum
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export enum PalqeeModuleCodeEnum {
  Docbox = 'DOCBOX',
  Dsar = 'DSAR',
  Survey = 'SURVEY',
  Task = 'TASK',
}

export type Person = IUserWithEmail & {
  __typename?: 'Person'
  customFieldValues: Array<PersonCustomFieldValue>
  email: Scalars['EmailAddress']['output']
  firstName: Scalars['String']['output']
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  personId: Scalars['ID']['output']
  phoneNumber?: Maybe<Scalars['SloppyPhoneNumber']['output']>
}

export type PersonCustomFieldValue = {
  __typename?: 'PersonCustomFieldValue'
  customFieldValueId: Scalars['ID']['output']
  formCustomField: ConsentFormCustomField
  formCustomFieldId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  person: Person
  personId: Scalars['ID']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type PingDevInput = {
  payload?: InputMaybe<Scalars['String']['input']>
  touchDb?: InputMaybe<Scalars['Boolean']['input']>
  touchLog?: InputMaybe<Scalars['Boolean']['input']>
}

export type PingDevPayload = {
  __typename?: 'PingDevPayload'
  payload?: Maybe<Scalars['String']['output']>
}

/**
 * Params for checking the translation system
 *
 * `language` is taken from the HTTP-headers or from the user settings (just like
 * for all other requests)
 */
export type PingLangInput = {
  field: Scalars['String']['input']
  uiCode: Scalars['UICode']['input']
}

export type PingLangPayload = {
  __typename?: 'PingLangPayload'
  field: Scalars['String']['output']
  language: Scalars['String']['output']
  /** Translated string (directive) */
  translatedByDirective?: Maybe<Scalars['String']['output']>
  /** Translated string (directly from i18n services) */
  translatedByService?: Maybe<Scalars['String']['output']>
  uiCode: Scalars['UICode']['output']
}

export type Position = {
  __typename?: 'Position'
  company: Company
  companyId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  positionId: Scalars['ID']['output']
  positionTemplate?: Maybe<PositionTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type PositionFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  positionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type PositionTemplate = {
  __typename?: 'PositionTemplate'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  positionTemplateId: Scalars['ID']['output']
  uiCode: Scalars['UICode']['output']
}

export type Process = IDatamapEntity & {
  __typename?: 'Process'
  company: Company
  createdAt?: Maybe<Scalars['DateTime']['output']>
  departments: Array<Department>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  processId: Scalars['ID']['output']
  processTemplate?: Maybe<ProcessTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ProcessTemplate = {
  __typename?: 'ProcessTemplate'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  processTemplateId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type ProcessesGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  processIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ProcessingType = IDatamapEntity & {
  __typename?: 'ProcessingType'
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  processingTypeId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Query = {
  __typename?: 'Query'
  admin: NamespaceAdminQuery
  auth: NamespaceAuthQuery
  company: NamespaceCompanyQuery
  consent: NamespaceConsentQuery
  dashboard: NamespaceDashboardQuery
  docbox: NamespaceDocboxQuery
  /**
   * obsolete
   * @deprecated use docbox
   */
  document: NamespaceDocboxQuery
  dsar: NamespaceDsarQuery
  general: NamespaceGeneralQuery
  /** Does nothing, just pings back, you could use it for a heartbeat-service */
  ping?: Maybe<Scalars['String']['output']>
  /** Does nothing, just pings back _and leave record in logs_, you could use it for debuging _something_. */
  pingDev: PingDevPayload
  /** Does nothing, just checks the LANGUAGE settings and translations (I18N). */
  pingLang: PingLangPayload
  statistics: NamespaceStatisticsQuery
  survey: NamespaceSurveyQuery
  task: NamespaceTaskQuery
  user: NamespaceUserQuery
}

export type QueryPingArgs = {
  payload?: InputMaybe<Scalars['String']['input']>
}

export type QueryPingDevArgs = {
  input?: InputMaybe<PingDevInput>
}

export type QueryPingLangArgs = {
  input?: InputMaybe<PingLangInput>
}

export enum QuestionAnswerValidationMethodCodeEnum {
  Date = 'DATE',
  Datetime = 'DATETIME',
  Email = 'EMAIL',
  Glob = 'GLOB',
  Period = 'PERIOD',
  Phone = 'PHONE',
  Time = 'TIME',
  Uuid = 'UUID',
}

export type QuestionMatrixTemplate = {
  __typename?: 'QuestionMatrixTemplate'
  description?: Maybe<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  questionMatrixTemplateId: Scalars['ID']['output']
  questionTemplates: Array<SurveyQuestionTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type QuestionType = {
  __typename?: 'QuestionType'
  availableModifiers?: Maybe<Scalars['JSONObject']['output']>
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export type QuestionTypeFilterArg = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type Region = IDatamapEntity & {
  __typename?: 'Region'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  regionGroup?: Maybe<RegionGroup>
  regionId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type RegionGroup = {
  __typename?: 'RegionGroup'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  regionGroupId: Scalars['ID']['output']
  regions: Array<Region>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Regulation = {
  __typename?: 'Regulation'
  categories: Array<SurveyCategory>
  code: RegulationCodeEnum
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  logoPath?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  regulationId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export enum RegulationCodeEnum {
  Ccpa = 'CCPA',
  EuAiAct = 'EU_AI_ACT',
  Gdpr = 'GDPR',
  GdprUk = 'GDPR_UK',
  Lgpd = 'LGPD',
  Tdpsa = 'TDPSA',
}

export type Representative = {
  __typename?: 'Representative'
  address?: Maybe<Address>
  company: Company
  email?: Maybe<Scalars['EmailAddress']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['SloppyPhoneNumber']['output']>
  representativeGroup: RepresentativeGroupEnum
  representativeId: Scalars['ID']['output']
}

export type RepresentativeFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  representativeGroups?: InputMaybe<Array<RepresentativeGroupEnum>>
  representativeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum RepresentativeGroupEnum {
  Dpo = 'DPO',
}

export type SelectedRegulation = {
  __typename?: 'SelectedRegulation'
  company: Company
  regulation: Regulation
}

export type StatisticsCompletedSurveysFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsCompletedSurveysGroupByEnum {
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Session = 'SESSION',
  Survey = 'SURVEY',
}

export type StatisticsCompletedSurveysInput = {
  filter?: InputMaybe<StatisticsCompletedSurveysFilterArg>
  groupBy?: InputMaybe<Array<StatisticsCompletedSurveysGroupByEnum>>
}

export type StatisticsCompletedSurveysItem = {
  __typename?: 'StatisticsCompletedSurveysItem'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  completionDurationAverage?: Maybe<Scalars['Duration']['output']>
  department?: Maybe<Department>
  departmentId?: Maybe<Scalars['ID']['output']>
  /** Number of employees COMPLETED the survey / Number of employees invited */
  employeeEngagementRate: Fraction
  isLatest: Scalars['Boolean']['output']
  previous?: Maybe<StatisticsCompletedSurveysItem>
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
}

export type StatisticsCompletedSurveysPayload = {
  __typename?: 'StatisticsCompletedSurveysPayload'
  items: Array<Maybe<StatisticsCompletedSurveysItem>>
  query: Query
}

export type StatisticsConsentsCountByPeriodFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  periodEndAt: Scalars['DateTime']['input']
  periodStartAt: Scalars['DateTime']['input']
  statusCodes?: InputMaybe<Array<ConsentStatusCodeEnum>>
}

export enum StatisticsConsentsCountByPeriodGroupByEnum {
  Company = 'COMPANY',
  Form = 'FORM',
  Status = 'STATUS',
}

export type StatisticsConsentsCountByPeriodInput = {
  filter: StatisticsConsentsCountByPeriodFilterArg
  groupBy?: InputMaybe<Array<StatisticsConsentsCountByPeriodGroupByEnum>>
  /**
   * Describes how the query counts "consents" inside the timeframes.
   *
   * If this parameter is `false`, then `consentsCount` will be counted **within
   * the timeframe**, it means that the output will reflect _"changes in consent
   * count"_
   *
   * If this parameter is `true` then `consentsCount` contains **the total number**
   * of consents in the appropriate group at the certain moment.
   */
  isAccumulated: Scalars['Boolean']['input']
  /**
   * Descibes the timeframe for building timelined-statistics.
   *
   * When this argument is `null` - the whole period
   * `[filter.periodStartAt; filter.periodEndAt)` is considered as the only
   * timeframe.
   *
   * When this argument is not `null` - the provided period
   * `[filter.periodStartAt; filter.periodEndAt)` is broken into "timeframes"
   * of the provided duration and for each "timeframe" there will be
   * a corresponding item in the output
   */
  timeFrame?: InputMaybe<Scalars['Duration']['input']>
}

export type StatisticsConsentsCountByPeriodItem = {
  __typename?: 'StatisticsConsentsCountByPeriodItem'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  consentStatusCode?: Maybe<ConsentStatusCodeEnum>
  consentsCount: Scalars['NonNegativeInt']['output']
  form?: Maybe<ConsentForm>
  formId?: Maybe<Scalars['ID']['output']>
  frameEndAt: Scalars['DateTime']['output']
  frameStartAt: Scalars['DateTime']['output']
}

export type StatisticsConsentsCountByPeriodPayload = {
  __typename?: 'StatisticsConsentsCountByPeriodPayload'
  items: Array<StatisticsConsentsCountByPeriodItem>
  query: Query
}

export type StatisticsDatamapResponsesCountFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  guidedStepIds?: InputMaybe<Array<Scalars['ID']['input']>>
  guidedStepNames?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum StatisticsDatamapResponsesCountGroupByEnum {
  Company = 'COMPANY',
  DatamapEntityTypeCode = 'DATAMAP_ENTITY_TYPE_CODE',
  GuidedStep = 'GUIDED_STEP',
  GuidedStepName = 'GUIDED_STEP_NAME',
  Session = 'SESSION',
  Survey = 'SURVEY',
}

export type StatisticsDatamapResponsesCountInput = {
  filter?: InputMaybe<StatisticsDatamapResponsesCountFilterArg>
  groupBy?: InputMaybe<Array<StatisticsDatamapResponsesCountGroupByEnum>>
}

export type StatisticsDatamapResponsesCountItem = {
  __typename?: 'StatisticsDatamapResponsesCountItem'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  completedCount: Scalars['NonNegativeInt']['output']
  datamapEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  guidedStep?: Maybe<SurveyGuidedStep>
  guidedStepId?: Maybe<Scalars['ID']['output']>
  /**
   * GuidedStep.name
   * **WARNING**: this field is available O N L Y if one requested grouping by
   * GUIDED_STEP_NAME, this field won't be filled if you requested GUIDED_STEP
   */
  guidedStepName?: Maybe<Scalars['String']['output']>
  incompletedCount: Scalars['NonNegativeInt']['output']
  responsesCount: Scalars['NonNegativeInt']['output']
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
}

export type StatisticsDatamapResponsesCountPayload = {
  __typename?: 'StatisticsDatamapResponsesCountPayload'
  guidedSteps: Array<SurveyGuidedStep>
  items: Array<StatisticsDatamapResponsesCountItem>
  query: Query
}

export type StatisticsDsarRequestsAverageFilterArg = {
  channelCodes?: InputMaybe<Array<DsarRequestChannelCodeEnum>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsDsarRequestsAverageGroupByEnum {
  ChannelCode = 'CHANNEL_CODE',
  Company = 'COMPANY',
  Form = 'FORM',
  Tenant = 'TENANT',
}

export type StatisticsDsarRequestsAverageInput = {
  filter?: InputMaybe<StatisticsDsarRequestsAverageFilterArg>
  groupBy?: InputMaybe<Array<StatisticsDsarRequestsAverageGroupByEnum>>
}

export type StatisticsDsarRequestsAverageItem = {
  __typename?: 'StatisticsDsarRequestsAverageItem'
  /** @deprecated use `newRequestsPerMonthAverage` */
  averageNewPerMonth?: Maybe<Scalars['Float']['output']>
  /** @deprecated use `processingDurationAverage` */
  averageProcessingTime?: Maybe<Scalars['Duration']['output']>
  channelCode?: Maybe<DsarRequestChannelCodeEnum>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  form?: Maybe<DsarForm>
  formId?: Maybe<Scalars['ID']['output']>
  /** Average number of new requests per month */
  newRequestsPerMonthAverage?: Maybe<Scalars['Float']['output']>
  /** average processing time per request */
  processingDurationAverage?: Maybe<Scalars['Duration']['output']>
  tenant?: Maybe<Tenant>
  tenantId?: Maybe<Scalars['ID']['output']>
}

export type StatisticsDsarRequestsAveragePayload = {
  __typename?: 'StatisticsDsarRequestsAveragePayload'
  items: Array<StatisticsDsarRequestsAverageItem>
  query: Query
}

export type StatisticsDsarRequestsCountFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestDataSubjectTypeGroupingKeys?: InputMaybe<
    Array<Scalars['String']['input']>
  >
  requestTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statusCodes?: InputMaybe<Array<DsarRequestStatusCodeEnum>>
}

export enum StatisticsDsarRequestsCountGroupByEnum {
  Company = 'COMPANY',
  Form = 'FORM',
  /**
   * By request requestDataSubjectType's `groupingKey` field (this way the
   * statistics will reflect grouping more or less by _data subject types_)
   */
  RequestDataSubjectTypeGroupingKey = 'REQUEST_DATA_SUBJECT_TYPE_GROUPING_KEY',
  RequestType = 'REQUEST_TYPE',
  Status = 'STATUS',
}

export type StatisticsDsarRequestsCountInput = {
  filter?: InputMaybe<StatisticsDsarRequestsCountFilterArg>
  groupBy?: InputMaybe<Array<StatisticsDsarRequestsCountGroupByEnum>>
  order?: InputMaybe<Array<StatisticsDsarRequestsCountOrderArg>>
}

export type StatisticsDsarRequestsCountItem = {
  __typename?: 'StatisticsDsarRequestsCountItem'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  requestDataSubjectTypeGroupingKey?: Maybe<Scalars['String']['output']>
  requestType?: Maybe<DsarRequestType>
  requestTypeId?: Maybe<Scalars['ID']['output']>
  requestsCount: Scalars['NonNegativeInt']['output']
  statusCode?: Maybe<Scalars['String']['output']>
}

export type StatisticsDsarRequestsCountOrderArg = {
  by: StatisticsDsarRequestsCountOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum StatisticsDsarRequestsCountOrderEnum {
  CompanyId = 'companyId',
  FormId = 'formId',
  RequestsCount = 'requestsCount',
  TenantId = 'tenantId',
}

export type StatisticsDsarRequestsCountPayload = {
  __typename?: 'StatisticsDsarRequestsCountPayload'
  items: Array<StatisticsDsarRequestsCountItem>
  query: Query
}

export type StatisticsMaturityScoreAveragesFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsMaturityScoreAveragesGroupByEnum {
  Company = 'COMPANY',
  Session = 'SESSION',
  Survey = 'SURVEY',
  Tenant = 'TENANT',
}

export type StatisticsMaturityScoreAveragesInput = {
  filter?: InputMaybe<StatisticsMaturityScoreAveragesFilterArg>
  groupBy?: InputMaybe<Array<StatisticsMaturityScoreAveragesGroupByEnum>>
}

export type StatisticsMaturityScoreAveragesItem = {
  __typename?: 'StatisticsMaturityScoreAveragesItem'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  /** Average of averages - works good for company */
  maturityScoreAverage?: Maybe<Scalars['Float']['output']>
  /** Sum of averages (avg for survey) */
  maturityScoreAverageSum?: Maybe<Scalars['Float']['output']>
  /** Sum of maturity scores */
  maturityScoreSum?: Maybe<Scalars['NonNegativeInt']['output']>
  questionsCount: Scalars['NonNegativeInt']['output']
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
  surveysCount: Scalars['NonNegativeInt']['output']
  tenant: Tenant
  tenantId: Scalars['ID']['output']
}

export type StatisticsMaturityScoreAveragesPayload = {
  __typename?: 'StatisticsMaturityScoreAveragesPayload'
  items: Array<StatisticsMaturityScoreAveragesItem>
  query: Query
}

export type StatisticsRespondedQuestionsFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsRespondedQuestionsGroupByEnum {
  Audience = 'AUDIENCE',
  Company = 'COMPANY',
  Session = 'SESSION',
  Survey = 'SURVEY',
  User = 'USER',
}

export type StatisticsRespondedQuestionsInput = {
  filter?: InputMaybe<StatisticsRespondedQuestionsFilterArg>
  groupBy?: InputMaybe<Array<StatisticsRespondedQuestionsGroupByEnum>>
}

export type StatisticsRespondedQuestionsItem = {
  __typename?: 'StatisticsRespondedQuestionsItem'
  audience?: Maybe<Audience>
  audienceId?: Maybe<Scalars['ID']['output']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  respondedQuestionsRate: Fraction
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type StatisticsRespondedQuestionsPayload = {
  __typename?: 'StatisticsRespondedQuestionsPayload'
  items: Array<Maybe<StatisticsRespondedQuestionsItem>>
  query: Query
}

export type StatisticsTasksCountFilterArg = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  statusIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsTasksCountGroupByEnum {
  Category = 'CATEGORY',
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Status = 'STATUS',
}

export type StatisticsTasksCountInput = {
  filter?: InputMaybe<StatisticsTasksCountFilterArg>
  groupBy?: InputMaybe<Array<StatisticsTasksCountGroupByEnum>>
}

export type StatisticsTasksCountItem = {
  __typename?: 'StatisticsTasksCountItem'
  category?: Maybe<TaskCategory>
  categoryId?: Maybe<Scalars['ID']['output']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  department?: Maybe<Department>
  departmentId?: Maybe<Scalars['ID']['output']>
  status?: Maybe<TaskStatus>
  statusId?: Maybe<Scalars['ID']['output']>
  tasksCount: Scalars['NonNegativeInt']['output']
}

export type StatisticsTasksCountPayload = {
  __typename?: 'StatisticsTasksCountPayload'
  items: Array<StatisticsTasksCountItem>
  query: Query
}

export type StatisticsWorkforcePercentageFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type StatisticsWorkforcePercentageInput = {
  filter?: InputMaybe<StatisticsWorkforcePercentageFilterArg>
}

export type StatisticsWorkforcePercentageItem = {
  __typename?: 'StatisticsWorkforcePercentageItem'
  company: Company
  percentage: Fraction
}

export type StatisticsWorkforcePercentagePayload = {
  __typename?: 'StatisticsWorkforcePercentagePayload'
  items: Array<StatisticsWorkforcePercentageItem>
  query: Query
}

export type SubscriptionFeatureBoolean = ISubscriptionFeature & {
  __typename?: 'SubscriptionFeatureBoolean'
  available: Scalars['Boolean']['output']
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  subscriptionFeatureId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SubscriptionFeatureIntRange = ISubscriptionFeature & {
  __typename?: 'SubscriptionFeatureIntRange'
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  maxValue?: Maybe<Scalars['Int']['output']>
  minValue?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  subscriptionFeatureId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
  unitOfMeasurementCode: UnitOfMeasurementEnum
}

export type SubscriptionFeatureUsage = {
  __typename?: 'SubscriptionFeatureUsage'
  feature: ISubscriptionFeature
  percentage?: Maybe<Fraction>
  unitOfMeasurementCode?: Maybe<UnitOfMeasurementEnum>
}

export type SubscriptionTier = {
  __typename?: 'SubscriptionTier'
  description?: Maybe<Scalars['String']['output']>
  features?: Maybe<Array<ISubscriptionFeature>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  subscriptionTierId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Survey = {
  __typename?: 'Survey'
  audiences: Array<Audience>
  categories: Array<SurveyCategory>
  company: Company
  companyId: Scalars['ID']['output']
  copiedFromSurvey?: Maybe<Survey>
  copiedFromSurveyId?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  guidedSteps: Array<SurveyGuidedStep>
  id: Scalars['ID']['output']
  isCopy: Scalars['Boolean']['output']
  isDraft: Scalars['Boolean']['output']
  isTemplate: Scalars['Boolean']['output']
  lastUsedAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  questionsCount: Scalars['NonNegativeInt']['output']
  schedules: Array<SurveySchedule>
  sessions: Array<SurveySession>
  subcategories: Array<SurveySubcategory>
  surveyId: Scalars['ID']['output']
  type: SurveyType
}

export type SurveyAnswerOption = IDatamapEntity &
  ISurveyAnswerOption & {
    __typename?: 'SurveyAnswerOption'
    answerOptionId: Scalars['ID']['output']
    answerOptionTemplate?: Maybe<SurveyAnswerOptionTemplate>
    dependents: Array<SurveyDependency>
    id: Scalars['ID']['output']
    /** alias for `value` */
    name?: Maybe<Scalars['String']['output']>
    question: SurveyQuestion
    questionId: Scalars['ID']['output']
    sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
    uiCode?: Maybe<Scalars['UICode']['output']>
    value?: Maybe<Scalars['String']['output']>
  }

export type SurveyAnswerOptionCreateInput = {
  answerOptionTemplateId?: InputMaybe<Scalars['ID']['input']>
  questionId: Scalars['ID']['input']
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyAnswerOptionCreatePayload = {
  __typename?: 'SurveyAnswerOptionCreatePayload'
  answerOption: SurveyAnswerOption
  query: Query
  question: SurveyQuestion
}

export type SurveyAnswerOptionDeleteInput = {
  answerOptionId: Scalars['ID']['input']
}

export type SurveyAnswerOptionDeletePayload = {
  __typename?: 'SurveyAnswerOptionDeletePayload'
  answerOption: SurveyAnswerOption
  query: Query
}

export type SurveyAnswerOptionTemplate = {
  __typename?: 'SurveyAnswerOptionTemplate'
  answerOptionTemplateId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  questionTemplate: SurveyQuestionTemplate
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type SurveyAnswerOptionUpdateInput = {
  answerOptionId: Scalars['ID']['input']
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyAnswerOptionUpdatePayload = {
  __typename?: 'SurveyAnswerOptionUpdatePayload'
  answerOption: SurveyAnswerOption
  query: Query
}

export enum SurveyBuilderQuestionMode {
  Answerable = 'ANSWERABLE',
  AnswerableTable = 'ANSWERABLE_TABLE',
  AnswersSummary = 'ANSWERS_SUMMARY',
  AnswerReadOnly = 'ANSWER_READ_ONLY',
  Editable = 'EDITABLE',
  ReadOnly = 'READ_ONLY',
}

export type SurveyCategoriesGetInput = {
  filter?: InputMaybe<SurveyCategoryFilterArg>
}

export type SurveyCategoriesGetPayload = {
  __typename?: 'SurveyCategoriesGetPayload'
  categories: Array<SurveyCategory>
  query: Query
}

/**
 * **TOP** level grouping for question-templates in Palqee-survey
 *
 * Categories doesn't exist in the resulting survey. It is pure _create-survey_
 * entities.
 *
 * ----
 *
 * Survey Template Hierarchy:
 *
 * - **Category**
 * - Subcategory Template
 * - Group Template
 * - Question Template
 */
export type SurveyCategory = {
  __typename?: 'SurveyCategory'
  categoryId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  regulation?: Maybe<Regulation>
  subcategoryTemplates: Array<SurveySubcategoryTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyCategoryFilterArg = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  regulationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  surveyType?: InputMaybe<SurveyType>
}

export type SurveyCopyInput = {
  companyId: Scalars['ID']['input']
  isDraft: Scalars['Boolean']['input']
  isTemplate: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  surveyId: Scalars['ID']['input']
}

export type SurveyCopyPayload = {
  __typename?: 'SurveyCopyPayload'
  query: Query
  survey: Survey
}

export type SurveyCreateInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId: Scalars['ID']['input']
  isDraft: Scalars['Boolean']['input']
  isTemplate: Scalars['Boolean']['input']
  name: Scalars['String']['input']
}

export type SurveyCreatePayload = {
  __typename?: 'SurveyCreatePayload'
  query: Query
  survey: Survey
}

export enum SurveyDatamapEntityTypeEnum {
  Asset = 'ASSET',
  CustomQuestionProcess = 'CUSTOM_QUESTION_PROCESS',
  DataElement = 'DATA_ELEMENT',
  DataLifecycle = 'DATA_LIFECYCLE',
  DataOwnership = 'DATA_OWNERSHIP',
  DataProcessingRegion = 'DATA_PROCESSING_REGION',
  DataSharing = 'DATA_SHARING',
  DataSource = 'DATA_SOURCE',
  DataSubject = 'DATA_SUBJECT',
  Department = 'DEPARTMENT',
  LegalBasis = 'LEGAL_BASIS',
  Process = 'PROCESS',
  ProcessingType = 'PROCESSING_TYPE',
}

export type SurveyDatamapInitInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  sessionId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyDatamapInitPayload = {
  __typename?: 'SurveyDatamapInitPayload'
  datamap: Datamap
  datamapId: Scalars['ID']['output']
  query: Query
}

export type SurveyDatamapResponsesGetFilterArg = {
  datamapEntityTypeCodes?: InputMaybe<Array<SurveyDatamapEntityTypeEnum>>
  doIncludeDatamapEntity?: Scalars['Boolean']['input']
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * filter the responses by taking only the DM-subtree part
   * below the response with provided ID
   */
  subtreeOfResponseIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyDatamapResponsesGetInput = {
  filter?: InputMaybe<SurveyDatamapResponsesGetFilterArg>
}

export type SurveyDatamapResponsesGetOrderArg = {
  by: SurveyDatamapResponsesGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyDatamapResponsesGetOrderEnum {
  CreatedAt = 'createdAt',
  RespondentId = 'respondentId',
}

export type SurveyDatamapResponsesGetPayload = {
  __typename?: 'SurveyDatamapResponsesGetPayload'
  datamapResponses?: Maybe<Array<IDatamapResponse>>
  datamaps?: Maybe<Array<Maybe<Datamap>>>
  query: Query
}

export type SurveyDatamapsGetFilterArg = {
  /**
   * Use this filter `companyIds` to fetch only the whole company's DataMaps
   * (in contrast to the DataMaps provided by users in their responses)
   */
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Use this filter `userIds` to fetch only DataMaps from users' responses
   * (in contrast to the whole company's DataMap, created by the admin)
   */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyDatamapsGetInput = {
  filter?: InputMaybe<SurveyDatamapsGetFilterArg>
  order?: InputMaybe<Array<SurveyDatamapsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyDatamapsGetOrderArg = {
  by: SurveyDatamapsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyDatamapsGetOrderEnum {
  CompanyId = 'companyId',
  CompanyName = 'companyName',
  CreatedAt = 'createdAt',
  Id = 'id',
  SessionDeadlineAt = 'sessionDeadlineAt',
  SessionId = 'sessionId',
  UserId = 'userId',
}

export type SurveyDatamapsGetPayload = {
  __typename?: 'SurveyDatamapsGetPayload'
  datamaps: Array<Datamap>
  paginationInfo: PaginationInfo
  query: Query
}

export type SurveyDeleteInput = {
  surveyId: Scalars['ID']['input']
}

export type SurveyDeletePayload = {
  __typename?: 'SurveyDeletePayload'
  query: Query
  survey: Survey
}

export type SurveyDependency = {
  __typename?: 'SurveyDependency'
  answerOption: SurveyAnswerOption
  answerOptionId: Scalars['ID']['output']
  dependencyId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  question: SurveyQuestion
  questionId: Scalars['ID']['output']
}

export type SurveyFilterArg = {
  audienceUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  invitationUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isDatamap?: InputMaybe<Scalars['Boolean']['input']>
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  isLastSessionFinished?: InputMaybe<Scalars['Boolean']['input']>
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyGetInput = {
  filter?: InputMaybe<SurveyFilterArg>
  order?: InputMaybe<Array<SurveyGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyGetOrderArg = {
  by: SurveyGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyGetOrderEnum {
  CreatedAt = 'createdAt',
  IsDraft = 'isDraft',
  IsTemplate = 'isTemplate',
  /** it is possible to use this for sorting by `status` (instead of `status`) */
  LastSessionFinishedAt = 'lastSessionFinishedAt',
  Name = 'name',
  QuestionsCount = 'questionsCount',
}

export type SurveyGetPayload = {
  __typename?: 'SurveyGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  surveys: Array<Survey>
}

export type SurveyGuidedStep = {
  __typename?: 'SurveyGuidedStep'
  description?: Maybe<Scalars['String']['output']>
  guidedStepId: Scalars['ID']['output']
  guidedStepTemplate?: Maybe<SurveyGuidedStepTemplate>
  id: Scalars['ID']['output']
  /**
   * Indicates whether the user with this `userId` (by default - currentUser)
   * answered all questions of this `GuidedStep`
   *
   *  - `true` - yes
   *  - `false` - no
   *  - `undefined` - there are 0 questions in this `GuidedStep`
   * @deprecated use `SurveySession.isCompleted` or `Invitation.isCompleted`
   */
  isCompletedByUser?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  questions: Array<SurveyQuestion>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  survey: Survey
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyGuidedStepIsCompletedByUserArgs = {
  input?: InputMaybe<SurveyIsCompletedByUserInput>
}

export type SurveyGuidedStepCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveyGuidedStepCreatePayload = {
  __typename?: 'SurveyGuidedStepCreatePayload'
  guidedStep: SurveyGuidedStep
  query: Query
}

export type SurveyGuidedStepDeleteInput = {
  guidedStepId: Scalars['ID']['input']
}

export type SurveyGuidedStepDeletePayload = {
  __typename?: 'SurveyGuidedStepDeletePayload'
  /** Deleted group. Nested fields **ARE NOT AVAILABLE** */
  guidedStep: SurveyGuidedStep
  query: Query
}

export type SurveyGuidedStepTemplate = {
  __typename?: 'SurveyGuidedStepTemplate'
  description?: Maybe<Scalars['String']['output']>
  guidedStepTemplateId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  /** @deprecated do we need this field at all? */
  isOnlyForManyRegulations?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  questionTemplates: Array<SurveyQuestionTemplate>
  sortOrder: Scalars['NonNegativeInt']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyGuidedStepUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  guidedStepId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type SurveyGuidedStepUpdatePayload = {
  __typename?: 'SurveyGuidedStepUpdatePayload'
  guidedStep: SurveyGuidedStep
  query: Query
}

export type SurveyGuidedStepsGetFilterArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  guidedStepIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyGuidedStepsGetInput = {
  filter?: InputMaybe<SurveyGuidedStepsGetFilterArgs>
  order?: InputMaybe<Array<SurveyGuidedStepsGetOrderArg>>
  /** Set this parameter if you want the output to be grouped by some fields */
  resultGroupBy?: InputMaybe<Array<SurveyGuidedStepsResultGroupByEnum>>
}

export type SurveyGuidedStepsGetOrderArg = {
  by: SurveyGuidedStepsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyGuidedStepsGetOrderEnum {
  Name = 'name',
  SortOrder = 'sortOrder',
  SurveyId = 'surveyId',
  SurveyName = 'surveyName',
}

export type SurveyGuidedStepsGroupItem = {
  __typename?: 'SurveyGuidedStepsGroupItem'
  guidedSteps: Array<SurveyGuidedStep>
  name?: Maybe<Scalars['String']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
}

export type SurveyGuidedStepsPayload = {
  __typename?: 'SurveyGuidedStepsPayload'
  guidedSteps: Array<SurveyGuidedStep>
  /**
   * Contains `GuidedStep`s, gathered in groups
   * This field is NOT empty if you called the method with `resultGroupBy`
   */
  guidedStepsGrouped?: Maybe<Array<SurveyGuidedStepsGroupItem>>
  query: Query
}

export enum SurveyGuidedStepsResultGroupByEnum {
  Name = 'NAME',
}

export type SurveyInvitationDeleteFilterArg = {
  invitationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyInvitationDeleteInput = {
  filter?: InputMaybe<SurveyInvitationDeleteFilterArg>
}

export type SurveyInvitationDeletePayload = {
  __typename?: 'SurveyInvitationDeletePayload'
  invitations: Array<Invitation>
  query: Query
}

export type SurveyInvitationsCreateInput = {
  onDuplicateInvitationBehavior?: InputMaybe<OnErrorBehaviorEnum>
  sessionIds: Array<Scalars['ID']['input']>
  suppressNotifications?: InputMaybe<Scalars['Boolean']['input']>
  users: SurveyInvitationsCreateUsersArg
}

export type SurveyInvitationsCreatePayload = {
  __typename?: 'SurveyInvitationsCreatePayload'
  invitations: Array<Invitation>
  query: Query
}

export type SurveyInvitationsCreateUsersArg = {
  filter: SurveyInvitationsCreateUsersFilterArg
}

export type SurveyInvitationsCreateUsersFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyInvitationsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyInvitationsGetInput = {
  filter?: InputMaybe<SurveyInvitationsGetFilterArg>
  order?: InputMaybe<Array<SurveyInvitationsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<SurveyInvitationsGetSearchArg>
}

export type SurveyInvitationsGetOrderArg = {
  by: SurveyInvitationsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyInvitationsGetOrderEnum {
  CreatedAt = 'createdAt',
  SessionCreatedAt = 'sessionCreatedAt',
  SessionDeadlineAt = 'sessionDeadlineAt',
  SessionFinishedAt = 'sessionFinishedAt',
  UserEmail = 'userEmail',
  UserFirstName = 'userFirstName',
  UserLastName = 'userLastName',
}

export type SurveyInvitationsGetPayload = {
  __typename?: 'SurveyInvitationsGetPayload'
  invitations: Array<Invitation>
  paginationInfo: PaginationInfo
  query: Query
}

export type SurveyInvitationsGetSearchArg = {
  bySameResponse?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyInviteAudiencesInput = {
  audienceIds: Array<Scalars['ID']['input']>
  sessionId: Scalars['ID']['input']
}

export type SurveyInviteAudiencesPayload = {
  __typename?: 'SurveyInviteAudiencesPayload'
  /** Only newly created invitations */
  invitations: Array<Invitation>
  query: Query
  session: SurveySession
}

export type SurveyInviteUsersInput = {
  sessionId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type SurveyInviteUsersPayload = {
  __typename?: 'SurveyInviteUsersPayload'
  /** Only newly created invitations */
  invitations: Array<Invitation>
  query: Query
  session: SurveySession
}

export type SurveyIsCompletedByUserInput = {
  sessionId: Scalars['ID']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyMaturityScoreSetInput = {
  maturityScore?: InputMaybe<Scalars['NonNegativeInt']['input']>
  questionId: Scalars['ID']['input']
  sessionId: Scalars['ID']['input']
}

export type SurveyMaturityScoreSetPayload = {
  __typename?: 'SurveyMaturityScoreSetPayload'
  maturityScore?: Maybe<MaturityScore>
  query: Query
}

export type SurveyMaturityScoresGetFilterArg = {
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyMaturityScoresGetInput = {
  filter?: InputMaybe<SurveyMaturityScoresGetFilterArg>
}

export type SurveyMaturityScoresGetPayload = {
  __typename?: 'SurveyMaturityScoresGetPayload'
  maturityScores: Array<MaturityScore>
  query: Query
}

export type SurveyNotInvitedGetInput = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds: Array<Scalars['ID']['input']>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyNotInvitedGetItem = {
  __typename?: 'SurveyNotInvitedGetItem'
  audienceIds: Array<Scalars['ID']['output']>
  audiences: Array<Audience>
  session: SurveySession
  sessionId: Scalars['ID']['output']
  userIds: Array<Scalars['ID']['output']>
  users: Array<User>
}

export type SurveyNotInvitedGetPayload = {
  __typename?: 'SurveyNotInvitedGetPayload'
  items: Array<SurveyNotInvitedGetItem>
  query: Query
}

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion'
  answerOptions: Array<ISurveyAnswerOption>
  datamapEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  datamapResponses?: Maybe<Array<Maybe<DatamapResponse>>>
  /** The list of dependencies for this question. */
  dependsOn: Array<SurveyDependency>
  description?: Maybe<Scalars['String']['output']>
  displayMode?: Maybe<SurveyBuilderQuestionMode>
  guidedStep?: Maybe<SurveyGuidedStep>
  guidedStepId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isTemplateQuestion?: Maybe<Scalars['Boolean']['output']>
  knowledgeBaseArticle?: Maybe<KnowledgeBaseArticle>
  label: Scalars['String']['output']
  matrix?: Maybe<SurveyQuestionMatrix>
  maturityScore?: Maybe<Scalars['NonNegativeInt']['output']>
  modifiers?: Maybe<Scalars['JSONObject']['output']>
  parentResponseId?: Maybe<Scalars['String']['output']>
  questionId: Scalars['ID']['output']
  questionTemplate?: Maybe<SurveyQuestionTemplate>
  questionTemplateId?: Maybe<Scalars['ID']['output']>
  responses: Array<SurveyResponse>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  subcategory: SurveySubcategory
  subcategoryId: Scalars['ID']['output']
  type: QuestionType
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyQuestionMaturityScoreArgs = {
  sessionId: Scalars['ID']['input']
}

export type SurveyQuestionCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  guidedStepId?: InputMaybe<Scalars['ID']['input']>
  label: Scalars['String']['input']
  modifiers?: InputMaybe<Scalars['JSONObject']['input']>
  questionTypeId: Scalars['ID']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  subcategoryId: Scalars['ID']['input']
}

export type SurveyQuestionCreatePayload = {
  __typename?: 'SurveyQuestionCreatePayload'
  query: Query
  question: SurveyQuestion
  survey: Survey
}

export type SurveyQuestionDeleteInput = {
  questionId: Scalars['ID']['input']
}

export type SurveyQuestionDeletePayload = {
  __typename?: 'SurveyQuestionDeletePayload'
  query: Query
  /** Deleted question. Nested fields **ARE NOT AVAILABLE** */
  question: SurveyQuestion
}

export type SurveyQuestionMatrix = {
  __typename?: 'SurveyQuestionMatrix'
  description?: Maybe<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  questionMatrixId: Scalars['ID']['output']
  questionMatrixTemplate?: Maybe<QuestionMatrixTemplate>
  questions: Array<SurveyQuestion>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyQuestionMatrixCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  header?: InputMaybe<Scalars['String']['input']>
  /**
   * Questions to matrix-ify (join into a matrix-question). Convenient replacent for questionMatrixQuestions.
   *
   * One of `questionIds` or `surveyId` - is required
   */
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** One of `questionIds` or `surveyId` - is required */
  surveyId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyQuestionMatrixCreatePayload = {
  __typename?: 'SurveyQuestionMatrixCreatePayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixDeleteInput = {
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixDeletePayload = {
  __typename?: 'SurveyQuestionMatrixDeletePayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixQuestionsLinkInput = {
  questionIds: Array<Scalars['ID']['input']>
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixQuestionsLinkPayload = {
  __typename?: 'SurveyQuestionMatrixQuestionsLinkPayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixQuestionsUnlinkInput = {
  questionIds: Array<Scalars['ID']['input']>
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixQuestionsUnlinkPayload = {
  __typename?: 'SurveyQuestionMatrixQuestionsUnlinkPayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  header?: InputMaybe<Scalars['String']['input']>
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixUpdatePayload = {
  __typename?: 'SurveyQuestionMatrixUpdatePayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionTemplate = {
  __typename?: 'SurveyQuestionTemplate'
  answerOptionTemplates?: Maybe<Array<SurveyAnswerOptionTemplate>>
  datamapEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  description?: Maybe<Scalars['String']['output']>
  displayMode?: Maybe<SurveyBuilderQuestionMode>
  guidedStepTemplate?: Maybe<SurveyGuidedStepTemplate>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  knowledgeBaseArticle?: Maybe<KnowledgeBaseArticle>
  label: Scalars['String']['output']
  modifiers?: Maybe<Scalars['JSONObject']['output']>
  questionTemplateId: Scalars['ID']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  subcategoryTemplate: SurveySubcategoryTemplate
  type: QuestionType
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyQuestionTypesGetInput = {
  filter?: InputMaybe<QuestionTypeFilterArg>
}

export type SurveyQuestionTypesGetPayload = {
  __typename?: 'SurveyQuestionTypesGetPayload'
  query: Query
  questionTypes: Array<Maybe<QuestionType>>
}

export type SurveyQuestionUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  guidedStepId?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  modifiers?: InputMaybe<Scalars['JSONObject']['input']>
  questionId: Scalars['ID']['input']
  questionTypeId?: InputMaybe<Scalars['ID']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  subcategoryId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyQuestionUpdatePayload = {
  __typename?: 'SurveyQuestionUpdatePayload'
  query: Query
  question: SurveyQuestion
}

export type SurveyQuestionsGetFilterArg = {
  guidedStepIds?: InputMaybe<Array<Scalars['ID']['input']>>
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  subcategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyQuestionsGetInput = {
  filter?: InputMaybe<SurveyQuestionsGetFilterArg>
  order?: InputMaybe<Array<SurveyQuestionsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyQuestionsGetOrderArg = {
  by: SurveyQuestionsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyQuestionsGetOrderEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Label = 'label',
  SortOrder = 'sortOrder',
  SubcategorySortOrder = 'subcategorySortOrder',
}

export type SurveyQuestionsGetPayload = {
  __typename?: 'SurveyQuestionsGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  questions: Array<SurveyQuestion>
}

export type SurveyReminderToInvitedNotRespondedSendInput = {
  sessionIds: Array<Scalars['ID']['input']>
}

export type SurveyReminderToInvitedNotRespondedSendPayload = {
  __typename?: 'SurveyReminderToInvitedNotRespondedSendPayload'
  query: Query
}

export type SurveyResponse = {
  __typename?: 'SurveyResponse'
  answerOption?: Maybe<ISurveyAnswerOption>
  /** @deprecated use `respondedAt` */
  answeredAt: Scalars['DateTime']['output']
  files: Array<BinaryFileInfo>
  id: Scalars['ID']['output']
  question: SurveyQuestion
  questionId: Scalars['ID']['output']
  respondedAt: Scalars['DateTime']['output']
  respondent: User
  respondentId: Scalars['ID']['output']
  responseId: Scalars['ID']['output']
  session: SurveySession
  sessionId: Scalars['ID']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type SurveyResponseAnswerArg = {
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  files?: InputMaybe<Array<Scalars['Upload']['input']>>
  /**
   * Shows if the user's response is sensitive
   * available only for certain Datamap questions
   */
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyResponseCreateInput = {
  /**
   * actual values of the response (you can provide several)
   * TODO: will be NOT NULL :   answers: [SurveyResponseAnswerArg!]!
   */
  answers?: InputMaybe<Array<SurveyResponseAnswerArg>>
  deleteExistingResponses?: Scalars['Boolean']['input']
  /** only for DM questions, identifier of the parent response */
  parentResponseId?: InputMaybe<Scalars['ID']['input']>
  questionId: Scalars['ID']['input']
  sessionId: Scalars['ID']['input']
}

export type SurveyResponseCreatePayload = {
  __typename?: 'SurveyResponseCreatePayload'
  datamapResponses?: Maybe<Array<IDatamapResponse>>
  fileInfos?: Maybe<Array<BinaryFileInfo>>
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponseDeleteInput = {
  filter?: InputMaybe<SurveyResponseFilterArg>
}

export type SurveyResponseDeletePayload = {
  __typename?: 'SurveyResponseDeletePayload'
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponseDocumentRemoveInput = {
  fileInfoIds: Array<Scalars['ID']['input']>
  responseId: Scalars['ID']['input']
}

export type SurveyResponseDocumentRemovePayload = {
  __typename?: 'SurveyResponseDocumentRemovePayload'
  fileInfos: Array<BinaryFileInfo>
  query: Query
  response: SurveyResponse
}

export type SurveyResponseDocumentUploadInput = {
  file: Scalars['Upload']['input']
  responseId: Scalars['ID']['input']
}

export type SurveyResponseDocumentUploadPayload = {
  __typename?: 'SurveyResponseDocumentUploadPayload'
  fileInfo: BinaryFileInfo
  query: Query
  response: SurveyResponse
}

export type SurveyResponseFilterArg = {
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  responseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyResponseInfosItemArg = {
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  files?: InputMaybe<Array<Scalars['Upload']['input']>>
  /**
   * Shows if the user's response is sensitive
   * available only for certain Datamap questions
   */
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>
  /** only for DM questions, identifier of the parent response */
  parentResponseId?: InputMaybe<Scalars['ID']['input']>
  questionId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyResponseUpdateInput = {
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>
  responseId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyResponseUpdatePayload = {
  __typename?: 'SurveyResponseUpdatePayload'
  datamapResponse?: Maybe<IDatamapResponse>
  query: Query
  response: SurveyResponse
}

export type SurveyResponsesChangeRespondentFilterArg = {
  responseIds: Array<Scalars['ID']['input']>
}

export type SurveyResponsesChangeRespondentInput = {
  filter: SurveyResponsesChangeRespondentFilterArg
  options?: InputMaybe<SurveyResponsesChangeRespondentOptionsArg>
  toUserId: Scalars['ID']['input']
}

export type SurveyResponsesChangeRespondentOptionsArg = {
  onDuplicatedResponsesBehavior?: InputMaybe<SurveyResponsesChangeRespondent_OnDuplicatedResponsesBehaviorEnum>
}

export type SurveyResponsesChangeRespondentPayload = {
  __typename?: 'SurveyResponsesChangeRespondentPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
  responseIds: Array<Scalars['ID']['output']>
  responses: Array<SurveyResponse>
}

export enum SurveyResponsesChangeRespondent_OnDuplicatedResponsesBehaviorEnum {
  Overwrite = 'OVERWRITE',
  Throw = 'THROW',
}

export type SurveyResponsesCreateInput = {
  deleteExistingResponses?: Scalars['Boolean']['input']
  responseInfos: Array<SurveyResponseInfosItemArg>
  sessionId: Scalars['ID']['input']
}

export type SurveyResponsesCreatePayload = {
  __typename?: 'SurveyResponsesCreatePayload'
  datamapResponses?: Maybe<Array<IDatamapResponse>>
  fileInfos?: Maybe<Array<BinaryFileInfo>>
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponsesGetInput = {
  filter?: InputMaybe<SurveyResponseFilterArg>
  order?: InputMaybe<Array<SurveyResponsesGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyResponsesGetOrderArg = {
  by: SurveyResponsesGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyResponsesGetOrderEnum {
  AnsweredAt = 'answeredAt',
  RespondentId = 'respondentId',
  SessionId = 'sessionId',
  Value = 'value',
}

export type SurveyResponsesGetPayload = {
  __typename?: 'SurveyResponsesGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponsesImportPrepareUrlInput = {
  /** This field is ignored, we use it just to provide a link to the docs */
  _readme_csv_format?: InputMaybe<GqlDocs_CsvFileUpload>
  language: LanguageCodeEnum
  respondentId?: InputMaybe<Scalars['ID']['input']>
  sessionId: Scalars['ID']['input']
}

export type SurveyResponsesImportPrepareUrlPayload = {
  __typename?: 'SurveyResponsesImportPrepareUrlPayload'
  headers: Array<HttpHeader>
  query: Query
  url: Scalars['URL']['output']
}

export type SurveySchedule = {
  __typename?: 'SurveySchedule'
  defaultSessionDurationDays: Scalars['NonNegativeInt']['output']
  id: Scalars['ID']['output']
  periodDays?: Maybe<Scalars['NonNegativeInt']['output']>
  scheduleId: Scalars['ID']['output']
  startAt: Scalars['DateTime']['output']
  survey: Survey
}

export type SurveyScheduleCreateInput = {
  defaultSessionDurationDays: Scalars['NonNegativeInt']['input']
  /** it is `nullable` just because in future releases we could add `periodMonths` */
  periodDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
  startAt: Scalars['DateTime']['input']
  surveyId: Scalars['ID']['input']
}

export type SurveyScheduleCreatePayload = {
  __typename?: 'SurveyScheduleCreatePayload'
  query: Query
  schedule: SurveySchedule
}

export type SurveyScheduleDeleteInput = {
  scheduleId: Scalars['ID']['input']
}

export type SurveyScheduleDeletePayload = {
  __typename?: 'SurveyScheduleDeletePayload'
  query: Query
  /** Deleted schedule. Nested fields **ARE NOT AVAILABLE** */
  schedule: SurveySchedule
}

export type SurveyScheduleUpdateInput = {
  defaultSessionDurationDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
  periodDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
  scheduleId: Scalars['ID']['input']
  startAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SurveyScheduleUpdatePayload = {
  __typename?: 'SurveyScheduleUpdatePayload'
  query: Query
  schedule: SurveySchedule
}

export type SurveySession = {
  __typename?: 'SurveySession'
  datamaps: Array<Datamap>
  deadlineAt?: Maybe<Scalars['DateTime']['output']>
  /** A status, based on expirations: `finishedAt` and `deadlineAt` */
  expirationStatusCode: SurveySessionExpirationStatusCodeEnum
  finishedAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  invitations: Array<Invitation>
  invitedUsers: Array<User>
  isComplete?: Maybe<Scalars['Boolean']['output']>
  isCompleted: Scalars['Boolean']['output']
  maturityScoreAveragesForSession?: Maybe<StatisticsMaturityScoreAveragesItem>
  responses: Array<SurveyResponse>
  sessionId: Scalars['ID']['output']
  startedAt: Scalars['DateTime']['output']
  /** SessionStatus for the current user */
  statusForUser?: Maybe<SurveySessionPerUserStatusEnum>
  survey: Survey
  surveyId: Scalars['ID']['output']
}

export type SurveySessionDatamapsArgs = {
  input?: InputMaybe<SurveySessionDatamapsInput>
}

export type SurveySessionCreateInput = {
  createInvitationsForAudiences?: InputMaybe<Scalars['Boolean']['input']>
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  finishedAt: Scalars['DateTime']['input']
  startedAt: Scalars['DateTime']['input']
  surveyId: Scalars['ID']['input']
}

export type SurveySessionCreatePayload = {
  __typename?: 'SurveySessionCreatePayload'
  invitations?: Maybe<Array<Invitation>>
  query: Query
  session: SurveySession
  survey: Survey
}

export type SurveySessionDatamapsFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveySessionDatamapsInput = {
  filter?: InputMaybe<SurveySessionDatamapsFilterArg>
}

export type SurveySessionDeleteInput = {
  sessionId: Scalars['ID']['input']
}

export type SurveySessionDeletePayload = {
  __typename?: 'SurveySessionDeletePayload'
  query: Query
  session: SurveySession
  survey: Survey
}

export enum SurveySessionExpirationStatusCodeEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

/** A enum for session status, based on expirations: `finishedAt` and `deadlineAt` */
export enum SurveySessionPerUserStatusEnum {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type SurveySessionUpdateInput = {
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  finishedAt?: InputMaybe<Scalars['DateTime']['input']>
  sessionId: Scalars['ID']['input']
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SurveySessionUpdatePayload = {
  __typename?: 'SurveySessionUpdatePayload'
  query: Query
  session: SurveySession
  survey: Survey
}

export type SurveySessionsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  deadlineAt?: InputMaybe<DateTimeFilterArg>
  finishedAt?: InputMaybe<DateTimeFilterArg>
  invitationUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  onlyLastSessionPerSurvey?: Scalars['Boolean']['input']
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statusesForCurrentUser?: InputMaybe<Array<SurveySessionPerUserStatusEnum>>
  surveyAudienceUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIsDraft?: InputMaybe<Scalars['Boolean']['input']>
  surveyIsTemplate?: InputMaybe<Scalars['Boolean']['input']>
}

export type SurveySessionsGetInput = {
  filter?: InputMaybe<SurveySessionsGetFilterArg>
  order?: InputMaybe<Array<SurveySessionsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<SurveySessionsGetSearchArg>
}

export type SurveySessionsGetOrderArg = {
  by: SurveySessionsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveySessionsGetOrderEnum {
  CreatedAt = 'createdAt',
  DeadlineAt = 'deadlineAt',
  FinishedAt = 'finishedAt',
  SessionId = 'sessionId',
  StartedAt = 'startedAt',
  SurveyCreatedAt = 'surveyCreatedAt',
  SurveyIsDraft = 'surveyIsDraft',
  SurveyIsTemplate = 'surveyIsTemplate',
  SurveyName = 'surveyName',
  SurveyQuestionsCount = 'surveyQuestionsCount',
}

export type SurveySessionsGetPayload = {
  __typename?: 'SurveySessionsGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  sessions: Array<SurveySession>
}

export type SurveySessionsGetSearchArg = {
  /**
   * Searches in:
   *   - session.survey.name
   */
  pattern: Scalars['String']['input']
}

export type SurveySubcategory = {
  __typename?: 'SurveySubcategory'
  description?: Maybe<Scalars['String']['output']>
  /**
   * Guided Steps
   *
   * `null` - when the Subcategory is not 'guided' this filed is null
   * `[ guidedSteps ... ]` - when the Subcategory is `guided`
   */
  guidedSteps?: Maybe<Array<SurveyGuidedStep>>
  header: Scalars['String']['output']
  id: Scalars['ID']['output']
  /**
   * Indicates whether the user with this `userId` (by default - currentUser)
   * answered all questions of this `Subcategory`
   *
   *  - `true` - yes
   *  - `false` - no
   *  - `undefined` - there are 0 questions in this `Subcategory`
   * @deprecated use `SurveySession.isCompleted` or `Invitation.isCompleted`
   */
  isCompletedByUser?: Maybe<Scalars['Boolean']['output']>
  isGuided: Scalars['Boolean']['output']
  questions: Array<SurveyQuestion>
  questionsCount: Scalars['NonNegativeInt']['output']
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  subcategoryId: Scalars['ID']['output']
  subcategoryTemplate?: Maybe<SurveySubcategoryTemplate>
  survey: Survey
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveySubcategoryIsCompletedByUserArgs = {
  input?: InputMaybe<SurveyIsCompletedByUserInput>
}

export type SurveySubcategoryCreateFromTemplateInput = {
  subcategoryTemplateIds: Array<Scalars['ID']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySubcategoryCreateFromTemplatePayload = {
  __typename?: 'SurveySubcategoryCreateFromTemplatePayload'
  query: Query
  subcategories: Array<SurveySubcategory>
  survey: Survey
}

export type SurveySubcategoryCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  header: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySubcategoryCreatePayload = {
  __typename?: 'SurveySubcategoryCreatePayload'
  query: Query
  subcategory: SurveySubcategory
  survey: Survey
}

export type SurveySubcategoryDeleteInput = {
  subcategoryId: Scalars['ID']['input']
}

export type SurveySubcategoryDeletePayload = {
  __typename?: 'SurveySubcategoryDeletePayload'
  query: Query
  subcategory: SurveySubcategory
  survey: Survey
}

export type SurveySubcategoryTemplate = {
  __typename?: 'SurveySubcategoryTemplate'
  category: SurveyCategory
  description?: Maybe<Scalars['String']['output']>
  header: Scalars['String']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  isConfirmed?: Maybe<Scalars['Boolean']['output']>
  isGuided: Scalars['Boolean']['output']
  isSelected?: Maybe<Scalars['Boolean']['output']>
  questionTemplates: Array<SurveyQuestionTemplate>
  questionTemplatesCount: Scalars['NonNegativeInt']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  subcategoryTemplateId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveySubcategoryTemplateUpdateInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  subcategoryTemplateId: Scalars['ID']['input']
}

export type SurveySubcategoryTemplateUpdatePayload = {
  __typename?: 'SurveySubcategoryTemplateUpdatePayload'
  subcategoryTemplate: SurveySubcategoryTemplate
}

export type SurveySubcategoryTemplatesFilterArg = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  subcategoryTemplateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type SurveySubcategoryTemplatesGetInput = {
  filter?: InputMaybe<SurveySubcategoryTemplatesFilterArg>
}

export type SurveySubcategoryTemplatesGetPayload = {
  __typename?: 'SurveySubcategoryTemplatesGetPayload'
  query: Query
  subcategoryTemplates: Array<SurveySubcategoryTemplate>
}

export type SurveySubcategoryUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  header?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  subcategoryId: Scalars['ID']['input']
}

export type SurveySubcategoryUpdatePayload = {
  __typename?: 'SurveySubcategoryUpdatePayload'
  query: Query
  subcategory: SurveySubcategory
}

export type SurveySurveyAudiencesSetInput = {
  audienceIds: Array<Scalars['ID']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySurveyAudiencesSetPayload = {
  __typename?: 'SurveySurveyAudiencesSetPayload'
  query: Query
  survey: Survey
}

export type SurveySurveyConvertIntoTemplateInput = {
  categoryId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySurveyConvertIntoTemplatePayload = {
  __typename?: 'SurveySurveyConvertIntoTemplatePayload'
  query?: Maybe<Query>
  subcategoryTemplate?: Maybe<Array<Maybe<SurveySubcategoryTemplate>>>
}

export enum SurveyType {
  Custom = 'CUSTOM',
  Regulatory = 'REGULATORY',
  Templated = 'TEMPLATED',
}

export type SurveyUpdateInput = {
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveyUpdatePayload = {
  __typename?: 'SurveyUpdatePayload'
  query: Query
  survey: Survey
}

export type SurveyVirtualAnswerOption = ISurveyAnswerOption & {
  __typename?: 'SurveyVirtualAnswerOption'
  id?: Maybe<Scalars['ID']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Task = {
  __typename?: 'Task'
  assigneeAudiences: Array<Audience>
  assigneeUsers: Array<User>
  attachments: Array<TaskAttachment>
  attachmentsCount: Scalars['NonNegativeInt']['output']
  categories: Array<TaskCategory>
  comments: Array<TaskComment>
  commentsCount: Scalars['NonNegativeInt']['output']
  company: Company
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  deadlineAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  linkedDocboxDocuments: Array<DocboxDocument>
  linkedDsarRequests: Array<DsarRequest>
  linkedSurveySessions: Array<SurveySession>
  linkedUrls: Array<TaskLinkedUrl>
  owner?: Maybe<User>
  ownerId?: Maybe<Scalars['ID']['output']>
  priority: TaskPriority
  priorityId: Scalars['ID']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  startAt?: Maybe<Scalars['DateTime']['output']>
  /** Status can be `null` _when and only when the Task is archived_. */
  status?: Maybe<TaskStatus>
  statusId?: Maybe<Scalars['ID']['output']>
  taskId: Scalars['ID']['output']
  title: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /** @deprecated use ownerId and owner fields */
  user: User
  /** @deprecated use ownerId and owner fields */
  userId: Scalars['ID']['output']
}

export type TaskAttachment = {
  __typename?: 'TaskAttachment'
  fileInfo: IBinaryFileInfo
  fileInfoId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isEmbed: Scalars['Boolean']['output']
  task: Task
  taskAttachmentId: Scalars['ID']['output']
  taskId: Scalars['ID']['output']
}

export type TaskAttachmentRemoveInput = {
  fileInfoIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskAttachmentRemovePayload = {
  __typename?: 'TaskAttachmentRemovePayload'
  query: Query
  task: Task
}

export type TaskAttachmentUploadInput = {
  files: Array<Scalars['Upload']['input']>
  taskId?: InputMaybe<Scalars['ID']['input']>
}

export type TaskAttachmentUploadPayload = {
  __typename?: 'TaskAttachmentUploadPayload'
  /**
   * These created if the `taskId` was provided
   *
   * That is why the array is NULLABLE: if there is a task - every uploaded
   * file will be attached to a `Task` and then the array will contain
   * `TaskAttachment`
   */
  attachments?: Maybe<Array<TaskAttachment>>
  fileInfos: Array<IBinaryFileInfo>
  query: Query
  task?: Maybe<Task>
}

export type TaskCategoriesGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskCategoriesGetInput = {
  filter?: InputMaybe<TaskCategoriesGetFilterArg>
}

export type TaskCategoriesGetPayload = {
  __typename?: 'TaskCategoriesGetPayload'
  categories: Array<TaskCategory>
  query: Query
}

export type TaskCategory = {
  __typename?: 'TaskCategory'
  categoryId: Scalars['ID']['output']
  color: Scalars['HexColorCode']['output']
  company: Company
  companyId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type TaskCategoryCreateInput = {
  color: Scalars['HexColorCode']['input']
  companyId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type TaskCategoryCreatePayload = {
  __typename?: 'TaskCategoryCreatePayload'
  category: TaskCategory
  query: Query
}

export type TaskCategoryDeleteFilterArg = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskCategoryDeleteInput = {
  filter: TaskCategoryDeleteFilterArg
}

export type TaskCategoryDeletePayload = {
  __typename?: 'TaskCategoryDeletePayload'
  categories: Array<TaskCategory>
  query: Query
}

export type TaskCategoryLinkInput = {
  categoryIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskCategoryLinkPayload = {
  __typename?: 'TaskCategoryLinkPayload'
  query: Query
  task: Task
}

export type TaskCategoryUnlinkInput = {
  categoryIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskCategoryUnlinkPayload = {
  __typename?: 'TaskCategoryUnlinkPayload'
  query: Query
  task: Task
}

export type TaskCategoryUpdateInput = {
  categoryId: Scalars['ID']['input']
  color?: InputMaybe<Scalars['HexColorCode']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type TaskCategoryUpdatePayload = {
  __typename?: 'TaskCategoryUpdatePayload'
  category: TaskCategory
  query: Query
}

export type TaskComment = {
  __typename?: 'TaskComment'
  attachments: Array<TaskCommentAttachment>
  commentId: Scalars['ID']['output']
  content: Scalars['String']['output']
  id: Scalars['ID']['output']
  task: Task
  taskId: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type TaskCommentAttachment = {
  __typename?: 'TaskCommentAttachment'
  comment: TaskComment
  commentAttachmentId: Scalars['ID']['output']
  commentId: Scalars['ID']['output']
  fileInfo: IBinaryFileInfo
  fileInfoId: Scalars['ID']['output']
  id: Scalars['ID']['output']
}

export type TaskCommentCreateInput = {
  content: Scalars['String']['input']
  fileInfoEmbedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  taskId: Scalars['ID']['input']
}

export type TaskCommentCreatePayload = {
  __typename?: 'TaskCommentCreatePayload'
  comment: TaskComment
  query: Query
}

export type TaskCopyInput = {
  taskId: Scalars['ID']['input']
}

export type TaskCopyPayload = {
  __typename?: 'TaskCopyPayload'
  query: Query
  task: Task
}

export type TaskCreateInput = {
  assigneeAudienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  assigneeUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId: Scalars['ID']['input']
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** For creating "embed" attachments */
  fileInfoEmbedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** For creating "pure" attachments (not "embed") */
  fileInfoIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedDocboxDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedDsarRequestIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedSurveySessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedUrls?: InputMaybe<Array<Scalars['URL']['input']>>
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Priority for the new task.
   * By default "Priority:NONE" will be used
   */
  priorityId?: InputMaybe<Scalars['ID']['input']>
  sortOrder?: Scalars['NonNegativeInt']['input']
  /** When the task should start. */
  startAt?: InputMaybe<Scalars['DateTime']['input']>
  /**
   * Status for the new task.
   * By default NEW status for the company with provided `companyId` will be used.
   */
  statusId?: InputMaybe<Scalars['ID']['input']>
  title: Scalars['String']['input']
}

export type TaskCreatePayload = {
  __typename?: 'TaskCreatePayload'
  query: Query
  task: Task
}

export type TaskDeleteFilterArg = {
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskDeleteInput = {
  filter?: InputMaybe<TaskDeleteFilterArg>
}

export type TaskDeletePayload = {
  __typename?: 'TaskDeletePayload'
  query: Query
  tasks: Array<Task>
}

export type TaskDocboxDocumentLinkInput = {
  documentIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDocboxDocumentLinkPayload = {
  __typename?: 'TaskDocboxDocumentLinkPayload'
  query: Query
  task: Task
}

export type TaskDocboxDocumentUnlinkInput = {
  documentIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDocboxDocumentUnlinkPayload = {
  __typename?: 'TaskDocboxDocumentUnlinkPayload'
  query: Query
  task: Task
}

export type TaskDsarRequestLinkInput = {
  requestIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDsarRequestLinkPayload = {
  __typename?: 'TaskDsarRequestLinkPayload'
  query: Query
  task: Task
}

export type TaskDsarRequestUnlinkInput = {
  requestIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDsarRequestUnlinkPayload = {
  __typename?: 'TaskDsarRequestUnlinkPayload'
  query: Query
  task: Task
}

/**
 * Describes filters for tasks.
 *
 * **Datetime tips**
 *
 * Obviously, there is a need of getting tasks, that were active during certain
 * period of time. We need 2 dates describing the period `[pFrom; pTo)` and for
 * every task we need to decide "what is active". Let's assume that "the period
 * between `task.startAt` and `task.deadlineAt` - it is when the task was active.
 *
 * so we can select these tasks with this `input.filter`:
 * ```
 * {
 * 	startAt: {
 * 		lt: pTo
 * 	}
 * 	deadlineAt: {
 * 		gt: pStart
 * 	}
 * }
 * ```
 * _Note: we use "greater/less than" wihout "or equal" - because we compare extremes
 * of the intervals end-to-start and start-to-end, and since we never include the
 * "end" into the interval - both comparisons must be strict (exclusive)._
 *
 * TODO: write this comment in Simple English
 */
export type TaskGetFilterArg = {
  assigneeAudienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  assigneeUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  deadlineAt?: InputMaybe<DateTimeFilterArg>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  startAt?: InputMaybe<DateTimeFilterArg>
  statusIds?: InputMaybe<Array<Scalars['ID']['input']>>
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskGetInput = {
  filter?: InputMaybe<TaskGetFilterArg>
  order?: InputMaybe<Array<TasksGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<TaskGetSearchArg>
}

export enum TaskGetOrderEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  IsArchived = 'isArchived',
  SortOrder = 'sortOrder',
  StartAt = 'startAt',
  StatusName = 'statusName',
  StatusSortOrder = 'statusSortOrder',
  StatusType = 'statusType',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type TaskGetPayload = {
  __typename?: 'TaskGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  tasks: Array<Task>
}

export type TaskGetSearchArg = {
  pattern: Scalars['String']['input']
}

export enum TaskHistoryEventCodeEnum {
  TaskAssigneeAudienceLinked = 'TASK_ASSIGNEE_AUDIENCE__LINKED',
  TaskAssigneeAudienceUnlinked = 'TASK_ASSIGNEE_AUDIENCE__UNLINKED',
  TaskAssigneeUserLinked = 'TASK_ASSIGNEE_USER__LINKED',
  TaskAssigneeUserUnlinked = 'TASK_ASSIGNEE_USER__UNLINKED',
  TaskAttachmentCreated = 'TASK_ATTACHMENT__CREATED',
  TaskAttachmentDeleted = 'TASK_ATTACHMENT__DELETED',
  TaskCategoryLinked = 'TASK_CATEGORY__LINKED',
  TaskCategoryUnlinked = 'TASK_CATEGORY__UNLINKED',
  TaskCommentCreated = 'TASK_COMMENT__CREATED',
  TaskDeadlineatCreated = 'TASK_DEADLINEAT__CREATED',
  TaskDeadlineatDeleted = 'TASK_DEADLINEAT__DELETED',
  TaskDeadlineatUpdated = 'TASK_DEADLINEAT__UPDATED',
  TaskDescriptionCreated = 'TASK_DESCRIPTION__CREATED',
  TaskDescriptionDeleted = 'TASK_DESCRIPTION__DELETED',
  TaskDescriptionUpdated = 'TASK_DESCRIPTION__UPDATED',
  TaskLinkedUrlCreated = 'TASK_LINKED_URL__CREATED',
  TaskLinkedUrlDeleted = 'TASK_LINKED_URL__DELETED',
  TaskLinkDocboxDocumentLinked = 'TASK_LINK_DOCBOX_DOCUMENT__LINKED',
  TaskLinkDocboxDocumentUnlinked = 'TASK_LINK_DOCBOX_DOCUMENT__UNLINKED',
  TaskLinkDsarRequestLinked = 'TASK_LINK_DSAR_REQUEST__LINKED',
  TaskLinkDsarRequestUnlinked = 'TASK_LINK_DSAR_REQUEST__UNLINKED',
  TaskLinkSurveySessionLinked = 'TASK_LINK_SURVEY_SESSION__LINKED',
  TaskLinkSurveySessionUnlinked = 'TASK_LINK_SURVEY_SESSION__UNLINKED',
  TaskPriorityUpdated = 'TASK_PRIORITY__UPDATED',
  TaskStartatCreated = 'TASK_STARTAT__CREATED',
  TaskStartatDeleted = 'TASK_STARTAT__DELETED',
  TaskStartatUpdated = 'TASK_STARTAT__UPDATED',
  TaskStatusUpdated = 'TASK_STATUS__UPDATED',
  TaskTitleUpdated = 'TASK_TITLE__UPDATED',
  TaskArchived = 'TASK__ARCHIVED',
  TaskCreated = 'TASK__CREATED',
}

export type TaskHistoryItem = {
  __typename?: 'TaskHistoryItem'
  createdAt: Scalars['DateTime']['output']
  eventCode: TaskHistoryEventCodeEnum
  historyItemId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  newInfo?: Maybe<Scalars['JSONObject']['output']>
  oldInfo?: Maybe<Scalars['JSONObject']['output']>
  task: Task
  taskId: Scalars['ID']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type TaskHistoryItemsGetFilterArg = {
  eventCodes?: InputMaybe<Array<TaskHistoryEventCodeEnum>>
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskHistoryItemsGetInput = {
  filter?: InputMaybe<TaskHistoryItemsGetFilterArg>
  order?: InputMaybe<Array<TasksHistoryItemsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export enum TaskHistoryItemsGetOrderEnum {
  CreatedAt = 'createdAt',
  EventCode = 'eventCode',
}

export type TaskHistoryItemsGetPayload = {
  __typename?: 'TaskHistoryItemsGetPayload'
  historyItems: Array<TaskHistoryItem>
  paginationInfo: PaginationInfo
  query: Query
}

export type TaskLinkedUrl = {
  __typename?: 'TaskLinkedUrl'
  id: Scalars['ID']['output']
  taskId: Scalars['ID']['output']
  url: Scalars['URL']['output']
}

export type TaskLinkedUrlCreateInput = {
  taskId: Scalars['ID']['input']
  urls: Array<Scalars['URL']['input']>
}

export type TaskLinkedUrlCreatePayload = {
  __typename?: 'TaskLinkedUrlCreatePayload'
  query: Query
  task: Task
}

export type TaskLinkedUrlDeleteInput = {
  linkedUrlIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskLinkedUrlDeletePayload = {
  __typename?: 'TaskLinkedUrlDeletePayload'
  query: Query
  task: Task
}

export type TaskPrioritiesGetPayload = {
  __typename?: 'TaskPrioritiesGetPayload'
  priorities: Array<TaskPriority>
  query: Query
}

export type TaskPriority = {
  __typename?: 'TaskPriority'
  code: Scalars['String']['output']
  color: Scalars['HexColorCode']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  priorityId: Scalars['ID']['output']
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type TaskStatus = {
  __typename?: 'TaskStatus'
  color: Scalars['HexColorCode']['output']
  company: Company
  companyId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  statusId: Scalars['ID']['output']
  type: TaskStatusTypeEnum
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type TaskStatusCreateInput = {
  color: Scalars['HexColorCode']['input']
  companyId: Scalars['ID']['input']
  name: Scalars['String']['input']
  sortOrder: Scalars['NonNegativeInt']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskStatusCreatePayload = {
  __typename?: 'TaskStatusCreatePayload'
  query: Query
  status: TaskStatus
}

export type TaskStatusDeleteFilterArg = {
  statusIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskStatusDeleteInput = {
  filter?: InputMaybe<TaskStatusDeleteFilterArg>
}

export type TaskStatusDeletePayload = {
  __typename?: 'TaskStatusDeletePayload'
  query: Query
  statuses: Array<TaskStatus>
}

export enum TaskStatusTypeEnum {
  /** Default PRE-defined status for CLOSED tasks */
  Closed = 'CLOSED',
  /**
   * Default PRE-defined status for NEW tasks
   *
   * There is and can be **one and only one** status of this type per company
   */
  New = 'NEW',
  /** Other intermediate statuses */
  Other = 'OTHER',
}

export type TaskStatusUpdateInput = {
  color?: InputMaybe<Scalars['HexColorCode']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  statusId: Scalars['ID']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskStatusUpdatePayload = {
  __typename?: 'TaskStatusUpdatePayload'
  query: Query
  status: TaskStatus
}

export type TaskStatusesGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskStatusesGetInput = {
  filter?: InputMaybe<TaskStatusesGetFilterArg>
}

export type TaskStatusesGetPaylod = {
  __typename?: 'TaskStatusesGetPaylod'
  query: Query
  statuses: Array<TaskStatus>
}

export type TaskStatusesModifyCreateItemArg = {
  color: Scalars['HexColorCode']['input']
  name: Scalars['String']['input']
  sortOrder: Scalars['NonNegativeInt']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskStatusesModifyInput = {
  companyId: Scalars['ID']['input']
  createItems?: InputMaybe<Array<TaskStatusesModifyCreateItemArg>>
  deleteItems?: InputMaybe<Array<Scalars['ID']['input']>>
  updateItems?: InputMaybe<Array<TaskStatusesModifyUpdateItemArg>>
}

export type TaskStatusesModifyPayload = {
  __typename?: 'TaskStatusesModifyPayload'
  query: Query
  statuses: Array<TaskStatus>
}

export type TaskStatusesModifyUpdateItemArg = {
  color?: InputMaybe<Scalars['HexColorCode']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  statusId: Scalars['ID']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskSurveySessionLinkInput = {
  sessionIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskSurveySessionLinkPayload = {
  __typename?: 'TaskSurveySessionLinkPayload'
  query: Query
  task: Task
}

export type TaskSurveySessionUnlinkInput = {
  sessionIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskSurveySessionUnlinkPayload = {
  __typename?: 'TaskSurveySessionUnlinkPayload'
  query: Query
  task: Task
}

export type TaskTaskArchivedSetInput = {
  isArchived: Scalars['Boolean']['input']
  taskIds: Array<Scalars['ID']['input']>
}

export type TaskTaskArchivedSetPayload = {
  __typename?: 'TaskTaskArchivedSetPayload'
  query: Query
  tasks: Array<Task>
}

export type TaskTaskAssigneeAudienceLinkInput = {
  audienceIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskTaskAssigneeAudienceLinkPayload = {
  __typename?: 'TaskTaskAssigneeAudienceLinkPayload'
  query: Query
  task: Task
}

export type TaskTaskAssigneeAudienceUnlinkInput = {
  audienceIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskTaskAssigneeAudienceUnlinkPayload = {
  __typename?: 'TaskTaskAssigneeAudienceUnlinkPayload'
  query: Query
  task: Task
}

export type TaskTaskAssigneeUserLinkInput = {
  taskId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type TaskTaskAssigneeUserLinkPayload = {
  __typename?: 'TaskTaskAssigneeUserLinkPayload'
  query: Query
  task: Task
}

export type TaskTaskAssigneeUserUnlinkInput = {
  taskId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type TaskTaskAssigneeUserUnlinkPayload = {
  __typename?: 'TaskTaskAssigneeUserUnlinkPayload'
  query: Query
  task: Task
}

export type TaskUpdateInput = {
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * Will be used to SET the `isEmbed` attachments for the task (attachments
   * that are used in the description/title of the task).
   *
   * This argument will be used to **override** the existing `isEmbed`
   * attachments: the provided list will be compared with the list of existing
   * attachments, and then - the new ones will be `related`/`linked` with
   * the task, not mentioned - removed.
   */
  fileInfoEmbedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  priorityId?: InputMaybe<Scalars['ID']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  /** When the task should start. */
  startAt?: InputMaybe<Scalars['DateTime']['input']>
  statusId?: InputMaybe<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
}

export type TaskUpdatePayload = {
  __typename?: 'TaskUpdatePayload'
  query: Query
  task: Task
}

export type TasksGetOrderArg = {
  by: TaskGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export type TasksHistoryItemsGetOrderArg = {
  by: TaskHistoryItemsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export type Tenant = {
  __typename?: 'Tenant'
  hasPermanentAccess: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  tenantId: Scalars['ID']['output']
  trialExpiredAt?: Maybe<Scalars['DateTime']['output']>
}

export enum UnitOfMeasurementEnum {
  Gigabyte = 'GIGABYTE',
  Item = 'ITEM',
  Megabyte = 'MEGABYTE',
  Person = 'PERSON',
}

export type User = IUserWithEmail & {
  __typename?: 'User'
  /** the list of audiences the user belongs to */
  audiences: Array<Audience>
  /** companies, related to user (with same `tenantId`) */
  companies: Array<Company>
  defaultCompany?: Maybe<Company>
  /** default company for the user (only for UI) */
  defaultCompanyId?: Maybe<Scalars['ID']['output']>
  email: Scalars['EmailAddress']['output']
  /** can use this to determine if user has ever logged in before */
  firstLogin?: Maybe<Scalars['DateTime']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isAdmin?: Maybe<Scalars['Boolean']['output']>
  isBot: Scalars['Boolean']['output']
  /**
   * Shows if MFA is enabled for the user. It can be null for SSO-signed up users
   * because we don't know if the user has MFA enabled in that Identity Provider
   */
  isMfaEnabled?: Maybe<Scalars['Boolean']['output']>
  isPalqeeManager?: Maybe<Scalars['Boolean']['output']>
  isRegistrationComplete?: Maybe<Scalars['Boolean']['output']>
  isSelected?: Maybe<Scalars['Boolean']['output']>
  language?: Maybe<LanguageCodeEnum>
  lastLogin?: Maybe<Scalars['DateTime']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  passwordLastChangeAt?: Maybe<Scalars['DateTime']['output']>
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>
  positions: Array<UserPosition>
  roles: Array<UserRole>
  status: UserStatusEnum
  tenant: Tenant
  tenantId: Scalars['ID']['output']
  title?: Maybe<Scalars['String']['output']>
  userId: Scalars['ID']['output']
  vendorInfo?: Maybe<VendorInfo>
}

export type UserAudiencesArgs = {
  input?: InputMaybe<UserAudiencesInput>
}

export type UserPositionsArgs = {
  input?: InputMaybe<UserPositionsInput>
}

export type UserRolesArgs = {
  input?: InputMaybe<UserRolesInput>
}

export type UserAudiencesFilterArg = {
  audiencesIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserAudiencesInput = {
  filter?: InputMaybe<UserAudiencesFilterArg>
}

export type UserCreateInput = {
  companyId: Scalars['ID']['input']
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  email: Scalars['EmailAddress']['input']
  firstName: Scalars['String']['input']
  language?: InputMaybe<LanguageCodeEnum>
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /** in order this field to work you have to provide `companyId` parameter */
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserCreateOrLinkToCompanyInput = {
  companyId: Scalars['ID']['input']
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /**
   * in order this field to work (set roles for user) you **MUST**
   * provide the `companyId` parameter
   */
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type UserCreateOrLinkToCompanyPayload = {
  __typename?: 'UserCreateOrLinkToCompanyPayload'
  query: Query
  user: User
}

export type UserCreatePayload = {
  __typename?: 'UserCreatePayload'
  query: Query
  user: User
}

export type UserDeleteExcludeArg = {
  /**
   * By default, even if the current user MATCHES the `input.filter`
   * we **do not delete** it, for safety reasons. In order to delete
   * the current user - one needs to set `exclude.currentUser: false`
   *
   * So, to summarize:
   *  - `true` - current user will be excluded from the list of "users to delete"
   *  - `false` - current user will NOT be excluded (_but anyway: it will be deleted ONLY if it matches `filter` params)
   *
   * And in other words: the current user can be deleted if BOTH are true:
   *  - `exclude.currentUser === false`
   *  - `filter` contains the current user (for example with a wide filter: `include.statuses: [ACTIVE]` - which is very dangerous)
   */
  currentUser?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserDeleteFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  emails?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  statuses?: InputMaybe<Array<UserStatusEnum>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserDeleteInput = {
  /**
   * **TODO: ⚠️ ⚠️ ⚠️ obsolete! use companyDelete(filter.usersAdministratorsHave)**
   *
   * Forces deletion of companies that remained without ADMINISTRATOR after
   * deleting users.
   *
   * We implemented this behavior long time ago, but now it seems extravagant a bit
   */
  deleteCompaniesWithoutAdministrators?: InputMaybe<Scalars['Boolean']['input']>
  exclude?: InputMaybe<UserDeleteExcludeArg>
  filter: UserDeleteFilterArg
  /**
   * Transwer ownership of created resources to the provided user:
   *
   * - DocBox: files and folders (ownership and authorship)
   * - Consent: Forms
   * - DSAR: Forms
   * - Tasks: Tasks (only tasks, no assignee, no comments, only tasks)
   *
   * **By default - ownership is transferred to the current user**
   */
  transferOwnershipsToUserId?: InputMaybe<Scalars['ID']['input']>
}

export type UserDeletePayload = {
  __typename?: 'UserDeletePayload'
  /** Deleted companies - nested fields are not availabe!!! */
  companies: Array<Company>
  query: Query
  /** Deleted users - nested fields are not availabe!!! */
  users: Array<User>
}

export type UserGetExcludeArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserGetFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  emails?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  statuses?: InputMaybe<Array<UserStatusEnum>>
  /**
   * Filter by _"assigned tasks"_:
   *
   * - `true` - takes users that have tasks assigned to them.
   * - `false` - takes users that do not have any task assigned to them.
   *
   * **Note 1**: when `filter.companyIds` is set - the filter considers tasks exclusively from these companies.
   * **Note 2**: deleted tasks are ignored
   * **Note 3**: a task can be assigned to the user **directly** or **through** an audience
   */
  tasksAssignedHave?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Filter by _"tasks owner"_:
   *
   * - `true` - takes users that own tasks.
   * - `false` - takes users that do not own any task.
   *
   * **Note 1**: when `filter.companyIds` is set - the filter considers tasks exclusively from these companies.
   * **Note 2**: deleted tasks are ignored
   */
  tasksOwnerIs?: InputMaybe<Scalars['Boolean']['input']>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserGetInput = {
  exclude?: InputMaybe<UserGetExcludeArg>
  filter?: InputMaybe<UserGetFilterArg>
  order?: InputMaybe<Array<UserGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<UserGetSearchArg>
}

export type UserGetOrderArg = {
  by: UserGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum UserGetOrderEnum {
  CreatedAt = 'createdAt',
  DepartmentName = 'departmentName',
  Email = 'email',
  FirstName = 'firstName',
  Language = 'language',
  LastName = 'lastName',
  PositionName = 'positionName',
  Status = 'status',
}

export type UserGetPayload = {
  __typename?: 'UserGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  users: Array<User>
}

export type UserGetSearchArg = {
  /**
   * **AND-search**
   *
   * **ALL** of these conditions are applied to the user entry, so the user must
   * match patterns from **ALL** specified `conditions`
   */
  conditions?: InputMaybe<Array<UserGetSearchConditionItem>>
  /**
   * **OR-search**
   *
   * A user matches this `pattern` if **ANY** of the fields selected by flags:
   *  - `byEmail`
   *  - `byFirstName`
   *  - `byLastName`
   *  - `byDepartmentName`
   *  - `byPositionName`
   *
   * matches the `pattern`
   */
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type UserGetSearchConditionItem = {
  field: UserGetSearchConditionItemFieldEnum
  pattern: Scalars['String']['input']
}

export enum UserGetSearchConditionItemFieldEnum {
  DepartmentName = 'departmentName',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  PositionName = 'positionName',
  VendorName = 'vendorName',
}

export type UserPosition = {
  __typename?: 'UserPosition'
  department: Department
  departmentId: Scalars['ID']['output']
  position: Position
  positionId: Scalars['ID']['output']
}

export type UserPositionFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserPositionSetInput = {
  departmentId: Scalars['ID']['input']
  positionId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type UserPositionSetPayload = {
  __typename?: 'UserPositionSetPayload'
  query: Query
  user: User
}

export type UserPositionsInput = {
  filter?: InputMaybe<UserPositionFilterArg>
}

/**
 * Security role attached to the user
 * Most fields contains COPIES of this.role
 */
export type UserRole = {
  __typename?: 'UserRole'
  code: AuthRoleCodeEnum
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  role?: Maybe<AuthRole>
  roleId: Scalars['ID']['output']
  uiCode: Scalars['UICode']['output']
  userId: Scalars['ID']['output']
}

export type UserRolesFilterArg = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type UserRolesInput = {
  filter?: InputMaybe<UserRolesFilterArg>
}

export type UserRolesLinkInput = {
  /** The target company you want to link the roles to. */
  companyId: Scalars['ID']['input']
  /**
   * When any user is already linked to the role you are trying to link.
   *
   * Pick the API behavior: THROW or IGNORE.
   * Default behavior is to IGNORE.
   */
  onRoleAlreadyLinkedBehavior?: InputMaybe<OnErrorBehaviorEnum>
  roleCodes: Array<AuthRoleCodeEnum>
  users: UserRolesLinkUsersArg
}

export type UserRolesLinkPayload = {
  __typename?: 'UserRolesLinkPayload'
  query: Query
  users?: Maybe<Array<User>>
}

export type UserRolesLinkUsersArg = {
  exclude?: InputMaybe<UserRolesLinkUsersExcludeArg>
  filter: UserRolesLinkUsersFilterArg
}

export type UserRolesLinkUsersExcludeArg = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserRolesLinkUsersFilterArg = {
  /**
   * Used to 'get all users from company'.
   *
   * This only **FILTER users**.
   *
   * One can provide any company here, but roles still are going to be linked to **{input.companyId}**.
   * That means if you provide company A here and company B in {input.companyId}, no roles will be linked.
   */
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserRolesSetInput = {
  companyId: Scalars['ID']['input']
  roleCodes: Array<AuthRoleCodeEnum>
  userId: Scalars['ID']['input']
}

export type UserRolesSetPayload = {
  __typename?: 'UserRolesSetPayload'
  query: Query
  user: User
}

export type UserRolesUnlinkInput = {
  /**
   * Whether the API should unlink or not roles from the current user.
   *
   * **Warning**: one is able to unlink roles from ALL users within company.
   * If this flag is true, that includes the current user.
   *
   * Default value is false.
   */
  allowRolesUnlinkCurrentUser?: Scalars['Boolean']['input']
  /** The target company you want to link the roles to. */
  companyId: Scalars['ID']['input']
  /**
   * When any provided user is not linked to the role you want to unlink.
   * Example: Users are only linked to EMPLOYEE role and you're trying to unlink VENDOR.
   *
   * Pick the API behavior: THROW or IGNORE.
   *
   * Default behavior is to IGNORE.
   */
  onRoleNotLinkedBehavior?: InputMaybe<OnErrorBehaviorEnum>
  roleCodes: Array<AuthRoleCodeEnum>
  users: UserRolesUnlinkUsersArg
}

export type UserRolesUnlinkPayload = {
  __typename?: 'UserRolesUnlinkPayload'
  query: Query
  users?: Maybe<Array<User>>
}

export type UserRolesUnlinkUsersArg = {
  exclude?: InputMaybe<UserRolesUnlinkUsersExcludeArg>
  filter: UserRolesUnlinkUsersFilterArg
}

export type UserRolesUnlinkUsersExcludeArg = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserRolesUnlinkUsersFilterArg = {
  /**
   * Used to 'get all users from company'.
   *
   * This only **FILTER users**.
   *
   * One can provide any company here, but roles still are going to be unlinked from **{input.companyId}**.
   * That means if you provide company A here and company B in {input.companyId}, no roles will be unlinked.
   */
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum UserStatusEnum {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  Removing = 'REMOVING',
}

/** @todo validate defaults (because it will prevent users from being created later) */
export type UserUpsertBulkPrepareUrlDefaultsArg = {
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  departmentName?: InputMaybe<Scalars['String']['input']>
  /** default language for the new users */
  language?: InputMaybe<LanguageCodeEnum>
  positionName?: InputMaybe<Scalars['String']['input']>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserUpsertBulkPrepareUrlInput = {
  /** This field is ignored, we use it just to provide a link to the docs */
  _readme_csv_format?: InputMaybe<GqlDocs_CsvFileUpload>
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId: Scalars['ID']['input']
  defaults?: InputMaybe<UserUpsertBulkPrepareUrlDefaultsArg>
  /** Whether the bulk should UPSERT or only INSERT users. */
  disableUserUpdate?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserUpsertBulkPrepareUrlPayload = {
  __typename?: 'UserUpsertBulkPrepareUrlPayload'
  headers: Array<HttpHeader>
  query: Query
  url: Scalars['URL']['output']
}

export type UserUpsertInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /**
   * in order this field to work (set roles for user) you **MUST**
   * provide the `companyId` parameter
   */
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type UserUpsertPayload = {
  __typename?: 'UserUpsertPayload'
  query: Query
  user: User
}

export type UserVendorInfoSetInput = {
  name: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type UserVendorInfoSetPayload = {
  __typename?: 'UserVendorInfoSetPayload'
  query: Query
  user: User
  vendorInfo: VendorInfo
}

export type VendorInfo = {
  __typename?: 'VendorInfo'
  name?: Maybe<Scalars['String']['output']>
}

export type CompanyStructureListQueryVariables = Exact<{ [key: string]: never }>

export type CompanyStructureListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    companyStructureList: {
      __typename?: 'GeneralCompanyStructureListPayload'
      companyStructures?: Array<{
        __typename?: 'CompanyStructure'
        id: string
        name: string
      }> | null
    }
  }
}

export type CountryListQueryVariables = Exact<{ [key: string]: never }>

export type CountryListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    countryList: {
      __typename?: 'GeneralCountryListPayload'
      countries: Array<{
        __typename?: 'Country'
        id: string
        name: string
        isoCode: string
      }>
    }
  }
}

export type CompanyTypeListQueryVariables = Exact<{ [key: string]: never }>

export type CompanyTypeListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    companyTypeList: {
      __typename?: 'GeneralCompanyTypeListPayload'
      companyTypes?: Array<{
        __typename?: 'CompanyType'
        id: string
        name?: string | null
      }> | null
    }
  }
}

export type CurrencyListQueryVariables = Exact<{ [key: string]: never }>

export type CurrencyListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    currencyList: {
      __typename?: 'GeneralCurrencyListPayload'
      currencies?: Array<{
        __typename?: 'Currency'
        id: string
        name: string
        isoCode: string
        symbol: string
      }> | null
    }
  }
}

export type AnnualSalesRevenueListQueryVariables = Exact<{
  [key: string]: never
}>

export type AnnualSalesRevenueListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    annualSalesRevenueList: {
      __typename?: 'GeneralAnnualSalesRevenueListPayload'
      annualSalesRevenues?: Array<{
        __typename?: 'AnnualSalesRevenue'
        id: string
        minValue?: number | null
        maxValue?: number | null
        sortOrder: any
      }> | null
    }
  }
}

export type IndustrySectorListQueryQueryVariables = Exact<{
  [key: string]: never
}>

export type IndustrySectorListQueryQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    industryList: {
      __typename?: 'GeneralIndustryListPayload'
      industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
    }
  }
}

export type NewUserFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  positions: Array<{
    __typename?: 'UserPosition'
    positionId: string
    departmentId: string
    position: { __typename?: 'Position'; id: string; name: string }
    department: { __typename?: 'Department'; id: string; name: string }
  }>
}

export type UserFieldsFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  email: any
}

export type DashboardShareWithUserInfoFieldsFragment = {
  __typename?: 'DashboardUserPermission'
  userId: string
  dashboardId: string
  canEdit: boolean
  user: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email: any
  }
}

export type DashboardShareModalUsersGetQueryVariables = Exact<{
  input: DashboardGetInput
}>

export type DashboardShareModalUsersGetQuery = {
  __typename?: 'Query'
  dashboard: {
    __typename?: 'NamespaceDashboardQuery'
    get: {
      __typename?: 'DashboardGetPayload'
      dashboards: Array<{
        __typename?: 'Dashboard'
        id: string
        owner: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          email: any
        }
        sharedWithUsers: Array<{
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          user: {
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            email: any
          }
        }>
        permissionForCurrentUser: {
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          canView: boolean
        }
      }>
    }
  }
}

export type AnalyticsDashboardTaskCategoriesGetQueryVariables = Exact<{
  input: TaskCategoriesGetInput
}>

export type AnalyticsDashboardTaskCategoriesGetQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'NamespaceTaskQuery'
    categoriesGet: {
      __typename?: 'TaskCategoriesGetPayload'
      categories: Array<{
        __typename?: 'TaskCategory'
        id: string
        name: string
      }>
    }
  }
}

export type AnalyticsDashboardTaskStatusesGetQueryVariables = Exact<{
  input: TaskStatusesGetInput
}>

export type AnalyticsDashboardTaskStatusesGetQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'NamespaceTaskQuery'
    statusesGet: {
      __typename?: 'TaskStatusesGetPaylod'
      statuses: Array<{ __typename?: 'TaskStatus'; id: string; name: string }>
    }
  }
}

export type AnalyticsDashboardCompanyDepartmentsGetQueryVariables = Exact<{
  input: CompanyDepartmentsGetInput
}>

export type AnalyticsDashboardCompanyDepartmentsGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    departmentsGet: {
      __typename?: 'CompanyDepartmentsGetPayload'
      departments: Array<{
        __typename?: 'Department'
        id: string
        name: string
      }>
    }
  }
}

export type DashboardFieldsFragment = {
  __typename?: 'Dashboard'
  id: string
  dashboardId: string
  name: string
  metadata: string
  ownerId: string
  permissionForCurrentUser: {
    __typename?: 'DashboardUserPermission'
    userId: string
    dashboardId: string
    canEdit: boolean
    canView: boolean
  }
}

export type DashboardUpsertMutationVariables = Exact<{
  input: DashboardUpsertInput
}>

export type DashboardUpsertMutation = {
  __typename?: 'Mutation'
  dashboard: {
    __typename?: 'NamespaceDashboardMutation'
    upsert: {
      __typename?: 'DashboardUpsertPayload'
      dashboard: {
        __typename?: 'Dashboard'
        id: string
        dashboardId: string
        name: string
        metadata: string
        ownerId: string
        permissionForCurrentUser: {
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          canView: boolean
        }
      }
    }
  }
}

export type DashboardDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DashboardDeleteMutation = {
  __typename?: 'Mutation'
  dashboard: {
    __typename?: 'NamespaceDashboardMutation'
    delete: {
      __typename?: 'DashboardDeletePayload'
      dashboard: {
        __typename?: 'Dashboard'
        id: string
        name: string
        metadata: string
      }
    }
  }
}

export type DashboardUnshareUserMutationVariables = Exact<{
  input: DashboardUnshareInput
}>

export type DashboardUnshareUserMutation = {
  __typename?: 'Mutation'
  dashboard: {
    __typename?: 'NamespaceDashboardMutation'
    unshare: {
      __typename?: 'DashboardUnsharePayload'
      dashboard: {
        __typename?: 'Dashboard'
        id: string
        sharedWithUsers: Array<{
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          user: {
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            email: any
          }
        }>
      }
    }
  }
}

export type DashboardShareWithUserMutationVariables = Exact<{
  input: DashboardShareInput
}>

export type DashboardShareWithUserMutation = {
  __typename?: 'Mutation'
  dashboard: {
    __typename?: 'NamespaceDashboardMutation'
    share: {
      __typename?: 'DashboardSharePayload'
      dashboard: {
        __typename?: 'Dashboard'
        id: string
        sharedWithUsers: Array<{
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          user: {
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            email: any
          }
        }>
      }
    }
  }
}

export type DashboardFavoriteSetMutationVariables = Exact<{
  input: DashboardFavoriteSetInput
}>

export type DashboardFavoriteSetMutation = {
  __typename?: 'Mutation'
  dashboard: {
    __typename?: 'NamespaceDashboardMutation'
    favoriteSet: {
      __typename?: 'DashboardFavoriteSetPayload'
      dashboard?: {
        __typename?: 'Dashboard'
        id: string
        dashboardId: string
        name: string
        metadata: string
        ownerId: string
        permissionForCurrentUser: {
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          canView: boolean
        }
      } | null
    }
  }
}

export type DashboardsGetQueryVariables = Exact<{
  input: DashboardGetInput
}>

export type DashboardsGetQuery = {
  __typename?: 'Query'
  dashboard: {
    __typename?: 'NamespaceDashboardQuery'
    get: {
      __typename?: 'DashboardGetPayload'
      dashboards: Array<{
        __typename?: 'Dashboard'
        id: string
        name: string
        isFavoriteForCurrentUser: boolean
        sharedWithUsers: Array<{
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
        }>
        permissionForCurrentUser: {
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          canView: boolean
        }
      }>
      paginationInfo: {
        __typename?: 'PaginationInfo'
        limit?: any | null
        offset?: any | null
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type DashboardsNameGetQueryVariables = Exact<{
  input: DashboardGetInput
}>

export type DashboardsNameGetQuery = {
  __typename?: 'Query'
  dashboard: {
    __typename?: 'NamespaceDashboardQuery'
    get: {
      __typename?: 'DashboardGetPayload'
      dashboards: Array<{ __typename?: 'Dashboard'; id: string; name: string }>
    }
  }
}

export type DashboardGetQueryVariables = Exact<{
  input: DashboardGetInput
}>

export type DashboardGetQuery = {
  __typename?: 'Query'
  dashboard: {
    __typename?: 'NamespaceDashboardQuery'
    get: {
      __typename?: 'DashboardGetPayload'
      dashboards: Array<{
        __typename?: 'Dashboard'
        id: string
        dashboardId: string
        name: string
        metadata: string
        ownerId: string
        permissionForCurrentUser: {
          __typename?: 'DashboardUserPermission'
          userId: string
          dashboardId: string
          canEdit: boolean
          canView: boolean
        }
      }>
      paginationInfo: {
        __typename?: 'PaginationInfo'
        limit?: any | null
        offset?: any | null
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type GetAudiencesQueryVariables = Exact<{
  input: CompanyAudiencesGetInput
}>

export type GetAudiencesQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    audiencesGet: {
      __typename?: 'CompanyAudiencesGetPayload'
      audiences: Array<{
        __typename?: 'Audience'
        audienceId: string
        name: string
      }>
    }
  }
}

export type SurveysGetQueryVariables = Exact<{
  companyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type SurveysGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    get: {
      __typename?: 'SurveyGetPayload'
      surveys: Array<{ __typename?: 'Survey'; id: string; name: string }>
    }
  }
}

export type TasksCountQueryVariables = Exact<{
  input?: InputMaybe<StatisticsTasksCountInput>
}>

export type TasksCountQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    tasksCount: {
      __typename?: 'StatisticsTasksCountPayload'
      items: Array<{
        __typename?: 'StatisticsTasksCountItem'
        tasksCount: any
        company?: {
          __typename?: 'Company'
          id: string
          name?: string | null
        } | null
        status?: {
          __typename?: 'TaskStatus'
          id: string
          name: string
          color: any
        } | null
        department?: {
          __typename?: 'Department'
          id: string
          name: string
        } | null
        category?: {
          __typename?: 'TaskCategory'
          id: string
          name: string
        } | null
      }>
    }
  }
}

export type ConsentsCountByPeriodQueryVariables = Exact<{
  input?: InputMaybe<StatisticsConsentsCountByPeriodInput>
}>

export type ConsentsCountByPeriodQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    consentsCountByPeriod: {
      __typename?: 'StatisticsConsentsCountByPeriodPayload'
      items: Array<{
        __typename?: 'StatisticsConsentsCountByPeriodItem'
        consentsCount: any
        frameStartAt: any
        frameEndAt: any
        consentStatusCode?: ConsentStatusCodeEnum | null
        company?: {
          __typename?: 'Company'
          id: string
          name?: string | null
        } | null
        form?: { __typename?: 'ConsentForm'; id: string; title: string } | null
      }>
    }
  }
}

export type SubjectRightsRequestsCountQueryVariables = Exact<{
  input?: InputMaybe<StatisticsDsarRequestsCountInput>
}>

export type SubjectRightsRequestsCountQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    dsarRequestsCount: {
      __typename?: 'StatisticsDsarRequestsCountPayload'
      items: Array<{
        __typename?: 'StatisticsDsarRequestsCountItem'
        requestsCount: any
        statusCode?: string | null
        company?: {
          __typename?: 'Company'
          id: string
          name?: string | null
        } | null
        requestType?: {
          __typename?: 'DsarRequestType'
          id: string
          name: string
        } | null
      }>
    }
  }
}

export type DatamapResponsesCountQueryVariables = Exact<{
  input?: InputMaybe<StatisticsDatamapResponsesCountInput>
}>

export type DatamapResponsesCountQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    datamapResponsesCount: {
      __typename?: 'StatisticsDatamapResponsesCountPayload'
      items: Array<{
        __typename?: 'StatisticsDatamapResponsesCountItem'
        responsesCount: any
        datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
        guidedStepName?: string | null
        company?: {
          __typename?: 'Company'
          id: string
          name?: string | null
        } | null
        survey?: { __typename?: 'Survey'; id: string; name: string } | null
        session?: {
          __typename?: 'SurveySession'
          sessionId: string
          expirationStatusCode: SurveySessionExpirationStatusCodeEnum
        } | null
        guidedStep?: {
          __typename?: 'SurveyGuidedStep'
          id: string
          name: string
        } | null
      }>
      guidedSteps: Array<{
        __typename?: 'SurveyGuidedStep'
        id: string
        name: string
      }>
    }
  }
}

export type SurveyDatamapGetQueryVariables = Exact<{
  input?: InputMaybe<SurveyGetInput>
}>

export type SurveyDatamapGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    get: {
      __typename?: 'SurveyGetPayload'
      surveys: Array<{ __typename?: 'Survey'; id: string; name: string }>
    }
  }
}

export type SurveyGuidedStepsGetQueryVariables = Exact<{
  input?: InputMaybe<SurveyGuidedStepsGetInput>
}>

export type SurveyGuidedStepsGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    guidedStepsGet: {
      __typename?: 'SurveyGuidedStepsPayload'
      guidedSteps: Array<{
        __typename?: 'SurveyGuidedStep'
        id: string
        name: string
      }>
      guidedStepsGrouped?: Array<{
        __typename?: 'SurveyGuidedStepsGroupItem'
        name?: string | null
        guidedSteps: Array<{
          __typename?: 'SurveyGuidedStep'
          id: string
          name: string
        }>
      }> | null
    }
  }
}

export type GroupCompaniesGetQueryVariables = Exact<{
  input: CompanyGetInput
}>

export type GroupCompaniesGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: { __typename?: 'Company'; id: string; name?: string | null }
    }
  }
}

export type AudienceUserCreateOrLinkMutationVariables = Exact<{
  input: UserCreateOrLinkToCompanyInput
  companyId: Scalars['ID']['input']
}>

export type AudienceUserCreateOrLinkMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'NamespaceUserMutation'
    createOrLinkToCompany: {
      __typename?: 'UserCreateOrLinkToCompanyPayload'
      user: {
        __typename?: 'User'
        id: string
        isAdmin?: boolean | null
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        isSelected?: boolean | null
        email: any
        status: UserStatusEnum
        defaultCompanyId?: string | null
        passwordLastChangeAt?: any | null
        vendorInfo?: { __typename?: 'VendorInfo'; name?: string | null } | null
        audiences: Array<{
          __typename?: 'Audience'
          id: string
          name: string
          computingMethod: CompanyAudienceComputingMethodEnum
        }>
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          companyId?: string | null
          code: AuthRoleCodeEnum
        }>
        positions: Array<{
          __typename?: 'UserPosition'
          positionId: string
          departmentId: string
          position: { __typename?: 'Position'; id: string; name: string }
          department: { __typename?: 'Department'; id: string; name: string }
        }>
      }
    }
  }
}

export type AudienceUserTableFragment = {
  __typename?: 'User'
  id: string
  isAdmin?: boolean | null
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  isSelected?: boolean | null
  email: any
  status: UserStatusEnum
  defaultCompanyId?: string | null
  passwordLastChangeAt?: any | null
  vendorInfo?: { __typename?: 'VendorInfo'; name?: string | null } | null
  audiences: Array<{
    __typename?: 'Audience'
    id: string
    name: string
    computingMethod: CompanyAudienceComputingMethodEnum
  }>
  roles: Array<{
    __typename?: 'UserRole'
    userId: string
    roleId: string
    name: string
    companyId?: string | null
    code: AuthRoleCodeEnum
  }>
  positions: Array<{
    __typename?: 'UserPosition'
    positionId: string
    departmentId: string
    position: { __typename?: 'Position'; id: string; name: string }
    department: { __typename?: 'Department'; id: string; name: string }
  }>
}

export type AuthenticatedUserFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  phoneNumber?: any | null
  passwordLastChangeAt?: any | null
  isMfaEnabled?: boolean | null
  email: any
  isAdmin?: boolean | null
  isPalqeeManager?: boolean | null
  isRegistrationComplete?: boolean | null
  status: UserStatusEnum
  language?: LanguageCodeEnum | null
  defaultCompanyId?: string | null
  defaultCompany?: {
    __typename?: 'Company'
    id: string
    name?: string | null
    numberOfEmployees: any
    industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
    address?: {
      __typename?: 'Address'
      id: string
      country: { __typename?: 'Country'; id: string; name: string }
    } | null
  } | null
  companies: Array<{
    __typename?: 'Company'
    id: string
    name?: string | null
    logo?: {
      __typename?: 'BinaryFileInfoPublic'
      publicUrl?: string | null
      id: string
      isPublic: boolean
      originalFilename?: string | null
      mimetype: string
      url: string
    } | null
    currentSubscriptionTier?: {
      __typename?: 'SubscriptionTier'
      id: string
      name: string
      features?: Array<
        | {
            __typename?: 'SubscriptionFeatureBoolean'
            available: boolean
            id: string
            name: string
            code: string
            description?: string | null
          }
        | {
            __typename?: 'SubscriptionFeatureIntRange'
            minValue?: number | null
            maxValue?: number | null
            id: string
            name: string
            code: string
            description?: string | null
          }
      > | null
    } | null
  }>
  roles: Array<{
    __typename?: 'UserRole'
    userId: string
    roleId: string
    name: string
    uiCode: any
    companyId?: string | null
    code: AuthRoleCodeEnum
  }>
  tenant: {
    __typename?: 'Tenant'
    hasPermanentAccess: boolean
    trialExpiredAt?: any | null
  }
}

export type AuthenticatedUserWithPositionsFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  phoneNumber?: any | null
  passwordLastChangeAt?: any | null
  isMfaEnabled?: boolean | null
  email: any
  isAdmin?: boolean | null
  isPalqeeManager?: boolean | null
  isRegistrationComplete?: boolean | null
  status: UserStatusEnum
  language?: LanguageCodeEnum | null
  defaultCompanyId?: string | null
  positions: Array<{
    __typename?: 'UserPosition'
    departmentId: string
    positionId: string
    position: {
      __typename?: 'Position'
      id: string
      name: string
      companyId: string
    }
    department: {
      __typename?: 'Department'
      id: string
      name: string
      companyId: string
    }
  }>
  defaultCompany?: {
    __typename?: 'Company'
    id: string
    name?: string | null
    numberOfEmployees: any
    industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
    address?: {
      __typename?: 'Address'
      id: string
      country: { __typename?: 'Country'; id: string; name: string }
    } | null
  } | null
  companies: Array<{
    __typename?: 'Company'
    id: string
    name?: string | null
    logo?: {
      __typename?: 'BinaryFileInfoPublic'
      publicUrl?: string | null
      id: string
      isPublic: boolean
      originalFilename?: string | null
      mimetype: string
      url: string
    } | null
    currentSubscriptionTier?: {
      __typename?: 'SubscriptionTier'
      id: string
      name: string
      features?: Array<
        | {
            __typename?: 'SubscriptionFeatureBoolean'
            available: boolean
            id: string
            name: string
            code: string
            description?: string | null
          }
        | {
            __typename?: 'SubscriptionFeatureIntRange'
            minValue?: number | null
            maxValue?: number | null
            id: string
            name: string
            code: string
            description?: string | null
          }
      > | null
    } | null
  }>
  roles: Array<{
    __typename?: 'UserRole'
    userId: string
    roleId: string
    name: string
    uiCode: any
    companyId?: string | null
    code: AuthRoleCodeEnum
  }>
  tenant: {
    __typename?: 'Tenant'
    hasPermanentAccess: boolean
    trialExpiredAt?: any | null
  }
}

export type AuthCurrentUserQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type AuthCurrentUserQuery = {
  __typename?: 'Query'
  auth: {
    __typename?: 'NamespaceAuthQuery'
    userCurrent: {
      __typename?: 'AuthUserCurrentPayload'
      user?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        phoneNumber?: any | null
        passwordLastChangeAt?: any | null
        isMfaEnabled?: boolean | null
        email: any
        isAdmin?: boolean | null
        isPalqeeManager?: boolean | null
        isRegistrationComplete?: boolean | null
        status: UserStatusEnum
        language?: LanguageCodeEnum | null
        defaultCompanyId?: string | null
        positions: Array<{
          __typename?: 'UserPosition'
          departmentId: string
          positionId: string
          position: {
            __typename?: 'Position'
            id: string
            name: string
            companyId: string
          }
          department: {
            __typename?: 'Department'
            id: string
            name: string
            companyId: string
          }
        }>
        defaultCompany?: {
          __typename?: 'Company'
          id: string
          name?: string | null
          numberOfEmployees: any
          industries: Array<{
            __typename?: 'Industry'
            id: string
            name: string
          }>
          address?: {
            __typename?: 'Address'
            id: string
            country: { __typename?: 'Country'; id: string; name: string }
          } | null
        } | null
        companies: Array<{
          __typename?: 'Company'
          id: string
          name?: string | null
          logo?: {
            __typename?: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          } | null
          currentSubscriptionTier?: {
            __typename?: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename?: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename?: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }>
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          uiCode: any
          companyId?: string | null
          code: AuthRoleCodeEnum
        }>
        tenant: {
          __typename?: 'Tenant'
          hasPermanentAccess: boolean
          trialExpiredAt?: any | null
        }
      } | null
    }
  }
}

export type CompanyGetQueryVariables = Exact<{
  input: CompanyGetInput
}>

export type CompanyGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        logo?: {
          __typename?: 'BinaryFileInfoPublic'
          publicUrl?: string | null
          id: string
          isPublic: boolean
          originalFilename?: string | null
          mimetype: string
          url: string
        } | null
      }
    }
  }
}

export type ConsentFormGetQueryVariables = Exact<{
  input: ConsentFormsGetInput
}>

export type ConsentFormGetQuery = {
  __typename?: 'Query'
  consent: {
    __typename?: 'NamespaceConsentQuery'
    formsGet: {
      __typename?: 'ConsentFormsGetPayload'
      forms: Array<{
        __typename?: 'ConsentForm'
        id: string
        createdAt: any
        lastUpdatedAt: any
        consentExpiresAfterPeriod?: any | null
        allowConsentOnBehalf: boolean
        reference: string
        title: string
        isEditable: boolean
        description?: string | null
        privacyPolicyLink?: string | null
        isDraft: boolean
        draftAccessToken: string
        logo?:
          | { __typename?: 'BinaryFileInfo'; id: string; url: string }
          | { __typename?: 'BinaryFileInfoPublic'; id: string; url: string }
          | null
        customFields: Array<{
          __typename?: 'ConsentFormCustomField'
          id: string
          label: string
          placeholder?: string | null
          isRequired: boolean
          sortOrder: any
          isLocked: boolean
          validationArg?: string | null
        }>
        conditions: Array<{
          __typename?: 'ConsentCondition'
          id: string
          description: string
        }>
      }>
      paginationInfo?: {
        __typename?: 'PaginationInfo'
        offset?: any | null
        limit?: any | null
        totalItems: any
        totalPages: any
      } | null
    }
  }
}

export type ConsentFormCreateMutationVariables = Exact<{
  input: ConsentFormCreateInput
}>

export type ConsentFormCreateMutation = {
  __typename?: 'Mutation'
  consent: {
    __typename?: 'NamespaceConsentMutation'
    formCreate: {
      __typename?: 'ConsentFormCreatePayload'
      form: {
        __typename?: 'ConsentForm'
        id: string
        createdAt: any
        lastUpdatedAt: any
        consentExpiresAfterPeriod?: any | null
        allowConsentOnBehalf: boolean
        reference: string
        title: string
        isEditable: boolean
        description?: string | null
        privacyPolicyLink?: string | null
        isDraft: boolean
        draftAccessToken: string
        logo?:
          | { __typename?: 'BinaryFileInfo'; id: string; url: string }
          | { __typename?: 'BinaryFileInfoPublic'; id: string; url: string }
          | null
        customFields: Array<{
          __typename?: 'ConsentFormCustomField'
          id: string
          label: string
          placeholder?: string | null
          isRequired: boolean
          sortOrder: any
          isLocked: boolean
          validationArg?: string | null
        }>
        conditions: Array<{
          __typename?: 'ConsentCondition'
          id: string
          description: string
        }>
      }
    }
  }
}

export type ConsentFormUpdateMutationVariables = Exact<{
  input: ConsentFormUpdateInput
}>

export type ConsentFormUpdateMutation = {
  __typename?: 'Mutation'
  consent: {
    __typename?: 'NamespaceConsentMutation'
    formUpdate: {
      __typename?: 'ConsentFormUpdatePayload'
      form: {
        __typename?: 'ConsentForm'
        id: string
        createdAt: any
        lastUpdatedAt: any
        consentExpiresAfterPeriod?: any | null
        allowConsentOnBehalf: boolean
        reference: string
        title: string
        isEditable: boolean
        description?: string | null
        privacyPolicyLink?: string | null
        isDraft: boolean
        draftAccessToken: string
        logo?:
          | { __typename?: 'BinaryFileInfo'; id: string; url: string }
          | { __typename?: 'BinaryFileInfoPublic'; id: string; url: string }
          | null
        customFields: Array<{
          __typename?: 'ConsentFormCustomField'
          id: string
          label: string
          placeholder?: string | null
          isRequired: boolean
          sortOrder: any
          isLocked: boolean
          validationArg?: string | null
        }>
        conditions: Array<{
          __typename?: 'ConsentCondition'
          id: string
          description: string
        }>
      }
    }
  }
}

export type ConsentFormCustomFieldUpsertMutationVariables = Exact<{
  input: ConsentFormCustomFieldUpsertInput
}>

export type ConsentFormCustomFieldUpsertMutation = {
  __typename?: 'Mutation'
  consent: {
    __typename?: 'NamespaceConsentMutation'
    formCustomFieldUpsert: {
      __typename?: 'ConsentFormCustomFieldUpsertPayload'
      formCustomField: {
        __typename?: 'ConsentFormCustomField'
        id: string
        form: {
          __typename?: 'ConsentForm'
          id: string
          createdAt: any
          lastUpdatedAt: any
          consentExpiresAfterPeriod?: any | null
          allowConsentOnBehalf: boolean
          reference: string
          title: string
          isEditable: boolean
          description?: string | null
          privacyPolicyLink?: string | null
          isDraft: boolean
          draftAccessToken: string
          logo?:
            | { __typename?: 'BinaryFileInfo'; id: string; url: string }
            | { __typename?: 'BinaryFileInfoPublic'; id: string; url: string }
            | null
          customFields: Array<{
            __typename?: 'ConsentFormCustomField'
            id: string
            label: string
            placeholder?: string | null
            isRequired: boolean
            sortOrder: any
            isLocked: boolean
            validationArg?: string | null
          }>
          conditions: Array<{
            __typename?: 'ConsentCondition'
            id: string
            description: string
          }>
        }
      }
    }
  }
}

export type ConsentFormCustomFieldsDeleteMutationVariables = Exact<{
  input: ConsentFormCustomFieldsDeleteInput
}>

export type ConsentFormCustomFieldsDeleteMutation = {
  __typename?: 'Mutation'
  consent: {
    __typename?: 'NamespaceConsentMutation'
    formCustomFieldsDelete: {
      __typename?: 'ConsentFormCustomFieldsDeletePayload'
      deletedItemsCount: number
    }
  }
}

export type ConsentFormDetailsFragment = {
  __typename?: 'ConsentForm'
  id: string
  createdAt: any
  lastUpdatedAt: any
  consentExpiresAfterPeriod?: any | null
  allowConsentOnBehalf: boolean
  reference: string
  title: string
  isEditable: boolean
  description?: string | null
  privacyPolicyLink?: string | null
  isDraft: boolean
  draftAccessToken: string
  logo?:
    | { __typename?: 'BinaryFileInfo'; id: string; url: string }
    | { __typename?: 'BinaryFileInfoPublic'; id: string; url: string }
    | null
  customFields: Array<{
    __typename?: 'ConsentFormCustomField'
    id: string
    label: string
    placeholder?: string | null
    isRequired: boolean
    sortOrder: any
    isLocked: boolean
    validationArg?: string | null
  }>
  conditions: Array<{
    __typename?: 'ConsentCondition'
    id: string
    description: string
  }>
}

export type ConsentConditionDetailsFragment = {
  __typename?: 'ConsentCondition'
  id: string
  createdAt: any
  updatedAt?: any | null
  description: string
  isArchived: boolean
}

export type CompanyBasicGetQueryVariables = Exact<{
  input: CompanyGetInput
}>

export type CompanyBasicGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        numberOfEmployees: any
        industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
        address?: {
          __typename?: 'Address'
          id: string
          streetAddress?: string | null
          streetAddress2?: string | null
          locality?: string | null
          subAdministrativeArea?: string | null
          administrativeArea?: string | null
          postalCode?: any | null
          country: { __typename?: 'Country'; id: string; name: string }
        } | null
        regions: Array<{ __typename?: 'Region'; id: string; name: string }>
        logo?: {
          __typename?: 'BinaryFileInfoPublic'
          publicUrl?: string | null
          id: string
          isPublic: boolean
          originalFilename?: string | null
          mimetype: string
          url: string
        } | null
        currentSubscriptionTier?: {
          __typename?: 'SubscriptionTier'
          id: string
          name: string
          features?: Array<
            | {
                __typename?: 'SubscriptionFeatureBoolean'
                available: boolean
                id: string
                name: string
                code: string
                description?: string | null
              }
            | {
                __typename?: 'SubscriptionFeatureIntRange'
                minValue?: number | null
                maxValue?: number | null
                id: string
                name: string
                code: string
                description?: string | null
              }
          > | null
        } | null
      }
    }
  }
}

type CompanyBinaryFileInfo_BinaryFileInfo_Fragment = {
  __typename?: 'BinaryFileInfo'
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

type CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment = {
  __typename?: 'BinaryFileInfoPublic'
  publicUrl?: string | null
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

export type CompanyBinaryFileInfoFragment =
  | CompanyBinaryFileInfo_BinaryFileInfo_Fragment
  | CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment

export type CompanyBasicFragment = {
  __typename?: 'Company'
  id: string
  name?: string | null
  numberOfEmployees: any
  industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
  address?: {
    __typename?: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename?: 'Country'; id: string; name: string }
  } | null
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>
  logo?: {
    __typename?: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename?: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename?: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename?: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export type CompanyFullDetailsFragment = {
  __typename?: 'Company'
  numberOfEmployees: any
  id: string
  name?: string | null
  structure: {
    __typename?: 'CompanyStructure'
    id: string
    name: string
    uiCode?: any | null
  }
  address?: {
    __typename?: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename?: 'Country'; id: string; name: string }
  } | null
  industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>
  logo?: {
    __typename?: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename?: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename?: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename?: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export type CompanyMainFragment = {
  __typename?: 'Company'
  id: string
  name?: string | null
  logo?: {
    __typename?: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
}

export type DocumentFieldsFragment = {
  __typename?: 'DocboxDocument'
  id: string
  documentId: string
  folderId: string
  name: string
  description?: string | null
  ownerId: string
  createdAt: any
  createdByUserId: string
  validUntil?: any | null
  createdByUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    roles: Array<{
      __typename?: 'UserRole'
      userId: string
      roleId: string
      name: string
      uiCode: any
      companyId?: string | null
      code: AuthRoleCodeEnum
    }>
  }
  fileInfo?: {
    __typename?: 'BinaryFileInfo'
    id: string
    binaryFileInfoId: string
    originalFilename?: string | null
    mimetype: string
    contentLength?: any | null
    url: string
  } | null
}

export type FileInfoFragment = {
  __typename?: 'BinaryFileInfo'
  id: string
  binaryFileInfoId: string
  originalFilename?: string | null
  mimetype: string
  contentLength?: any | null
  url: string
}

export type UserCreateOrLinkToCompanyMutationVariables = Exact<{
  input: UserCreateOrLinkToCompanyInput
}>

export type UserCreateOrLinkToCompanyMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'NamespaceUserMutation'
    createOrLinkToCompany: {
      __typename?: 'UserCreateOrLinkToCompanyPayload'
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        phoneNumber?: any | null
        passwordLastChangeAt?: any | null
        isMfaEnabled?: boolean | null
        email: any
        isAdmin?: boolean | null
        isPalqeeManager?: boolean | null
        isRegistrationComplete?: boolean | null
        status: UserStatusEnum
        language?: LanguageCodeEnum | null
        defaultCompanyId?: string | null
        defaultCompany?: {
          __typename?: 'Company'
          id: string
          name?: string | null
          numberOfEmployees: any
          industries: Array<{
            __typename?: 'Industry'
            id: string
            name: string
          }>
          address?: {
            __typename?: 'Address'
            id: string
            country: { __typename?: 'Country'; id: string; name: string }
          } | null
        } | null
        companies: Array<{
          __typename?: 'Company'
          id: string
          name?: string | null
          logo?: {
            __typename?: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          } | null
          currentSubscriptionTier?: {
            __typename?: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename?: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename?: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }>
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          uiCode: any
          companyId?: string | null
          code: AuthRoleCodeEnum
        }>
        tenant: {
          __typename?: 'Tenant'
          hasPermanentAccess: boolean
          trialExpiredAt?: any | null
        }
      }
    }
  }
}

export type CompanyNameGetQueryVariables = Exact<{
  input: CompanyGetInput
}>

export type CompanyNameGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: { __typename?: 'Company'; id: string; name?: string | null }
    }
  }
}

export type DocboxGetQueryVariables = Exact<{
  filter?: InputMaybe<DocboxGetFilterArg>
  fileUrlInput?: InputMaybe<BinaryFileInfoUrlInput>
  pagination?: InputMaybe<PaginationArg>
  order?: InputMaybe<Array<DocboxGetOrderArg> | DocboxGetOrderArg>
  search?: InputMaybe<DocboxGetSearchArg>
}>

export type DocboxGetQuery = {
  __typename?: 'Query'
  docbox: {
    __typename?: 'NamespaceDocboxQuery'
    get: {
      __typename?: 'DocboxGetPayload'
      documents?: Array<{
        __typename?: 'DocboxDocument'
        id: string
        documentId: string
        folderId: string
        name: string
        description?: string | null
        ownerId: string
        createdAt: any
        createdByUserId: string
        validUntil?: any | null
        createdByUser: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          roles: Array<{
            __typename?: 'UserRole'
            userId: string
            roleId: string
            name: string
            uiCode: any
            companyId?: string | null
            code: AuthRoleCodeEnum
          }>
        }
        fileInfo?: {
          __typename?: 'BinaryFileInfo'
          id: string
          binaryFileInfoId: string
          originalFilename?: string | null
          mimetype: string
          contentLength?: any | null
          url: string
        } | null
      }> | null
      paginationInfo: {
        __typename?: 'PaginationInfo'
        offset?: any | null
        limit?: any | null
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type DsarFormGetQueryVariables = Exact<{
  input: DsarFormsGetInput
}>

export type DsarFormGetQuery = {
  __typename?: 'Query'
  dsar: {
    __typename?: 'NamespaceDsarQuery'
    formsGet: {
      __typename?: 'DsarFormsGetPayload'
      forms: Array<{
        __typename?: 'DsarForm'
        id: string
        name?: string | null
        draftAccessToken: string
        createdAt: any
        updatedAt?: any | null
        defaultLanguage: LanguageCodeEnum
        availableLanguages: Array<LanguageCodeEnum>
        statusCode: DsarFormStatusCodeEnum
        lastUpdatedAt: any
        customFields: Array<{
          __typename?: 'DsarFormCustomField'
          id: string
          isLocked: boolean
          isRequired: boolean
          sortOrder: any
          validationMethod?: string | null
          validationArg?: string | null
          translations?: Array<{
            __typename?: 'DsarFormCustomFieldTranslation'
            language: LanguageCodeEnum
            label?: string | null
            description?: string | null
            placeholder?: string | null
          }> | null
        }>
        regulation: { __typename?: 'Regulation'; id: string; name: string }
        translations: Array<{
          __typename?: 'DsarFormTranslation'
          id: string
          title?: string | null
          description?: string | null
          language: LanguageCodeEnum
          sortOrder?: any | null
          privacyPolicyLink?: string | null
          privacyCookiesLink?: string | null
        }>
      }>
    }
  }
}

export type DsarFormsGetQueryVariables = Exact<{
  input: DsarFormsGetInput
}>

export type DsarFormsGetQuery = {
  __typename?: 'Query'
  dsar: {
    __typename?: 'NamespaceDsarQuery'
    formsGet: {
      __typename?: 'DsarFormsGetPayload'
      forms: Array<{
        __typename?: 'DsarForm'
        id: string
        name?: string | null
        createdAt: any
        updatedAt?: any | null
        statusCode: DsarFormStatusCodeEnum
        lastUpdatedAt: any
        regulation: { __typename?: 'Regulation'; id: string; name: string }
        translations: Array<{
          __typename?: 'DsarFormTranslation'
          id: string
          language: LanguageCodeEnum
        }>
      }>
      paginationInfo?: {
        __typename?: 'PaginationInfo'
        limit?: any | null
        offset?: any | null
        totalItems: any
        totalPages: any
      } | null
    }
  }
}

export type DsarFormTranslationFieldsFragment = {
  __typename?: 'DsarFormTranslation'
  id: string
  title?: string | null
  language: LanguageCodeEnum
  description?: string | null
  privacyPolicyLink?: string | null
  privacyCookiesLink?: string | null
}

export type DsarFormFieldsFragment = {
  __typename?: 'DsarForm'
  id: string
  name?: string | null
  statusCode: DsarFormStatusCodeEnum
  defaultLanguage: LanguageCodeEnum
}

export type DsarRequestDataSubjectTypesUpsertMutationVariables = Exact<{
  input: DsarRequestDataSubjectTypesUpsertInput
}>

export type DsarRequestDataSubjectTypesUpsertMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    requestDataSubjectTypesUpsert: {
      __typename?: 'DsarRequestDataSubjectTypesUpsertPayload'
      requestDataSubjectTypes: Array<{
        __typename?: 'DsarRequestDataSubjectType'
        id: string
      }>
    }
  }
}

export type DsarFormCreateMutationVariables = Exact<{
  input: DsarFormCreateInput
}>

export type DsarFormCreateMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formCreate: {
      __typename?: 'DsarFormCreatePayload'
      form: {
        __typename?: 'DsarForm'
        id: string
        name?: string | null
        statusCode: DsarFormStatusCodeEnum
        defaultLanguage: LanguageCodeEnum
      }
    }
  }
}

export type DsarFormCustomFieldUpsertMutationVariables = Exact<{
  input: DsarFormCustomFieldUpsertInput
}>

export type DsarFormCustomFieldUpsertMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formCustomFieldUpsert: {
      __typename?: 'DsarFormCustomFieldUpsertPayload'
      formCustomField: {
        __typename?: 'DsarFormCustomField'
        id: string
        sortOrder: any
        isRequired: boolean
        isLocked: boolean
      }
    }
  }
}

export type DsarFormUpdateMutationVariables = Exact<{
  input: DsarFormUpdateInput
}>

export type DsarFormUpdateMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formUpdate: {
      __typename?: 'DsarFormUpdatePayload'
      form: {
        __typename?: 'DsarForm'
        id: string
        name?: string | null
        statusCode: DsarFormStatusCodeEnum
        defaultLanguage: LanguageCodeEnum
      }
    }
  }
}

export type DsarFormTranslationUpdateMutationVariables = Exact<{
  input: DsarFormTranslationUpdateInput
}>

export type DsarFormTranslationUpdateMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formTranslationUpdate: {
      __typename?: 'DsarFormTranslationUpdatePayload'
      formTranslation: {
        __typename?: 'DsarFormTranslation'
        id: string
        title?: string | null
        language: LanguageCodeEnum
        description?: string | null
        privacyPolicyLink?: string | null
        privacyCookiesLink?: string | null
      }
    }
  }
}

export type DsarFormTranslationCreateMutationVariables = Exact<{
  input: DsarFormTranslationCreateInput
}>

export type DsarFormTranslationCreateMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formTranslationCreate: {
      __typename?: 'DsarFormTranslationCreatePayload'
      formTranslation: {
        __typename?: 'DsarFormTranslation'
        id: string
        title?: string | null
        language: LanguageCodeEnum
        description?: string | null
        privacyPolicyLink?: string | null
        privacyCookiesLink?: string | null
      }
    }
  }
}

export type DsarFormTranslationDeleteMutationVariables = Exact<{
  input: DsarFormTranslationDeleteInput
}>

export type DsarFormTranslationDeleteMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formTranslationDelete: {
      __typename?: 'DsarFormTranslationDeletePayload'
      formTranslations: Array<{
        __typename?: 'DsarFormTranslation'
        id: string
        title?: string | null
        language: LanguageCodeEnum
        description?: string | null
        privacyPolicyLink?: string | null
        privacyCookiesLink?: string | null
      }>
    }
  }
}

export type DsarFormDeleteMutationVariables = Exact<{
  input: DsarFormDeleteInput
}>

export type DsarFormDeleteMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formDelete: {
      __typename?: 'DsarFormDeletePayload'
      form: {
        __typename?: 'DsarForm'
        id: string
        name?: string | null
        statusCode: DsarFormStatusCodeEnum
        defaultLanguage: LanguageCodeEnum
      }
    }
  }
}

export type DsarRequestDataSubjectTypeTranslationsDeleteMutationVariables =
  Exact<{
    input: DsarRequestDataSubjectTypeTranslationsDeleteInput
  }>

export type DsarRequestDataSubjectTypeTranslationsDeleteMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    requestDataSubjectTypeTranslationsDelete: {
      __typename?: 'DsarRequestDataSubjectTypeTranslationsDeletePayload'
      query: { __typename?: 'Query'; ping?: string | null }
    }
  }
}

export type DsarFormCustomFieldsDeleteMutationVariables = Exact<{
  input: DsarFormCustomFieldsDeleteInput
}>

export type DsarFormCustomFieldsDeleteMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    formCustomFieldsDelete: {
      __typename?: 'DsarFormCustomFieldsDeletePayload'
      deletedItemsCount: number
    }
  }
}

export type DsarRequestDataSubjectTypeTranslationsGetQueryVariables = Exact<{
  input: DsarRequestDataSubjectTypeTranslationsGetInput
}>

export type DsarRequestDataSubjectTypeTranslationsGetQuery = {
  __typename?: 'Query'
  dsar: {
    __typename?: 'NamespaceDsarQuery'
    requestDataSubjectTypeTranslationsGet: {
      __typename?: 'DsarRequestDataSubjectTypeTranslationsGetPayload'
      requestDataSubjectTypeTranslations: Array<{
        __typename?: 'DsarRequestDataSubjectTypeTranslation'
        id: string
        requestDataSubjectTypeId: string
        language: LanguageCodeEnum
        name: string
        sortOrder?: any | null
      }>
    }
  }
}

export type DsarRequestCreateMutationVariables = Exact<{
  input: DsarRequestCreateInput
}>

export type DsarRequestCreateMutation = {
  __typename?: 'Mutation'
  dsar: {
    __typename?: 'NamespaceDsarMutation'
    requestCreate: {
      __typename?: 'DsarRequestCreatePayload'
      request: {
        __typename?: 'DsarRequest'
        id: string
        companyId: string
        email: any
        phoneNumber?: any | null
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        statusCode: DsarRequestStatusCodeEnum
        isIdVerified: boolean
        description?: string | null
        expiredAtEffective?: any | null
        form?: {
          __typename?: 'DsarForm'
          id: string
          regulation: { __typename?: 'Regulation'; id: string; name: string }
        } | null
        requestDataSubjectTypeTranslation: {
          __typename?: 'DsarRequestDataSubjectTypeTranslation'
          id: string
          name: string
        }
        country: { __typename?: 'Country'; id: string; name: string }
        type: {
          __typename?: 'DsarRequestType'
          id: string
          name: string
          regulation: { __typename?: 'Regulation'; id: string; name: string }
        }
        customFieldValues: Array<{
          __typename?: 'DsarRequestCustomFieldValue'
          id: string
          value: string
          formCustomField: {
            __typename?: 'DsarFormCustomField'
            id: string
            label: string
            translations?: Array<{
              __typename?: 'DsarFormCustomFieldTranslation'
              language: LanguageCodeEnum
              label?: string | null
            }> | null
          }
        }>
      }
    }
  }
}

export type DsarRequestChannelsGetQueryVariables = Exact<{
  [key: string]: never
}>

export type DsarRequestChannelsGetQuery = {
  __typename?: 'Query'
  dsar: {
    __typename?: 'NamespaceDsarQuery'
    requestChannelsGet: {
      __typename?: 'DsarRequestChannelsGetPayload'
      requestChannels: Array<{
        __typename?: 'DsarRequestChannel'
        code: DsarRequestChannelCodeEnum
        name: string
      }>
    }
  }
}

export type CompanyAddressRepGetQueryVariables = Exact<{
  input: CompanyGetInput
}>

export type CompanyAddressRepGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        address?: {
          __typename?: 'Address'
          id: string
          streetAddress?: string | null
          streetAddress2?: string | null
          locality?: string | null
          administrativeArea?: string | null
          subAdministrativeArea?: string | null
          postalCode?: any | null
          country: { __typename?: 'Country'; id: string; name: string }
        } | null
        representatives: Array<{
          __typename?: 'Representative'
          id: string
          firstName?: string | null
          lastName?: string | null
          fullName?: string | null
          email?: any | null
          phoneNumber?: any | null
        }>
      }
    }
  }
}

export type DsarPeriodSelectorRequestQueryVariables = Exact<{
  input: DsarRequestsGetInput
}>

export type DsarPeriodSelectorRequestQuery = {
  __typename?: 'Query'
  dsar: {
    __typename?: 'NamespaceDsarQuery'
    requestsGet: {
      __typename?: 'DsarRequestsGetPayload'
      requests: Array<{
        __typename?: 'DsarRequest'
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        email: any
        phoneNumber?: any | null
        requestedAt: any
        updatedAt?: any | null
        expiredAtEffective?: any | null
        isIdVerified: boolean
        statusCode: DsarRequestStatusCodeEnum
        country: { __typename?: 'Country'; id: string; name: string }
        type: {
          __typename?: 'DsarRequestType'
          id: string
          name: string
          regulation: { __typename?: 'Regulation'; id: string; name: string }
        }
        requestDataSubjectTypeTranslation: {
          __typename?: 'DsarRequestDataSubjectTypeTranslation'
          id: string
          language: LanguageCodeEnum
          name: string
        }
        customFieldValues: Array<{
          __typename?: 'DsarRequestCustomFieldValue'
          id: string
          value: string
          formCustomField: {
            __typename?: 'DsarFormCustomField'
            id: string
            translations?: Array<{
              __typename?: 'DsarFormCustomFieldTranslation'
              label?: string | null
              language: LanguageCodeEnum
            }> | null
          }
        }>
      }>
    }
  }
}

export type DsarRequestGetQueryVariables = Exact<{
  input: DsarRequestsGetInput
}>

export type DsarRequestGetQuery = {
  __typename?: 'Query'
  dsar: {
    __typename?: 'NamespaceDsarQuery'
    requestsGet: {
      __typename?: 'DsarRequestsGetPayload'
      requests: Array<{
        __typename?: 'DsarRequest'
        id: string
        companyId: string
        email: any
        phoneNumber?: any | null
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        statusCode: DsarRequestStatusCodeEnum
        isIdVerified: boolean
        description?: string | null
        expiredAtEffective?: any | null
        form?: {
          __typename?: 'DsarForm'
          id: string
          regulation: { __typename?: 'Regulation'; id: string; name: string }
        } | null
        requestDataSubjectTypeTranslation: {
          __typename?: 'DsarRequestDataSubjectTypeTranslation'
          id: string
          name: string
        }
        country: { __typename?: 'Country'; id: string; name: string }
        type: {
          __typename?: 'DsarRequestType'
          id: string
          name: string
          regulation: { __typename?: 'Regulation'; id: string; name: string }
        }
        customFieldValues: Array<{
          __typename?: 'DsarRequestCustomFieldValue'
          id: string
          value: string
          formCustomField: {
            __typename?: 'DsarFormCustomField'
            id: string
            label: string
            translations?: Array<{
              __typename?: 'DsarFormCustomFieldTranslation'
              language: LanguageCodeEnum
              label?: string | null
            }> | null
          }
        }>
      }>
      paginationInfo?: {
        __typename?: 'PaginationInfo'
        offset?: any | null
        limit?: any | null
        totalItems: any
        totalPages: any
      } | null
    }
  }
}

export type DsarRequestsGetQueryVariables = Exact<{
  input: DsarRequestsGetInput
}>

export type DsarRequestsGetQuery = {
  __typename?: 'Query'
  dsar: {
    __typename?: 'NamespaceDsarQuery'
    requestsGet: {
      __typename?: 'DsarRequestsGetPayload'
      requests: Array<{
        __typename?: 'DsarRequest'
        id: string
        companyId: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        statusCode: DsarRequestStatusCodeEnum
        isIdVerified: boolean
        expiredAtEffective?: any | null
        requestedAt: any
        form?: {
          __typename?: 'DsarForm'
          id: string
          regulation: { __typename?: 'Regulation'; id: string; name: string }
        } | null
        requestDataSubjectTypeTranslation: {
          __typename?: 'DsarRequestDataSubjectTypeTranslation'
          id: string
          name: string
          language: LanguageCodeEnum
        }
        country: { __typename?: 'Country'; id: string; name: string }
        channel?: {
          __typename?: 'DsarRequestChannel'
          code: DsarRequestChannelCodeEnum
          name: string
        } | null
        type: { __typename?: 'DsarRequestType'; id: string; name: string }
      }>
      paginationInfo?: {
        __typename?: 'PaginationInfo'
        offset?: any | null
        limit?: any | null
        totalItems: any
        totalPages: any
      } | null
    }
  }
}

export type DsarRequestFieldsFragment = {
  __typename?: 'DsarRequest'
  id: string
  companyId: string
  email: any
  phoneNumber?: any | null
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  statusCode: DsarRequestStatusCodeEnum
  isIdVerified: boolean
  description?: string | null
  expiredAtEffective?: any | null
  form?: {
    __typename?: 'DsarForm'
    id: string
    regulation: { __typename?: 'Regulation'; id: string; name: string }
  } | null
  requestDataSubjectTypeTranslation: {
    __typename?: 'DsarRequestDataSubjectTypeTranslation'
    id: string
    name: string
  }
  country: { __typename?: 'Country'; id: string; name: string }
  type: {
    __typename?: 'DsarRequestType'
    id: string
    name: string
    regulation: { __typename?: 'Regulation'; id: string; name: string }
  }
  customFieldValues: Array<{
    __typename?: 'DsarRequestCustomFieldValue'
    id: string
    value: string
    formCustomField: {
      __typename?: 'DsarFormCustomField'
      id: string
      label: string
      translations?: Array<{
        __typename?: 'DsarFormCustomFieldTranslation'
        language: LanguageCodeEnum
        label?: string | null
      }> | null
    }
  }>
}

export type DsarRequestsFieldsFragment = {
  __typename?: 'DsarRequest'
  id: string
  companyId: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  statusCode: DsarRequestStatusCodeEnum
  isIdVerified: boolean
  expiredAtEffective?: any | null
  requestedAt: any
  form?: {
    __typename?: 'DsarForm'
    id: string
    regulation: { __typename?: 'Regulation'; id: string; name: string }
  } | null
  requestDataSubjectTypeTranslation: {
    __typename?: 'DsarRequestDataSubjectTypeTranslation'
    id: string
    name: string
    language: LanguageCodeEnum
  }
  country: { __typename?: 'Country'; id: string; name: string }
  channel?: {
    __typename?: 'DsarRequestChannel'
    code: DsarRequestChannelCodeEnum
    name: string
  } | null
  type: { __typename?: 'DsarRequestType'; id: string; name: string }
}

export type AuthApiKeyCreateMutationVariables = Exact<{
  input: AuthApiKeyCreateInput
}>

export type AuthApiKeyCreateMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    apiKeyCreate: {
      __typename?: 'AuthApiKeyCreatePayload'
      apiKeyString: string
    }
  }
}

export type AuthApiKeyDeleteMutationVariables = Exact<{
  input: AuthApiKeyDeleteInput
}>

export type AuthApiKeyDeleteMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    apiKeyDelete: { __typename?: 'AuthApiKeyDeletePayload'; apiKeyId: string }
  }
}

export type AuthApiKeysGetQueryVariables = Exact<{
  input: AuthApiKeysGetInput
}>

export type AuthApiKeysGetQuery = {
  __typename?: 'Query'
  auth: {
    __typename?: 'NamespaceAuthQuery'
    apiKeysGet: {
      __typename?: 'AuthApiKeysGetPayload'
      apiKeys: Array<{
        __typename?: 'AuthApiKey'
        id: string
        name: string
        apiKeyMasked: string
        createdAt: any
      }>
    }
  }
}

export type AuthApiKeyTableFragment = {
  __typename?: 'AuthApiKey'
  id: string
  name: string
  apiKeyMasked: string
  createdAt: any
}

export type SurveyResponsesChangeRespondentMutationVariables = Exact<{
  input: SurveyResponsesChangeRespondentInput
}>

export type SurveyResponsesChangeRespondentMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    responsesChangeRespondent: {
      __typename?: 'SurveyResponsesChangeRespondentPayload'
      query: { __typename?: 'Query'; ping?: string | null }
    }
  }
}

export type SurveyResponsesChangeRespondentInvitationsQueryVariables = Exact<{
  input: SurveyInvitationsGetInput
}>

export type SurveyResponsesChangeRespondentInvitationsQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    invitationsGet: {
      __typename?: 'SurveyInvitationsGetPayload'
      invitations: Array<{ __typename?: 'Invitation'; userId: string }>
    }
  }
}

export type TasksFilterByCategoriesQueryVariables = Exact<{
  input: TaskCategoriesGetInput
}>

export type TasksFilterByCategoriesQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'NamespaceTaskQuery'
    categoriesGet: {
      __typename?: 'TaskCategoriesGetPayload'
      categories: Array<{
        __typename?: 'TaskCategory'
        id: string
        name: string
      }>
    }
  }
}

export type TasksFilterByStatusesQueryVariables = Exact<{
  input: TaskStatusesGetInput
}>

export type TasksFilterByStatusesQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'NamespaceTaskQuery'
    statusesGet: {
      __typename?: 'TaskStatusesGetPaylod'
      statuses: Array<{
        __typename?: 'TaskStatus'
        id: string
        name: string
        sortOrder: any
      }>
    }
  }
}

export type TasksFilterByOwnersQueryVariables = Exact<{
  input: UserGetInput
}>

export type TasksFilterByOwnersQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'NamespaceUserQuery'
    get: {
      __typename?: 'UserGetPayload'
      users: Array<{
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: any
        fullName?: string | null
      }>
    }
  }
}

export type TasksFilterByAssigneesQueryVariables = Exact<{
  companyAudiencesGetInput: CompanyAudiencesGetInput
  userGetInput: UserGetInput
}>

export type TasksFilterByAssigneesQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'NamespaceUserQuery'
    get: {
      __typename?: 'UserGetPayload'
      users: Array<{
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: any
        fullName?: string | null
      }>
    }
  }
  company: {
    __typename?: 'NamespaceCompanyQuery'
    audiencesGet: {
      __typename?: 'CompanyAudiencesGetPayload'
      audiences: Array<{ __typename?: 'Audience'; id: string; name: string }>
    }
  }
}

export type TasksExportGetQueryVariables = Exact<{
  input: TaskGetInput
}>

export type TasksExportGetQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'NamespaceTaskQuery'
    get: {
      __typename?: 'TaskGetPayload'
      tasks: Array<{
        __typename?: 'Task'
        id: string
        title: string
        createdAt: any
        startAt?: any | null
        deadlineAt?: any | null
        description?: string | null
        isArchived: boolean
        assigneeAudiences: Array<{
          __typename?: 'Audience'
          id: string
          name: string
        }>
        assigneeUsers: Array<{
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          fullName?: string | null
        }>
        attachments: Array<{
          __typename?: 'TaskAttachment'
          id: string
          fileInfo:
            | {
                __typename?: 'BinaryFileInfo'
                id: string
                originalFilename?: string | null
                mimetype: string
              }
            | {
                __typename?: 'BinaryFileInfoPublic'
                id: string
                originalFilename?: string | null
                mimetype: string
              }
        }>
        owner?: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          fullName?: string | null
        } | null
        status?: {
          __typename?: 'TaskStatus'
          id: string
          name: string
          type: TaskStatusTypeEnum
        } | null
        categories: Array<{
          __typename?: 'TaskCategory'
          id: string
          name: string
        }>
        linkedDocboxDocuments: Array<{
          __typename?: 'DocboxDocument'
          id: string
          name: string
          fileInfo?: {
            __typename?: 'BinaryFileInfo'
            id: string
            mimetype: string
          } | null
        }>
        linkedDsarRequests: Array<{
          __typename?: 'DsarRequest'
          id: string
          firstName?: string | null
          lastName?: string | null
        }>
        linkedSurveySessions: Array<{
          __typename?: 'SurveySession'
          id: string
          survey: { __typename?: 'Survey'; id: string; name: string }
        }>
        linkedUrls: Array<{
          __typename?: 'TaskLinkedUrl'
          id: string
          url: any
        }>
      }>
    }
  }
}

export type TasksExportHistoryItemCommentsGetQueryVariables = Exact<{
  input: TaskHistoryItemsGetInput
}>

export type TasksExportHistoryItemCommentsGetQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'NamespaceTaskQuery'
    historyItemsGet: {
      __typename?: 'TaskHistoryItemsGetPayload'
      historyItems: Array<{
        __typename?: 'TaskHistoryItem'
        id: string
        createdAt: any
        newInfo?: any | null
        taskId: string
      }>
    }
  }
}

export type UserSearchQueryVariables = Exact<{
  input: UserGetInput
}>

export type UserSearchQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'NamespaceUserQuery'
    get: {
      __typename?: 'UserGetPayload'
      users: Array<{
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        email: any
      }>
      paginationInfo: {
        __typename?: 'PaginationInfo'
        offset?: any | null
        limit?: any | null
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type UserCompaniesGetQueryVariables = Exact<{
  input: UserGetInput
}>

export type UserCompaniesGetQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'NamespaceUserQuery'
    get: {
      __typename?: 'UserGetPayload'
      users: Array<{
        __typename?: 'User'
        id: string
        companies: Array<{ __typename?: 'Company'; id: string }>
      }>
    }
  }
}

export type SurveyGetViewAnswersQueryVariables = Exact<{
  filter?: InputMaybe<SurveyFilterArg>
  fileUrlInput?: InputMaybe<BinaryFileInfoUrlInput>
  sessionId: Scalars['ID']['input']
}>

export type SurveyGetViewAnswersQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    get: {
      __typename?: 'SurveyGetPayload'
      surveys: Array<{
        __typename?: 'Survey'
        id: string
        name: string
        type: SurveyType
        audiences: Array<{
          __typename?: 'Audience'
          id: string
          name: string
          computingMethod: CompanyAudienceComputingMethodEnum
          isSingleUser: boolean
          users: Array<{
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            email: any
            positions: Array<{
              __typename?: 'UserPosition'
              position: {
                __typename?: 'Position'
                id: string
                name: string
                companyId: string
              }
              department: {
                __typename?: 'Department'
                id: string
                name: string
                companyId: string
              }
            }>
          }>
        }>
        company: { __typename?: 'Company'; id: string }
        categories: Array<{
          __typename?: 'SurveyCategory'
          id: string
          name?: string | null
          regulation?: {
            __typename?: 'Regulation'
            id: string
            name: string
            uiCode?: any | null
          } | null
        }>
        subcategories: Array<{
          __typename?: 'SurveySubcategory'
          id: string
          header: string
          description?: string | null
          sortOrder?: any | null
          isGuided: boolean
          subcategoryTemplate?: {
            __typename?: 'SurveySubcategoryTemplate'
            id: string
            category: {
              __typename?: 'SurveyCategory'
              id: string
              name?: string | null
              regulation?: {
                __typename?: 'Regulation'
                id: string
                name: string
                uiCode?: any | null
              } | null
            }
          } | null
          questions: Array<{
            __typename?: 'SurveyQuestion'
            id: string
            label: string
            description?: string | null
            maturityScore?: any | null
            modifiers?: any | null
            subcategory: { __typename?: 'SurveySubcategory'; id: string }
            answerOptions: Array<
              | {
                  __typename?: 'DatamapAnswerOption'
                  id: string
                  value?: string | null
                }
              | {
                  __typename?: 'DatamapCustomAnswerOption'
                  id?: string | null
                  value?: string | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  value?: string | null
                }
              | {
                  __typename?: 'SurveyVirtualAnswerOption'
                  id?: string | null
                  value?: string | null
                }
            >
            responses: Array<{
              __typename?: 'SurveyResponse'
              id: string
              answeredAt: any
              files: Array<{
                __typename?: 'BinaryFileInfo'
                id: string
                url: string
                originalFilename?: string | null
              }>
              respondent: {
                __typename?: 'User'
                id: string
                firstName?: string | null
                lastName?: string | null
                email: any
                positions: Array<{
                  __typename?: 'UserPosition'
                  department: {
                    __typename?: 'Department'
                    id: string
                    name: string
                    companyId: string
                  }
                  position: {
                    __typename?: 'Position'
                    id: string
                    name: string
                    companyId: string
                  }
                }>
              }
            }>
            type: {
              __typename?: 'QuestionType'
              id: string
              uiCode: any
              name: string
            }
          }>
        }>
        sessions: Array<{
          __typename?: 'SurveySession'
          id: string
          startedAt: any
          finishedAt: any
          invitedUsers: Array<{
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            email: any
            positions: Array<{
              __typename?: 'UserPosition'
              position: {
                __typename?: 'Position'
                id: string
                name: string
                companyId: string
              }
              department: {
                __typename?: 'Department'
                id: string
                name: string
                companyId: string
              }
            }>
          }>
        }>
      }>
    }
  }
}

export type AuthCurrentUserAccountQueryVariables = Exact<{
  [key: string]: never
}>

export type AuthCurrentUserAccountQuery = {
  __typename?: 'Query'
  auth: {
    __typename?: 'NamespaceAuthQuery'
    userCurrent: {
      __typename?: 'AuthUserCurrentPayload'
      user?: {
        __typename?: 'User'
        id: string
        phoneNumber?: any | null
        passwordLastChangeAt?: any | null
        isMfaEnabled?: boolean | null
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          code: AuthRoleCodeEnum
          companyId?: string | null
        }>
        companies: Array<{
          __typename?: 'Company'
          numberOfEmployees: any
          id: string
          name?: string | null
          structure: {
            __typename?: 'CompanyStructure'
            id: string
            name: string
            uiCode?: any | null
          }
          address?: {
            __typename?: 'Address'
            id: string
            streetAddress?: string | null
            streetAddress2?: string | null
            locality?: string | null
            subAdministrativeArea?: string | null
            administrativeArea?: string | null
            postalCode?: any | null
            country: { __typename?: 'Country'; id: string; name: string }
          } | null
          industries: Array<{
            __typename?: 'Industry'
            id: string
            name: string
          }>
          regions: Array<{ __typename?: 'Region'; id: string; name: string }>
          logo?: {
            __typename?: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          } | null
          currentSubscriptionTier?: {
            __typename?: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename?: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename?: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }>
      } | null
    }
  }
}

export type CompanyCompanyGetComanyAccountQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyCompanyGetComanyAccountQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        regions: Array<{ __typename?: 'Region'; id: string; name: string }>
      }
    }
  }
}

export type CompanyCompanyGetAuthLoginQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyCompanyGetAuthLoginQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        logo?: {
          __typename?: 'BinaryFileInfoPublic'
          publicUrl?: string | null
          id: string
          isPublic: boolean
          originalFilename?: string | null
          mimetype: string
          url: string
        } | null
      }
    }
  }
}

export type CompanyCompanyGetCompanyOnboardingQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyCompanyGetCompanyOnboardingQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        numberOfEmployees: any
        currentSubscriptionTier?: {
          __typename?: 'SubscriptionTier'
          id: string
          name: string
          features?: Array<
            | {
                __typename?: 'SubscriptionFeatureBoolean'
                available: boolean
                id: string
                name: string
                code: string
                description?: string | null
              }
            | {
                __typename?: 'SubscriptionFeatureIntRange'
                minValue?: number | null
                maxValue?: number | null
                id: string
                name: string
                code: string
                description?: string | null
              }
          > | null
        } | null
        type: { __typename?: 'CompanyType'; id: string; name?: string | null }
        logo?: {
          __typename?: 'BinaryFileInfoPublic'
          publicUrl?: string | null
          id: string
          isPublic: boolean
          originalFilename?: string | null
          mimetype: string
          url: string
        } | null
        structure: { __typename?: 'CompanyStructure'; id: string; name: string }
        industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
        regions: Array<{ __typename?: 'Region'; id: string; name: string }>
        annualSalesRevenue: {
          __typename?: 'AnnualSalesRevenue'
          id: string
          minValue?: number | null
          maxValue?: number | null
        }
        currency?: {
          __typename?: 'Currency'
          id: string
          isoCode: string
        } | null
        address?: {
          __typename?: 'Address'
          id: string
          streetAddress?: string | null
          streetAddress2?: string | null
          locality?: string | null
          subAdministrativeArea?: string | null
          administrativeArea?: string | null
          postalCode?: any | null
          country: { __typename?: 'Country'; id: string; name: string }
        } | null
      }
    }
  }
}

export type UserGetCompanyOnboardingQueryVariables = Exact<{
  input: UserGetInput
}>

export type UserGetCompanyOnboardingQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'NamespaceUserQuery'
    get: {
      __typename?: 'UserGetPayload'
      users: Array<{
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: any
        positions: Array<{
          __typename?: 'UserPosition'
          position: {
            __typename?: 'Position'
            id: string
            name: string
            companyId: string
          }
          department: {
            __typename?: 'Department'
            id: string
            name: string
            companyId: string
          }
        }>
      }>
    }
  }
}

export const NewUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'positions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'positionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'position' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewUserFragment, unknown>
export const UserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>
export const DashboardShareWithUserInfoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardShareWithUserInfoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardUserPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardShareWithUserInfoFieldsFragment, unknown>
export const DashboardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Dashboard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissionForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardFieldsFragment, unknown>
export const AudienceUserTableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AudienceUserTable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAdmin' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSelected' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCompanyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passwordLastChangeAt' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vendorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'audiences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'computingMethod' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'positions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'positionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'position' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AudienceUserTableFragment, unknown>
export const CompanyBinaryFileInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyBinaryFileInfoFragment, unknown>
export const AuthenticatedUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticatedUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passwordLastChangeAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isMfaEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAdmin' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPalqeeManager' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isRegistrationComplete' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCompanyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfEmployees' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscriptionTier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureIntRange',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minValue' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxValue' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureBoolean',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'available' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uiCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPermanentAccess' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialExpiredAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthenticatedUserFragment, unknown>
export const AuthenticatedUserWithPositionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticatedUserWithPositions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AuthenticatedUser' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'positions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departmentId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'positionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'position' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticatedUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passwordLastChangeAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isMfaEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAdmin' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPalqeeManager' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isRegistrationComplete' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCompanyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfEmployees' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscriptionTier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureIntRange',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minValue' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxValue' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureBoolean',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'available' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uiCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPermanentAccess' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialExpiredAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthenticatedUserWithPositionsFragment, unknown>
export const ConsentFormDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentFormDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConsentForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consentExpiresAfterPeriod' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allowConsentOnBehalf' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'urlTargetType' },
                            value: { kind: 'EnumValue', value: 'PUBLIC_URL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationArg' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'draftAccessToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsentFormDetailsFragment, unknown>
export const ConsentConditionDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentConditionDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConsentCondition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsentConditionDetailsFragment, unknown>
export const CompanyBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subAdministrativeArea' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'administrativeArea' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscriptionTier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureIntRange',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxValue' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureBoolean',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'available' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyBasicFragment, unknown>
export const CompanyFullDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyFullDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CompanyBasic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uiCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subAdministrativeArea' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'administrativeArea' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscriptionTier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureIntRange',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxValue' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureBoolean',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'available' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyFullDetailsFragment, unknown>
export const CompanyMainFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyMain' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyMainFragment, unknown>
export const FileInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'binaryFileInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileUrlInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileInfoFragment, unknown>
export const DocumentFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DocboxDocument' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uiCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FileInfo' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'binaryFileInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileUrlInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentFieldsFragment, unknown>
export const DsarFormTranslationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormTranslationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarFormTranslation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyCookiesLink' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DsarFormTranslationFieldsFragment, unknown>
export const DsarFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultLanguage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DsarFormFieldsFragment, unknown>
export const DsarRequestFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarRequestFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestDataSubjectTypeTranslation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIdVerified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredAtEffective' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldValues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCustomField' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'language' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DsarRequestFieldsFragment, unknown>
export const DsarRequestsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarRequestsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestDataSubjectTypeTranslation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIdVerified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredAtEffective' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DsarRequestsFieldsFragment, unknown>
export const AuthApiKeyTableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthApiKeyTable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AuthApiKey' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiKeyMasked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthApiKeyTableFragment, unknown>
export const CompanyStructureListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyStructureList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'general' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyStructureList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyStructures' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyStructureListQuery,
  CompanyStructureListQueryVariables
>
export const CountryListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CountryList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'general' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'countryList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isoCode' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountryListQuery, CountryListQueryVariables>
export const CompanyTypeListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyTypeList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'general' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyTypeList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyTypeListQuery,
  CompanyTypeListQueryVariables
>
export const CurrencyListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrencyList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'general' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isoCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'symbol' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrencyListQuery, CurrencyListQueryVariables>
export const AnnualSalesRevenueListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AnnualSalesRevenueList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'general' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annualSalesRevenueList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annualSalesRevenues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortOrder' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnnualSalesRevenueListQuery,
  AnnualSalesRevenueListQueryVariables
>
export const IndustrySectorListQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndustrySectorListQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'general' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industryList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IndustrySectorListQueryQuery,
  IndustrySectorListQueryQueryVariables
>
export const DashboardShareModalUsersGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardShareModalUsersGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboards' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'UserFields' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sharedWithUsers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'DashboardShareWithUserInfoFields',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'permissionForCurrentUser',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dashboardId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canEdit' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canView' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardShareWithUserInfoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardUserPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardShareModalUsersGetQuery,
  DashboardShareModalUsersGetQueryVariables
>
export const AnalyticsDashboardTaskCategoriesGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AnalyticsDashboardTaskCategoriesGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskCategoriesGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoriesGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnalyticsDashboardTaskCategoriesGetQuery,
  AnalyticsDashboardTaskCategoriesGetQueryVariables
>
export const AnalyticsDashboardTaskStatusesGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AnalyticsDashboardTaskStatusesGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskStatusesGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statusesGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnalyticsDashboardTaskStatusesGetQuery,
  AnalyticsDashboardTaskStatusesGetQueryVariables
>
export const AnalyticsDashboardCompanyDepartmentsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AnalyticsDashboardCompanyDepartmentsGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyDepartmentsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departmentsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'departments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnalyticsDashboardCompanyDepartmentsGetQuery,
  AnalyticsDashboardCompanyDepartmentsGetQueryVariables
>
export const DashboardUpsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DashboardUpsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardUpsertInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upsert' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboard' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Dashboard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissionForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardUpsertMutation,
  DashboardUpsertMutationVariables
>
export const DashboardDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DashboardDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboard' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardDeleteMutation,
  DashboardDeleteMutationVariables
>
export const DashboardUnshareUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DashboardUnshareUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardUnshareInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unshare' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboard' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sharedWithUsers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'DashboardShareWithUserInfoFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardShareWithUserInfoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardUserPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardUnshareUserMutation,
  DashboardUnshareUserMutationVariables
>
export const DashboardShareWithUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DashboardShareWithUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardShareInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'share' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboard' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sharedWithUsers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'DashboardShareWithUserInfoFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardShareWithUserInfoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardUserPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardShareWithUserMutation,
  DashboardShareWithUserMutationVariables
>
export const DashboardFavoriteSetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DashboardFavoriteSet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardFavoriteSetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'favoriteSet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboard' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Dashboard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissionForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardFavoriteSetMutation,
  DashboardFavoriteSetMutationVariables
>
export const DashboardsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardsGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboards' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isFavoriteForCurrentUser',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sharedWithUsers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dashboardId',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'permissionForCurrentUser',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dashboardId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canEdit' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canView' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsGetQuery, DashboardsGetQueryVariables>
export const DashboardsNameGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardsNameGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboards' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardsNameGetQuery,
  DashboardsNameGetQueryVariables
>
export const DashboardGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboards' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Dashboard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissionForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardGetQuery, DashboardGetQueryVariables>
export const GetAudiencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAudiences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyAudiencesGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audiencesGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'audiences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'audienceId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAudiencesQuery, GetAudiencesQueryVariables>
export const SurveysGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'surveysGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'survey' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'companyIds' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyIds' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'surveys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SurveysGetQuery, SurveysGetQueryVariables>
export const TasksCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'StatisticsTasksCountInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasksCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tasksCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'color' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'department' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TasksCountQuery, TasksCountQueryVariables>
export const ConsentsCountByPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConsentsCountByPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'StatisticsConsentsCountByPeriodInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consentsCountByPeriod' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consentsCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'frameStartAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'frameEndAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'consentStatusCode',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'form' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsentsCountByPeriodQuery,
  ConsentsCountByPeriodQueryVariables
>
export const SubjectRightsRequestsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubjectRightsRequestsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'StatisticsDsarRequestsCountInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dsarRequestsCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestsCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubjectRightsRequestsCountQuery,
  SubjectRightsRequestsCountQueryVariables
>
export const DatamapResponsesCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DatamapResponsesCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'StatisticsDatamapResponsesCountInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datamapResponsesCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'responsesCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'datamapEntityTypeCode',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'survey' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'session' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sessionId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'expirationStatusCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'guidedStep' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'guidedStepName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'guidedSteps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DatamapResponsesCountQuery,
  DatamapResponsesCountQueryVariables
>
export const SurveyDatamapGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SurveyDatamapGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SurveyGetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'survey' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'surveys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SurveyDatamapGetQuery,
  SurveyDatamapGetQueryVariables
>
export const SurveyGuidedStepsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SurveyGuidedStepsGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SurveyGuidedStepsGetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'survey' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'guidedStepsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'guidedSteps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'guidedStepsGrouped' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'guidedSteps' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SurveyGuidedStepsGetQuery,
  SurveyGuidedStepsGetQueryVariables
>
export const GroupCompaniesGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupCompaniesGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupCompaniesGetQuery,
  GroupCompaniesGetQueryVariables
>
export const AudienceUserCreateOrLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AudienceUserCreateOrLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserCreateOrLinkToCompanyInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createOrLinkToCompany' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AudienceUserTable',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AudienceUserTable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAdmin' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSelected' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCompanyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passwordLastChangeAt' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vendorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'audiences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'computingMethod' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'positions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'positionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'position' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AudienceUserCreateOrLinkMutation,
  AudienceUserCreateOrLinkMutationVariables
>
export const AuthCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthCurrentUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userCurrent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AuthenticatedUserWithPositions',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticatedUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passwordLastChangeAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isMfaEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAdmin' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPalqeeManager' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isRegistrationComplete' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCompanyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfEmployees' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscriptionTier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureIntRange',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minValue' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxValue' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureBoolean',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'available' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uiCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPermanentAccess' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialExpiredAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticatedUserWithPositions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AuthenticatedUser' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'positions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyIds' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'companyId' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departmentId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'positionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'position' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthCurrentUserQuery,
  AuthCurrentUserQueryVariables
>
export const CompanyGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CompanyMain' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyMain' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyGetQuery, CompanyGetQueryVariables>
export const ConsentFormGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConsentFormGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConsentFormsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ConsentFormDetails',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentFormDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConsentForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consentExpiresAfterPeriod' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allowConsentOnBehalf' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'urlTargetType' },
                            value: { kind: 'EnumValue', value: 'PUBLIC_URL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationArg' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'draftAccessToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsentFormGetQuery, ConsentFormGetQueryVariables>
export const ConsentFormCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConsentFormCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConsentFormCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCreate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'form' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ConsentFormDetails',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentFormDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConsentForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consentExpiresAfterPeriod' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allowConsentOnBehalf' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'urlTargetType' },
                            value: { kind: 'EnumValue', value: 'PUBLIC_URL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationArg' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'draftAccessToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsentFormCreateMutation,
  ConsentFormCreateMutationVariables
>
export const ConsentFormUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConsentFormUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConsentFormUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formUpdate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'form' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ConsentFormDetails',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentFormDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConsentForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consentExpiresAfterPeriod' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allowConsentOnBehalf' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'urlTargetType' },
                            value: { kind: 'EnumValue', value: 'PUBLIC_URL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationArg' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'draftAccessToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsentFormUpdateMutation,
  ConsentFormUpdateMutationVariables
>
export const ConsentFormCustomFieldUpsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConsentFormCustomFieldUpsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ConsentFormCustomFieldUpsertInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCustomFieldUpsert' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formCustomField' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'form' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConsentFormDetails',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentFormDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConsentForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consentExpiresAfterPeriod' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allowConsentOnBehalf' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'urlTargetType' },
                            value: { kind: 'EnumValue', value: 'PUBLIC_URL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationArg' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'draftAccessToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsentFormCustomFieldUpsertMutation,
  ConsentFormCustomFieldUpsertMutationVariables
>
export const ConsentFormCustomFieldsDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConsentFormCustomFieldsDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ConsentFormCustomFieldsDeleteInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCustomFieldsDelete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedItemsCount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsentFormCustomFieldsDeleteMutation,
  ConsentFormCustomFieldsDeleteMutationVariables
>
export const CompanyBasicGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyBasicGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CompanyBasic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subAdministrativeArea' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'administrativeArea' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscriptionTier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureIntRange',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxValue' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureBoolean',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'available' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyBasicGetQuery,
  CompanyBasicGetQueryVariables
>
export const UserCreateOrLinkToCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserCreateOrLinkToCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserCreateOrLinkToCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createOrLinkToCompany' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AuthenticatedUser',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticatedUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passwordLastChangeAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isMfaEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAdmin' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPalqeeManager' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isRegistrationComplete' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCompanyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfEmployees' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSubscriptionTier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureIntRange',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minValue' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxValue' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'SubscriptionFeatureBoolean',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'available' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uiCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPermanentAccess' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialExpiredAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserCreateOrLinkToCompanyMutation,
  UserCreateOrLinkToCompanyMutationVariables
>
export const CompanyNameGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyNameGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyNameGetQuery, CompanyNameGetQueryVariables>
export const DocboxGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DocboxGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DocboxGetFilterArg' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileUrlInput' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'BinaryFileInfoUrlInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PaginationArg' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DocboxGetOrderArg' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DocboxGetSearchArg' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docbox' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filter' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'pagination' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pagination' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'order' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'order' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'search' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'search' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DocumentFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'binaryFileInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileUrlInput' },
                },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DocboxDocument' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uiCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FileInfo' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocboxGetQuery, DocboxGetQueryVariables>
export const DsarFormGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DsarFormGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftAccessToken' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customFields' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isLocked' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isRequired' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sortOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'validationMethod',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'validationArg',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'translations',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'language',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'label',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'placeholder',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'regulation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultLanguage' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'availableLanguages',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'translations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'language' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sortOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'privacyPolicyLink',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'privacyCookiesLink',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastUpdatedAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DsarFormGetQuery, DsarFormGetQueryVariables>
export const DsarFormsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DsarFormsGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'regulation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'translations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'language' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastUpdatedAt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DsarFormsGetQuery, DsarFormsGetQueryVariables>
export const DsarRequestDataSubjectTypesUpsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarRequestDataSubjectTypesUpsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DsarRequestDataSubjectTypesUpsertInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'requestDataSubjectTypesUpsert',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'requestDataSubjectTypes',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarRequestDataSubjectTypesUpsertMutation,
  DsarRequestDataSubjectTypesUpsertMutationVariables
>
export const DsarFormCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCreate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'form' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DsarFormFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultLanguage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormCreateMutation,
  DsarFormCreateMutationVariables
>
export const DsarFormCustomFieldUpsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormCustomFieldUpsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormCustomFieldUpsertInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCustomFieldUpsert' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formCustomField' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortOrder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isRequired' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isLocked' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormCustomFieldUpsertMutation,
  DsarFormCustomFieldUpsertMutationVariables
>
export const DsarFormUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formUpdate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'form' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DsarFormFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultLanguage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormUpdateMutation,
  DsarFormUpdateMutationVariables
>
export const DsarFormTranslationUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormTranslationUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormTranslationUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formTranslationUpdate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formTranslation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DsarFormTranslationFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormTranslationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarFormTranslation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyCookiesLink' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormTranslationUpdateMutation,
  DsarFormTranslationUpdateMutationVariables
>
export const DsarFormTranslationCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormTranslationCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormTranslationCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formTranslationCreate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formTranslation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DsarFormTranslationFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormTranslationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarFormTranslation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyCookiesLink' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormTranslationCreateMutation,
  DsarFormTranslationCreateMutationVariables
>
export const DsarFormTranslationDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormTranslationDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormTranslationDeleteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formTranslationDelete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formTranslations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DsarFormTranslationFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormTranslationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarFormTranslation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyLink' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyCookiesLink' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormTranslationDeleteMutation,
  DsarFormTranslationDeleteMutationVariables
>
export const DsarFormDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormDeleteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formDelete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'form' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DsarFormFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarForm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultLanguage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormDeleteMutation,
  DsarFormDeleteMutationVariables
>
export const DsarRequestDataSubjectTypeTranslationsDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'DsarRequestDataSubjectTypeTranslationsDelete',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DsarRequestDataSubjectTypeTranslationsDeleteInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'requestDataSubjectTypeTranslationsDelete',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'query' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ping' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarRequestDataSubjectTypeTranslationsDeleteMutation,
  DsarRequestDataSubjectTypeTranslationsDeleteMutationVariables
>
export const DsarFormCustomFieldsDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarFormCustomFieldsDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarFormCustomFieldsDeleteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCustomFieldsDelete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedItemsCount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarFormCustomFieldsDeleteMutation,
  DsarFormCustomFieldsDeleteMutationVariables
>
export const DsarRequestDataSubjectTypeTranslationsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'DsarRequestDataSubjectTypeTranslationsGet',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DsarRequestDataSubjectTypeTranslationsGetInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'requestDataSubjectTypeTranslationsGet',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'requestDataSubjectTypeTranslations',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'requestDataSubjectTypeId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'language' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortOrder' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarRequestDataSubjectTypeTranslationsGetQuery,
  DsarRequestDataSubjectTypeTranslationsGetQueryVariables
>
export const DsarRequestCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DsarRequestCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarRequestCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestCreate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'request' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DsarRequestFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarRequestFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestDataSubjectTypeTranslation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIdVerified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredAtEffective' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldValues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCustomField' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'language' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarRequestCreateMutation,
  DsarRequestCreateMutationVariables
>
export const DsarRequestChannelsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DsarRequestChannelsGet' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestChannelsGet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestChannels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarRequestChannelsGetQuery,
  DsarRequestChannelsGetQueryVariables
>
export const CompanyAddressRepGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyAddressRepGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'streetAddress',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'streetAddress2',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'locality' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'administrativeArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subAdministrativeArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'postalCode' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'representatives' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'input' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'filter' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'representativeGroups',
                                              },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  {
                                                    kind: 'EnumValue',
                                                    value: 'DPO',
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: { kind: 'Name', value: 'client' },
                                      },
                                    ],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyAddressRepGetQuery,
  CompanyAddressRepGetQueryVariables
>
export const DsarPeriodSelectorRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DsarPeriodSelectorRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarRequestsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: { kind: 'Name', value: 'client' },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phoneNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'expiredAtEffective',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isIdVerified' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regulation' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'requestDataSubjectTypeTranslation',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'language' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customFieldValues',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'formCustomField',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'translations',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'label',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'language',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarPeriodSelectorRequestQuery,
  DsarPeriodSelectorRequestQueryVariables
>
export const DsarRequestGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DsarRequestGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarRequestsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DsarRequestFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarRequestFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestDataSubjectTypeTranslation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIdVerified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredAtEffective' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldValues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formCustomField' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'language' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DsarRequestGetQuery, DsarRequestGetQueryVariables>
export const DsarRequestsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DsarRequestsGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DsarRequestsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dsar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DsarRequestsFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DsarRequestsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DsarRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullName' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestDataSubjectTypeTranslation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIdVerified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredAtEffective' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DsarRequestsGetQuery,
  DsarRequestsGetQueryVariables
>
export const AuthApiKeyCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthApiKeyCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AuthApiKeyCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'apiKeyCreate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apiKeyString' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthApiKeyCreateMutation,
  AuthApiKeyCreateMutationVariables
>
export const AuthApiKeyDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthApiKeyDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AuthApiKeyDeleteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'apiKeyDelete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apiKeyId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthApiKeyDeleteMutation,
  AuthApiKeyDeleteMutationVariables
>
export const AuthApiKeysGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthApiKeysGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AuthApiKeysGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'apiKeysGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apiKeys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'apiKeyMasked' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthApiKeysGetQuery, AuthApiKeysGetQueryVariables>
export const SurveyResponsesChangeRespondentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SurveyResponsesChangeRespondent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SurveyResponsesChangeRespondentInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'survey' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsesChangeRespondent' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'query' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ping' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SurveyResponsesChangeRespondentMutation,
  SurveyResponsesChangeRespondentMutationVariables
>
export const SurveyResponsesChangeRespondentInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'SurveyResponsesChangeRespondentInvitations',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SurveyInvitationsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'survey' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitationsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SurveyResponsesChangeRespondentInvitationsQuery,
  SurveyResponsesChangeRespondentInvitationsQueryVariables
>
export const TasksFilterByCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksFilterByCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskCategoriesGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoriesGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TasksFilterByCategoriesQuery,
  TasksFilterByCategoriesQueryVariables
>
export const TasksFilterByStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksFilterByStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskStatusesGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statusesGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortOrder' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TasksFilterByStatusesQuery,
  TasksFilterByStatusesQueryVariables
>
export const TasksFilterByOwnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksFilterByOwners' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: { kind: 'Name', value: 'client' },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TasksFilterByOwnersQuery,
  TasksFilterByOwnersQueryVariables
>
export const TasksFilterByAssigneesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksFilterByAssignees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyAudiencesGetInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyAudiencesGetInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userGetInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userGetInput' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: { kind: 'Name', value: 'client' },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audiencesGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'companyAudiencesGetInput',
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'audiences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TasksFilterByAssigneesQuery,
  TasksFilterByAssigneesQueryVariables
>
export const TasksExportGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksExportGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tasks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deadlineAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isArchived' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'assigneeAudiences',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assigneeUsers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: { kind: 'Name', value: 'client' },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attachments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'originalFilename',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mimetype',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: { kind: 'Name', value: 'client' },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'linkedDocboxDocuments',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mimetype',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'linkedDsarRequests',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'linkedSurveySessions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'survey' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedUrls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TasksExportGetQuery, TasksExportGetQueryVariables>
export const TasksExportHistoryItemCommentsGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksExportHistoryItemCommentsGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskHistoryItemsGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'historyItemsGet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'historyItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'newInfo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'taskId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TasksExportHistoryItemCommentsGetQuery,
  TasksExportHistoryItemCommentsGetQueryVariables
>
export const UserSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: { kind: 'Name', value: 'client' },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paginationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offset' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSearchQuery, UserSearchQueryVariables>
export const UserCompaniesGetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserCompaniesGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companies' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserCompaniesGetQuery,
  UserCompaniesGetQueryVariables
>
export const SurveyGetViewAnswersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SurveyGetViewAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SurveyFilterArg' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileUrlInput' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'BinaryFileInfoUrlInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'survey' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filter' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'surveys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'audiences' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'computingMethod',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'isSingleUser',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'users' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'positions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'position',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'name',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'companyId',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'department',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'name',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'companyId',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regulation' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'uiCode',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcategories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'header' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sortOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isGuided' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subcategoryTemplate',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'category',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'regulation',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'name',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'uiCode',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'questions' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'label',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'maturityScore',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'sessionId',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'sessionId',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'modifiers',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subcategory',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'answerOptions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'responses',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'answeredAt',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'files',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                      arguments: [
                                                        {
                                                          kind: 'Argument',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'input',
                                                          },
                                                          value: {
                                                            kind: 'Variable',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'fileUrlInput',
                                                            },
                                                          },
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'originalFilename',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'respondent',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'email',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'positions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'department',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'id',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'name',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'companyId',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'position',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'id',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'name',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'companyId',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uiCode',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sessions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'startedAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'finishedAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'invitedUsers',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'positions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'position',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'name',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'companyId',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'department',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'name',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'companyId',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SurveyGetViewAnswersQuery,
  SurveyGetViewAnswersQueryVariables
>
export const AuthCurrentUserAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthCurrentUserAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userCurrent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phoneNumber' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'passwordLastChangeAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isMfaEnabled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'roles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roleId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyId' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companies' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CompanyFullDetails',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subAdministrativeArea' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'administrativeArea' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscriptionTier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureIntRange',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxValue' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubscriptionFeatureBoolean',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'available' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyFullDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CompanyBasic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uiCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthCurrentUserAccountQuery,
  AuthCurrentUserAccountQueryVariables
>
export const CompanyCompanyGetComanyAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyCompanyGetComanyAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'regions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyCompanyGetComanyAccountQuery,
  CompanyCompanyGetComanyAccountQueryVariables
>
export const CompanyCompanyGetAuthLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyCompanyGetAuthLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CompanyBinaryFileInfo',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyCompanyGetAuthLoginQuery,
  CompanyCompanyGetAuthLoginQueryVariables
>
export const CompanyCompanyGetCompanyOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyCompanyGetCompanyOnboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'companyId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentSubscriptionTier',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'features' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'code' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value:
                                                'SubscriptionFeatureIntRange',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'minValue',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'maxValue',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value:
                                                'SubscriptionFeatureBoolean',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'available',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CompanyBinaryFileInfo',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'structure' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'industries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'regions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'numberOfEmployees',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'annualSalesRevenue',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minValue' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxValue' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isoCode' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'streetAddress',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'streetAddress2',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'locality' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subAdministrativeArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'administrativeArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'postalCode' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyBinaryFileInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IBinaryFileInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BinaryFileInfoPublic' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyCompanyGetCompanyOnboardingQuery,
  CompanyCompanyGetCompanyOnboardingQueryVariables
>
export const UserGetCompanyOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserGetCompanyOnboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserGetInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'get' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'position' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'department' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserGetCompanyOnboardingQuery,
  UserGetCompanyOnboardingQueryVariables
>
